import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_CLAIM_REPORTS,
  GET_CLAIM_REPORTS_SUCCESS,
  GET_CLAIM_REPORTS_FAILURE
} from './getClaimReports.actions';

export default createReducer(
  {
    [GET_CLAIM_REPORTS]: defaultRequest,
    [GET_CLAIM_REPORTS_SUCCESS]: defaultSuccess,
    [GET_CLAIM_REPORTS_FAILURE]: defaultError
  },
  defaultInitialState
);
