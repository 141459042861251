import API from './API';

const prefix = 'booking';

export const getBookings = () => API.get(`${prefix}`);

export const bookAsLandlord = (
  startDate,
  durationInMonths,
  roomId,
  userId,
  rentalFee,
  deposit
) =>
  API.post(`${prefix}/landlord`, {
    startDate,
    durationInMonths,
    roomId,
    userId,
    rentalFee,
    deposit
  });

export const addBooking = (
  startDate,
  durationInMonths,
  roomId,
  orderId,
  promotionCode
) =>
  API.post(`${prefix}`, {
    startDate,
    durationInMonths,
    roomId,
    orderId,
    promotionCode
  });

export const bookingNextTenant = (
  terminationDate,
  tenantId,
  roomId,
  orderId,
  startDate,
  durationInMonths,
  secret
) =>
  API.post(`${prefix}/newTenant`, {
    terminationDate,
    oldTenantId: tenantId,
    roomId,
    orderId,
    startDate,
    durationInMonths,
    secret
  });

export const canBookRoom = (startDate, durationInMonths, roomId) =>
  API.post(`${prefix}/canBookRoom`, { startDate, durationInMonths, roomId });

export const updateBooking = (
  id,
  startDate,
  durationInMonths,
  rentalFee,
  deposit
) =>
  API.put(`${prefix}/${id}`, {
    startDate,
    durationInMonths,
    rentalFee,
    deposit
  });

export default {
  addBooking,
  bookingNextTenant,
  getBookings,
  canBookRoom,
  updateBooking,
  bookAsLandlord
};
