import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import {
  GET_FLAT_ROOMS,
  getFlatRoomsSuccess,
  getFlatRoomsFailure
} from './getFlatRooms.actions';

function* getFlatRooms({ id }) {
  try {
    const { data, errors } = yield call(flatApi.getFlatRooms, id);

    if (!errors) {
      yield put(getFlatRoomsSuccess(data));
    } else {
      yield put(getFlatRoomsFailure(errors));
    }
  } catch (error) {
    yield put(getFlatRoomsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_FLAT_ROOMS, getFlatRooms);
}
