import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { FlatList } from 'containers';

const FlatOverview = ({ t }) => (
  <Content>
    <h2>{t('flats.flats')}</h2>
    <FlatList />
  </Content>
);

FlatOverview.propTypes = {
  t: PropTypes.func.isRequired
};

export default FlatOverview;
