export const GET_FLAT = 'GET_FLAT';
export const GET_FLAT_SUCCESS = 'GET_FLAT_SUCCESS';
export const GET_FLAT_FAILURE = 'GET_FLAT_FAILURE';

export const getFlat = id => ({
  type: GET_FLAT,
  id
});
export const getFlatSuccess = payload => ({
  type: GET_FLAT_SUCCESS,
  payload
});
export const getFlatFailure = errors => ({
  type: GET_FLAT_FAILURE,
  errors
});
