import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { serviceProviderApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  addServiceProviderFailure,
  addServiceProviderSuccess,
  ADD_SERVICE_PROVIDER
} from './addServiceProvider.actions';

function* addServiceProviderSage({
  firstName,
  lastName,
  email,
  serviceProviderTypes
}) {
  try {
    const { data, errors } = yield call(
      serviceProviderApi.addServiceProvider,
      firstName,
      lastName,
      email,
      serviceProviderTypes
    );

    if (!errors) {
      yield put(addServiceProviderSuccess(data));
      successToast('addServiceProvider');
    } else {
      yield put(addServiceProviderFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addServiceProviderFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_SERVICE_PROVIDER, addServiceProviderSage);
}
