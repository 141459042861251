import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useInputState from 'hooks/useInputState';
import { updateUserInfo } from '../../store/account/updateUserInfo/updateUserInfo.actions';
import { Loader } from 'components';
import UpdateUserInfo from './UpdateUserInfo';

const UpdateUserInfoContainer = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: user, success: loadedUser } = useSelector(state => state.user);
  const { success, loading, errors: apiErrors } = useSelector(
    state => state.request.updateUserInfo
  );

  const { t } = useTranslation('setBaseData');
  const [mobileNumber, setMobileNumber] = useInputState('');
  const [nationality, setNationality] = useInputState(t('select'));
  const before18Years = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18)
  )
    .toISOString()
    .split('T')[0];

  const [birthDate, setBirthDate] = useState(before18Years);
  const [gender, setGender] = useInputState();
  const [job, setJob] = useInputState();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (apiErrors.length > 0) setErrors(apiErrors);
    if (success) {
      history.push(
        (location.state && location.state.redirectUrl) || './dashboard'
      );
    }
  }, [apiErrors, success, history]);

  const handleSubmit = e => {
    e.preventDefault();
    setErrors([]);

    if (mobileNumber.length === 0)
      setErrors(prev => [...prev, t('noPhoneNumber')]);

    if (nationality === t('select'))
      setErrors(prev => [...prev, t('noNationality')]);

    if (gender == null) setErrors(prev => [...prev, t('noGender')]);

    if (job == null) setErrors(prev => [...prev, t('noJob')]);

    if (errors.length > 0) return;

    dispatch(updateUserInfo(mobileNumber, nationality, birthDate, gender, job));
  };

  return (
    <Loader prop={loadedUser} spinner>
      <UpdateUserInfo
        {...{
          t,
          firstName: user ? user.firstName : '',
          lastName: user ? user.lastName : '',
          email: user ? user.email : '',
          mobileNumber,
          nationality,
          birthDate,
          gender,
          job,
          errors,
          loading,
          success,
          handleMobileNumberChange: setMobileNumber,
          handleNationalityChange: setNationality,
          handleBirthDateChange: setBirthDate,
          handleGenderChange: setGender,
          handleJobChange: setJob,
          handleSubmit
        }}
      />
    </Loader>
  );
};

UpdateUserInfoContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default UpdateUserInfoContainer;
