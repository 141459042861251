import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE
} from './getBookings.actions';

export default createReducer(
  {
    [GET_BOOKINGS]: defaultRequest,
    [GET_BOOKINGS_SUCCESS]: defaultSuccess,
    [GET_BOOKINGS_FAILURE]: defaultError
  },
  defaultInitialState
);
