export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';

export const getPayments = () => ({
  type: GET_PAYMENTS
});
export const getPaymentsSuccess = payload => ({
  type: GET_PAYMENTS_SUCCESS,
  payload
});
export const getPaymentsFailure = errors => ({
  type: GET_PAYMENTS_FAILURE,
  errors
});
