import Content from 'components/Content/Content';
import { HouseForm } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';

const EditHouse = ({ t, house, handleSubmit, isLoading }) => (
  <Content>
    <h2>{t('editHouse.editHouse')}</h2>
    <HouseForm
      onSubmit={handleSubmit}
      giverId={house.giverId}
      ownerId={house.ownerId}
      address={house.address}
      levels={house.levels}
      minutesToTrainStation={house.minutesToTrainStation}
      isLoading={isLoading}
    />
  </Content>
);

EditHouse.propTypes = {
  t: PropTypes.func.isRequired,
  house: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EditHouse;
