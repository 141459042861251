import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  SYNCHRONIZE_BANKING,
  synchronizeBankingSuccess,
  synchronizeBankingFailure
} from './synchronizeBanking.actions';
import { bankingApi } from 'utils/api';

export function* synchronizeBanking() {
  try {
    const { data, errors } = yield call(bankingApi.synchronizeBanking);

    if (!errors) {
      yield put(synchronizeBankingSuccess(data));
    } else {
      yield put(synchronizeBankingFailure(errors));
    }
  } catch (error) {
    yield put(synchronizeBankingFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(SYNCHRONIZE_BANKING, synchronizeBanking);
}
