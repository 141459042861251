import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HouseGiver from './HouseGiver';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { getHouseGiver } from 'store/houseGiver/getHouseGiver/getHouseGiver.actions';
import { deleteHouseGiver } from 'store/houseGiver/deleteHouseGiver/deleteHouseGiver.actions';

const HouseGiverContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();
  const houseGiverId = parseInt(match.params.houseGiverId);

  const houseGivers = useSelector(state => state.houseGivers);
  const houseGiver = houseGivers.find(x => x.id === houseGiverId);

  useEffect(() => {
    dispatch(getHouseGiver(houseGiverId));
  }, [houseGiverId]);

  const handleEdithouseGiverClick = () =>
    history.push(`/dashboard/data/houseGiver/${houseGiverId}/edit`);

  const handleDeleteHouseGiverClick = () => {
    dispatch(deleteHouseGiver(houseGiver.id));
    history.push(`/dashboard/data/houseGivers`);
  };

  return houseGiver !== undefined ? (
    <Loader prop={houseGiver}>
      <HouseGiver
        {...{
          houseGiver,
          handleEdithouseGiverClick,
          handleDeleteHouseGiverClick,
          t
        }}
      />
    </Loader>
  ) : (
    <div />
  );
};

HouseGiverContainer.propTypes = { match: PropTypes.object.isRequired };

export default HouseGiverContainer;
