import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';

const RentalDuration = ({ t, rentalDuration, handleRentalDuration }) => (
  <>
    <div>{t('minimumRentalDuration')}</div>
    <div>
      <FormControl
        as="select"
        value={rentalDuration}
        onChange={handleRentalDuration}
      >
        {['6', '9', '12'].map(x => (
          <option key={x}>{`${x} ${t('months')}`}</option>
        ))}
      </FormControl>
    </div>
  </>
);

RentalDuration.propTypes = {
  t: PropTypes.func.isRequired,
  rentalDuration: PropTypes.string.isRequired,
  handleRentalDuration: PropTypes.func.isRequired
};

export default RentalDuration;
