export const GET_TERMINATION = 'GET_TERMINATION';
export const GET_TERMINATION_SUCCESS = 'GET_TERMINATION_SUCCESS';
export const GET_TERMINATION_FAILURE = 'GET_TERMINATION_FAILURE';

export const getTermination = () => ({
  type: GET_TERMINATION
});
export const getTerminationSuccess = payload => ({
  type: GET_TERMINATION_SUCCESS,
  payload
});
export const getTerminationFailure = errors => ({
  type: GET_TERMINATION_FAILURE,
  errors
});
