import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import Config from '../config';
import rootReducer from './root.reducer';
import rootSaga from './root.saga';

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];
  if (Config.environment === 'development') {
    middleware.push(logger);
  }

  const store = createStore(rootReducer, applyMiddleware(...middleware));

  sagaMiddleware.run(rootSaga);

  return store;
}

export const storeInstance = configureStore();
