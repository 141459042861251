import { faCopy, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PromotionRoomListItem from './components/PromotionRoomListItem';

const EarnMoney = ({
  totalEarnedMoney,
  totalAdvertisedTenants,
  totalAvailableMoney,
  bonusPerRoom,
  promotionCode,
  availableRooms,
  currentPosition,
  calculateBonusFirstPlace,
  hasCreditNotesThisMonth,
  t
}) => {
  const Tooltip = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{t('earnMoney')}</Popover.Header>
      <Popover.Body>{t('headerHover')}</Popover.Body>
    </Popover>
  );
  return (
    <Container className="mt-5">
      <b style={{ fontSize: '20px' }}>
        {t('earnMoney')}
        <OverlayTrigger
          overlay={Tooltip}
          trigger={['hover', 'focus']}
          placement="right"
        >
          <FontAwesomeIcon
            icon={faQuestion}
            style={{
              marginLeft: 15,
              color: 'grey',
              verticalAlign: 'middle'
            }}
          />
        </OverlayTrigger>
      </b>

      {hasCreditNotesThisMonth && currentPosition && currentPosition <= 5 ? (
        <div className="mt-5">
          <Alert variant="success">
            {t('promotionTextTop').replace('{bonus}', calculateBonusFirstPlace)}
          </Alert>
        </div>
      ) : (
        <div className="mt-5">
          <Alert variant="success">
            {t('promotionText').replace('{bonus}', calculateBonusFirstPlace)}
          </Alert>
        </div>
      )}

      <div
        className="mt-5"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Card>
          <Card.Body>
            <Card.Text style={{ fontSize: 'larger' }}>
              {t('bonusPerRoom')}
            </Card.Text>
            <Card.Title style={{ textAlign: 'center', fontSize: 'larger' }}>
              {bonusPerRoom}
            </Card.Title>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>{t('earnedMoney')}</Card.Text>
            <Card.Title style={{ textAlign: 'center' }}>
              {totalEarnedMoney}
            </Card.Title>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>{t('advertisedTenants')}</Card.Text>
            <Card.Title style={{ textAlign: 'center' }}>
              {totalAdvertisedTenants}
            </Card.Title>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>{t('avaiableMoney')}</Card.Text>
            <Card.Title style={{ textAlign: 'center' }}>
              {totalAvailableMoney}
            </Card.Title>
          </Card.Body>
        </Card>
      </div>

      <div className="mt-5">
        {t('yourPromotionCode')} {promotionCode}
        <FontAwesomeIcon
          icon={faCopy}
          onClick={() => navigator.clipboard.writeText(promotionCode)}
          style={{
            marginLeft: 15,
            fontSize: 18,
            color: 'grey',
            verticalAlign: 'middle',
            cursor: 'pointer'
          }}
        />
      </div>
      <h2 className="d-flex justify-content-center">{t('bookableRooms')}</h2>
      <div className="mt-5">
        {availableRooms.map(room => (
          <PromotionRoomListItem key={room.id} room={room} />
        ))}
      </div>
    </Container>
  );
};

EarnMoney.propTypes = {
  totalEarnedMoney: PropTypes.string.isRequired,
  totalAdvertisedTenants: PropTypes.number.isRequired,
  totalAvailableMoney: PropTypes.string.isRequired,
  bonusPerRoom: PropTypes.string.isRequired,
  promotionCode: PropTypes.string.isRequired,
  availableRooms: PropTypes.array.isRequired,
  currentPosition: PropTypes.number,
  calculateBonusFirstPlace: PropTypes.number.isRequired,
  hasCreditNotesThisMonth: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default EarnMoney;
