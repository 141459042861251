import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { bookingApi } from 'utils/api/';
import {
  CAN_BOOK_ROOM,
  canBookRoomSuccess,
  canBookRoomFailure
} from './canBookRoom.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';

export function* canBookRoom({ startDate, durationInMonths, roomId }) {
  try {
    const { data, errors } = yield call(
      bookingApi.canBookRoom,
      startDate,
      parseInt(durationInMonths),
      parseInt(roomId)
    );

    if (!errors) {
      yield put(canBookRoomSuccess(data));
    } else {
      yield put(canBookRoomFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(canBookRoomFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(CAN_BOOK_ROOM, canBookRoom);
}
