import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_HOUSES,
  GET_HOUSES_SUCCESS,
  GET_HOUSES_FAILURE
} from './getHouses.actions';

export default createReducer(
  {
    [GET_HOUSES]: defaultRequest,
    [GET_HOUSES_SUCCESS]: defaultSuccess,
    [GET_HOUSES_FAILURE]: defaultError
  },
  defaultInitialState
);
