import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_TENANT_BALANCE_FOR_LANDLORD,
  GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS,
  GET_TENANT_BALANCE_FOR_LANDLORD_FAILURE,
  GET_TENANT_BALANCE_FOR_LANDLORD_CLEAR
} from './getTenantBalanceForLandlord.actions';

export default createReducer(
  {
    [GET_TENANT_BALANCE_FOR_LANDLORD]: defaultRequest,
    [GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS]: defaultSuccess,
    [GET_TENANT_BALANCE_FOR_LANDLORD_FAILURE]: defaultError,
    [GET_TENANT_BALANCE_FOR_LANDLORD_CLEAR]: () => defaultInitialState
  },
  {
    data: { payments: [], debts: [] },
    loading: false,
    success: false,
    errors: []
  }
);
