import React from 'react';
import PropTypes from 'prop-types';
import { FlagIcon } from 'react-flag-kit';
import { toAge } from 'utils/date.formatter';
import { getCountryCode } from '../../../../utils/countryService/country.service';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getGenderByKey } from '../../../../utils/genderService/gender.service';

const CurrentTenants = ({ t, currentTenants, value, ...rest }) => (
  <div {...rest}>
    <h6 className="mt-2">
      <strong>{t('room:currentTenants')}</strong>
    </h6>
    <ul className="list-group list-group-flush">
      {currentTenants.length > 0 ? (
        currentTenants.map(tenant => (
          <li
            key={tenant.id}
            className="list-group-item d-flex align-items-center"
          >
            <div style={{ marginRight: 20 }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{tenant.nationality}</Tooltip>}
              >
                <div style={{ display: 'inline-block' }}>
                  <FlagIcon
                    code={getCountryCode(tenant.nationality)}
                    size={24}
                  />
                </div>
              </OverlayTrigger>
            </div>
            <span>{`${toAge(tenant.dateOfBirth)}`}</span>
            <span>{', '}&nbsp;</span>
            <span> {getGenderByKey(tenant.gender).value}</span>
          </li>
        ))
      ) : (
        <div>{t('room:noTenants')}</div>
      )}
    </ul>
  </div>
);

CurrentTenants.propTypes = {
  value: PropTypes.number,
  t: PropTypes.func.isRequired,
  currentTenants: PropTypes.array.isRequired
};

export default CurrentTenants;
