import PropTypes from 'prop-types';
import React, { cloneElement, isValidElement } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import PayPalPayment from './PayPal';

const Payment = ({
  handlePaymentSuccess,
  handlePaymentError,
  children,
  header,
  amount,
  xs,
  md,
  lg,
  isLoading,
  setIsLoading,
}) => (
  <Row className="text-center justify-content-center">
    <Col xs={xs} md={md} lg={lg}>
      {header && <h1 className="mb-3">{header}</h1>}
      {isValidElement(children) && cloneElement(children)}

      <PayPalPayment
        amount={amount}
        handlePaymentSuccess={handlePaymentSuccess}
        handlePaymentError={handlePaymentError}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      {isLoading && (
        <Spinner animation="border" variant="primary" className="mb-5" />
      )}
    </Col>
  </Row>
);

Payment.propTypes = {
  handlePaymentSuccess: PropTypes.func.isRequired,
  handlePaymentError: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.object,
  xs: PropTypes.number.isRequired,
  md: PropTypes.number.isRequired,
  lg: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default Payment;
