import API from './API';

const prefix = 'house/giver';

export const getHouseGiver = id => API.get(`${prefix}/${id}`);
export const getHouseGivers = () => API.get(`${prefix}`);
export const addHouseGiver = (addressId, firstName, lastName) =>
  API.post(`${prefix}/`, { addressId, firstName, lastName });
export const updateHouseGiver = (id, addressId, firstName, lastName) =>
  API.put(`${prefix}/`, { houseGiverId: id, addressId, firstName, lastName });
export const deleteHouseGiver = id => API.delete(`${prefix}/${id}`);

export default {
  getHouseGiver,
  getHouseGivers,
  addHouseGiver,
  updateHouseGiver,
  deleteHouseGiver
};
