import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langDetector from 'i18next-browser-languagedetector';

import Config from '../../config';

import dictionaryEN from './en';
import dictionaryDE from './de';

const detectingOptions = { order: ['localStorage'] };

i18n
  .use(langDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': dictionaryEN,
      'de-DE': dictionaryDE
    },
    fallbackLng: 'en-US',
    detection: detectingOptions,
    interpolation: {
      escapeValue: false
    },
    debug: Config.environment === 'development',
    react: {
      wait: true
    }
  });

export default i18n;
