export const GET_OFFERED_CREDIT_NOTES = 'GET_OFFERED_CREDIT_NOTES';
export const GET_OFFERED_CREDIT_NOTES_SUCCESS = 'GET_OFFERED_CREDIT_NOTES_SUCCESS';
export const GET_OFFERED_CREDIT_NOTES_FAILURE = 'GET_OFFERED_CREDIT_NOTES_FAILURE';
export const GET_OFFERED_CREDIT_NOTES_RESET = 'GET_OFFERED_CREDIT_NOTES_RESET';

export const getOfferedCreditNotes = () => ({
  type: GET_OFFERED_CREDIT_NOTES
});
export const getOfferedCreditNotesSuccess = payload => ({
  type: GET_OFFERED_CREDIT_NOTES_SUCCESS,
  payload
});
export const getOfferedCreditNotesFailure = errors => ({
  type: GET_OFFERED_CREDIT_NOTES_FAILURE,
  errors
});

export const getOfferedCreditNotesReset = () => ({
  type: GET_OFFERED_CREDIT_NOTES_RESET
});
