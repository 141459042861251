import { ServiceProviderSelect } from 'containers';
import useClaimReports from 'hooks/store/useClaimReports';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Viewer from 'react-viewer';
import { toDisplayDate } from 'utils/date.formatter';
import AddClaimModal from './AddClaimModal';

const TicketDetailModal = ({
  ticket,
  isOpen,
  onHide,
  t,
  handleDoneClick,
  handleAssignTicket,
}) => {
  const [open, setStateOpen] = useState(false);
  const [showAddClaimModal, setShowAddClaimModal] = useState(false);
  const [moveInClaimReportImagesOpen, setMoveInClaimReportImagesOpen] =
    useState(false);
  const [serviceProvider, setServiceProvider] = useState(
    ticket.serviceProvider?.id
  );

  const [images, setImages] = useState();
  const [moveInClaimReports, setMoveInClaimReports] = useState([]);
  const [imagesCount, setImagesCount] = useState();

  const claimReports = useClaimReports();

  useEffect(() => {
    if (ticket.isCleanUpTicket) {
      setMoveInClaimReports(
        claimReports.filter(
          (x) =>
            x.moveInClaimReport &&
            x.tenant.booking.id == ticket.tenant?.booking?.id
        )
      );
    }
  }, [claimReports, ticket.id]);

  useEffect(() => {
    setServiceProvider(ticket.serviceProvider?.id);

    let images = ticket.images
      ? ticket.images.map((x) => ({ src: x.url }))
      : [];
    if (ticket.claimReport) {
      images = ticket.claimReport.claims[0]?.images
        ? [
            ...images,
            ...ticket.claimReport.claims[0].images.map((x) => ({ src: x.url })),
          ]
        : [...images];
    }

    setImages(images);
    setImagesCount(images.length);

    return function () {
      setMoveInClaimReports([]);
    };
  }, [ticket.id]);

  return (
    <>
      <AddClaimModal
        show={showAddClaimModal}
        setShow={setShowAddClaimModal}
        t={t}
        ticketId={ticket.id}
      />
      <Modal style={{ zIndex: 1050 }} show={isOpen} size="lg" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{t('ticket')}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <b>{t('description')}</b>
          <div style={{ marginBottom: '10px', whiteSpace: 'pre-wrap' }}>
            {ticket.description}
          </div>
          <div style={{ marginBottom: '10px' }} />
          {ticket.tenant && ticket.tenant.firstName && ticket.tenant.lastName && (
            <>
              <b>{t('name')}</b>
              <span>{`${ticket.tenant.firstName} ${ticket.tenant.lastName}`}</span>
              <div style={{ marginBottom: '10px' }} />
            </>
          )}
          {ticket.tenant && ticket.tenant.email && ticket.tenant.phoneNumber && (
            <>
              <b>{t('contact')}</b>
              <span>{ticket.tenant.phoneNumber}</span>
              <span>{ticket.tenant.email}</span>
              <div style={{ marginBottom: '10px' }} />
            </>
          )}
          {ticket.referenceObject.symbol && (
            <>
              <b>{t('roomSymbol')}</b>
              <span>{ticket.referenceObject.symbol}</span>
              <div style={{ marginBottom: '10px' }} />
            </>
          )}
          <b>{t('address')}</b>
          <span>{`${ticket.referenceObject.address.street} ${ticket.referenceObject.address.houseNumber}, ${ticket.referenceObject.address.postalCode} ${ticket.referenceObject.address.location}`}</span>
          <div style={{ marginBottom: '10px' }} />
          <b>{t('date')}</b>
          <span>{toDisplayDate(ticket.created)}</span>
          <div style={{ marginBottom: '10px' }} />
          <b>{t('state')}</b>
          <span>{t('open')}</span>
          <div style={{ marginBottom: '10px' }} />
          <b>{t('object')}</b>
          <span>{ticket.referenceObject.object}</span>
          <div style={{ marginBottom: '10px' }} />

          <b>{t('serviceProvider')}</b>
          <div className="d-flex">
            <ServiceProviderSelect
              style={{ flexGrow: 1 }}
              onChange={setServiceProvider}
              value={serviceProvider}
              selector={(state) => {
                return state.serviceProviders.filter(
                  (x) =>
                    x.houseIds.includes(ticket.room?.flat?.houseId) ||
                    x.houseIds.includes(ticket.house?.id) ||
                    x.houseIds.includes(ticket.flat?.houseId)
                );
              }}
            />
            <Button
              className="mb-3"
              variant="success"
              onClick={() => handleAssignTicket(ticket.id, serviceProvider)}
            >
              {t('assign')}
            </Button>
          </div>

          <div style={{ marginBottom: '10px' }} />

          {ticket.isCleanUpTicket && (
            <Button
              disabled={ticket.state === t('closed')}
              style={{ marginBottom: '10px' }}
              onClick={() => setShowAddClaimModal(true)}
            >
              {t('addClaim')}
            </Button>
          )}

          {moveInClaimReports.length > 0 && ticket.isCleanUpTicket && (
            <>
              <Button
                onClick={() => setMoveInClaimReportImagesOpen(true)}
                variant="secondary"
              >
                {`${t('imagesMoveIn')} ${moveInClaimReports.reduce(
                  (x, y) => x + y.claims[0].images.length,
                  0
                )}`}
              </Button>
              <Viewer
                visible={moveInClaimReportImagesOpen}
                onClose={() => {
                  setMoveInClaimReportImagesOpen(false);
                }}
                zIndex={2000}
                images={moveInClaimReports.flatMap((x) => [
                  ...x.claims[0].images.map((y) => ({ src: y.url })),
                ])}
              />
              <div style={{ marginBottom: '10px' }} />
            </>
          )}

          {imagesCount > 0 && (
            <>
              <Button
                onClick={() => setStateOpen(true)}
                variant="secondary"
                disabled={imagesCount === 0}
              >
                {`${t('images')} ${imagesCount}`}
              </Button>
              <Viewer
                visible={open}
                onClose={() => {
                  setStateOpen(false);
                }}
                zIndex={2000}
                images={images}
              />
              <div style={{ marginBottom: '10px' }} />
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {t('close')}
          </Button>
          <Button
            variant="success"
            onClick={handleDoneClick}
            disabled={ticket.state === t('closed')}
          >
            {t('closed')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TicketDetailModal.defaultState = {
  ticket: undefined,
  isOpen: false,
};

TicketDetailModal.propTypes = {
  ticket: PropTypes.object,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  handleSaveDateClick: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  handleAssignTicket: PropTypes.func.isRequired,
};

export default TicketDetailModal;
