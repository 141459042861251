export const GET_HOUSE_OWNER = 'GET_HOUSE_OWNER';
export const GET_HOUSE_OWNER_SUCCESS = 'GET_HOUSE_OWNER_SUCCESS';
export const GET_HOUSE_OWNER_FAILURE = 'GET_HOUSE_OWNER_FAILURE';
export const GET_HOUSE_OWNER_CLEAR = 'GET_HOUSE_OWNER_CLEAR';

export const getHouseOwner = id => ({
  type: GET_HOUSE_OWNER,
  id
});
export const getHouseOwnerSuccess = payload => ({
  type: GET_HOUSE_OWNER_SUCCESS,
  payload
});
export const getHouseOwnerFailure = errors => ({
  type: GET_HOUSE_OWNER_FAILURE,
  errors
});
export const getHouseOwnerClear = () => ({
  type: GET_HOUSE_OWNER_CLEAR
});
