import PropTypes from 'prop-types';
import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import config from '../../config';

const PayPalPayment = ({
  amount,
  handlePaymentSuccess,
  handlePaymentError,
  setIsLoading,
  isLoading,
}) => (
  <div className={isLoading ? 'd-none' : ''}>
    <PayPalButton
      onCancel={() => setIsLoading(false)}
      onButtonReady={() => setIsLoading(false)}
      amount={amount}
      onClick={(e) => {
        if (e.fundingSource !== 'card') {
          setIsLoading(true);
        }
      }}
      shippingPreference="NO_SHIPPING"
      onSuccess={(_, data) => {
        handlePaymentSuccess(data.orderID);
      }}
      onError={(e) => {
        handlePaymentError(e);
        setIsLoading(false);
      }}
      catchError={(e) => {
        handlePaymentError(e);
        setIsLoading(false);
      }}
      options={{
        clientId: config.PayPalClientId,
        currency: 'EUR',
        disableFunding: 'giropay,sepa',
      }}
    />
  </div>
);

PayPalPayment.propTypes = {
  amount: PropTypes.string.isRequired,
  handlePaymentSuccess: PropTypes.func.isRequired,
  handlePaymentError: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PayPalPayment;
