import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_HOUSE_GIVER,
  ADD_HOUSE_GIVER_SUCCESS,
  ADD_HOUSE_GIVER_FAILURE,
  ADD_HOUSE_GIVER_CLEAR
} from './addHouseGiver.actions';

export default createReducer(
  {
    [ADD_HOUSE_GIVER]: defaultRequest,
    [ADD_HOUSE_GIVER_SUCCESS]: defaultSuccess,
    [ADD_HOUSE_GIVER_FAILURE]: defaultError,
    [ADD_HOUSE_GIVER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
