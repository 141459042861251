import {
  createReducer,
  defaultError,
  defaultInitialState,
  defaultRequest,
  defaultSuccess,
} from 'utils/reducer.helper';
import {
  CREATE_LOG,
  CREATE_LOG_CLEAR,
  CREATE_LOG_FAILURE,
  CREATE_LOG_SUCCESS,
} from './createLog.actions';

export default createReducer(
  {
    [CREATE_LOG]: defaultRequest,
    [CREATE_LOG_SUCCESS]: defaultSuccess,
    [CREATE_LOG_FAILURE]: defaultError,
    [CREATE_LOG_CLEAR]: () => defaultInitialState,
  },
  defaultInitialState
);
