import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_RENTAL_ENTRANCE,
  GET_RENTAL_ENTRANCE_FAILURE,
  GET_RENTAL_ENTRANCE_SUCCESS
} from './rentalEntrance.actions';

export default createReducer(
  {
    [GET_RENTAL_ENTRANCE]: defaultRequest,
    [GET_RENTAL_ENTRANCE_SUCCESS]: defaultSuccess,
    [GET_RENTAL_ENTRANCE_FAILURE]: defaultError
  },
  defaultInitialState
);
