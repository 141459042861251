import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TestSystemDisclaimer = ({ t }) => (
  <p>
    <b>{t('testSystem')}</b>
    <p>
      <br />
      {t('testSystemUsername')}
      <OverlayTrigger
        placement="top"
        delay={{ show: 50, hide: 400 }}
        overlay={<Tooltip>{t('copy')}</Tooltip>}
      >
        <span
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
          onClick={() =>
            navigator.clipboard.writeText(
              'sb-xpkaj1488574@personal.example.com'
            )
          }
        >
          sb-xpkaj1488574@personal.example.com
        </span>
      </OverlayTrigger>
      <br />
      {t('testSystemPassword')}
      <OverlayTrigger
        placement="top"
        delay={{ show: 50, hide: 400 }}
        overlay={<Tooltip>{t('copy')}</Tooltip>}
      >
        <span
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
          onClick={() => navigator.clipboard.writeText('m/2NNJ"g')}
        >
          m/2NNJ\"g
        </span>
      </OverlayTrigger>
    </p>
  </p>
);

export default TestSystemDisclaimer;
