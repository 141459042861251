import { useDispatch, useSelector } from 'react-redux';
import { getClaimReport as _getClaimReport } from 'store/claimReport/getClaimReport/getClaimReport.actions';
import { getClaimReports as _getClaimReports } from 'store/claimReport/getClaimReports/getClaimReports.actions';
import { sendClaimReport as _sendClaimReport } from 'store/claimReport/sendClaimReport/sendClaimReport.actions';

const useClaimReportStore = () => {
  const dispatch = useDispatch();
  const selector = useSelector;

  const claimReports = useSelector(state => state.claimReports);

  // #region getClaimReport
  const getClaimReport = id => dispatch(_getClaimReport(id));
  const selectGetClaimReport = state => state.request.getClaimReport;
  const selectGetClaimReportData = state => state.request.getClaimReport.data;
  const selectGetClaimReportSuccess = state =>
    state.request.getClaimReport.success;
  const selectGetClaimReportErrors = state =>
    state.request.getClaimReport.errors;
  const getClaimReportResult = () => selector(selectGetClaimReport);
  const getClaimReportData = () => selector(selectGetClaimReportData);
  const getClaimReportSuccess = () => selector(selectGetClaimReportSuccess);
  const getClaimReportErrors = () => selector(selectGetClaimReportErrors);
  // #endregion

  // #region getClaimReports
  const getClaimReports = () => dispatch(_getClaimReports());
  const selectGetClaimReports = state => state.request.getClaimReports;
  const selectGetClaimReportsData = state => state.request.getClaimReports.data;
  const selectGetClaimReportsSuccess = state =>
    state.request.getClaimReports.success;
  const selectGetClaimReportsErrors = state =>
    state.request.getClaimReports.errors;
  const getClaimReportsResult = () => selector(selectGetClaimReports);
  const getClaimReportsData = () => selector(selectGetClaimReportsData);
  const getClaimReportsSuccess = () => selector(selectGetClaimReportsSuccess);
  const getClaimReportsErrors = () => selector(selectGetClaimReportsErrors);
  // #endregion

  // #region sendClaimReport
  const sendClaimReport = (claim, description, images, isMoveInClaimReport) =>
    dispatch(_sendClaimReport(claim, description, images, isMoveInClaimReport));
  const selectSendClaimReport = state => state.request.sendClaimReport;
  const selectSendClaimReportData = state => state.request.sendClaimReport.data;
  const selectSendClaimReportSuccess = state => state.request.sendClaimReport;
  const selectSendClaimReportErrors = state => state.request.sendClaimReport;
  const sendClaimReportResult = () => selector(selectSendClaimReport);
  const sendClaimReportData = () => selector(selectSendClaimReportData);
  const sendClaimReportSuccess = () => selector(selectSendClaimReportSuccess);
  const sendClaimReportErrors = () => selector(selectSendClaimReportErrors);
  // #endregion

  return {
    claimReports,
    getClaimReport,
    selectGetClaimReport,
    selectGetClaimReportData,
    selectGetClaimReportSuccess,
    selectGetClaimReportErrors,
    getClaimReportResult,
    getClaimReportData,
    getClaimReportErrors,
    getClaimReportSuccess,
    getClaimReports,
    selectGetClaimReports,
    selectGetClaimReportsData,
    selectGetClaimReportsSuccess,
    selectGetClaimReportsErrors,
    getClaimReportsResult,
    getClaimReportsData,
    getClaimReportsSuccess,
    getClaimReportsErrors,
    sendClaimReport,
    selectSendClaimReport,
    selectSendClaimReportData,
    selectSendClaimReportSuccess,
    selectSendClaimReportErrors,
    sendClaimReportResult,
    sendClaimReportData,
    sendClaimReportSuccess,
    sendClaimReportErrors
  };
};

export default useClaimReportStore;
