import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  Row
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const Tenants = ({
  handleFilterChange,
  tenants,
  rowEvents,
  columns,
  t,
  filter,
  onActiveTenantsFilterChange,
  onlyActiveTenantsFilter,
  handleNewTenantClick
}) => {
  const { SearchBar } = Search;
  let sum = 0;
  tenants.map(x => (sum += x.accountBalance));
  return (
    <Container className="mt-5">
      <ToolkitProvider
        keyField="id"
        data={tenants}
        columns={columns}
        search={{ searchFormatted: true }}
      >
        {props => (
          <div>
            <Row>
              <Col
                className="col-md-5 col-12"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '20px'
                }}
              >
                <b>{`${t('tenantsBalance')}: ${sum.toFixed(2)}€`}</b>
              </Col>
              <Col className="col-md-1 col-12 mt-3 mt-md-0">
                <Button onClick={handleNewTenantClick}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <FormCheck
                  type="checkbox"
                  label={t('onlyActiveTenants')}
                  onChange={onActiveTenantsFilterChange}
                  value={onlyActiveTenantsFilter}
                  defaultChecked={true}
                />
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <FormControl
                  as="select"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option>{t('allBalances')}</option>
                  <option>{t('positiveBalances')}</option>
                  <option>{t('negativeBalances')}</option>
                </FormControl>
              </Col>
              <Col className="col-md-2 col-12 mt-2 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  srText={null}
                  placeholder={i18n.t('base:search')}
                  style={{ float: 'right' }}
                />
              </Col>
            </Row>
            <hr />
            <div className="noselect" style={{ cursor: 'pointer' }}>
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
};

Tenants.propTypes = {
  tenants: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowEvents: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  baseProps: PropTypes.object.isRequired,
  searchProps: PropTypes.object.isRequired,
  onActiveTenantsFilterChange: PropTypes.func.isRequired,
  onlyActiveTenantsFilter: PropTypes.bool.isRequired,
  handleNewTenantClick: PropTypes.func.isRequired
};

export default Tenants;
