import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { PriceFormControl } from 'components';

const SelfInflictedModal = ({
  ticketId,
  isOpen,
  onHide,
  t,
  handleDoneClick
}) => {
  const [filter, setFilter] = useState(t('no'));
  const [amount, setAmount] = useState();

  const isSelfInflicted = filter === t('yes');

  return (
    <Modal show={isOpen} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('selfInflicted')}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{t('selfInflicted')}</span>
        <FormControl
          as="select"
          value={filter}
          onChange={e => setFilter(e.target.value)}
        >
          <option>{t('yes')}</option>
          <option>{t('no')}</option>
        </FormControl>
        <div style={{ marginBottom: '10px' }} />
        <span>{t('amount')}</span>
        <PriceFormControl
          value={amount}
          onChange={e => setAmount(e.target.value)}
          disabled={!isSelfInflicted}
        />

        <div style={{ marginBottom: '10px' }} />
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => handleDoneClick(ticketId, amount)}
        >
          {t('closed')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SelfInflictedModal.defaultState = {
  ticketId: '',
  isOpen: false
};

SelfInflictedModal.propTypes = {
  ticketId: PropTypes.string,
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  handleSaveDateClick: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default SelfInflictedModal;
