import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { RoomList } from 'containers';

const RoomsOverview = ({ t }) => (
  <Content>
    <h2>{t('rooms.rooms')}</h2>
    <RoomList />
  </Content>
);

RoomsOverview.propTypes = {
  t: PropTypes.func.isRequired
};

export default RoomsOverview;
