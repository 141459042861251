import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRooms } from 'store/room/getAllRooms/getAllRooms.actions';

const useRooms = (selector = state => state.rooms) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getAllRooms);
  const rooms = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getAllRooms());
  }, [success]);

  return rooms;
};

export default useRooms;
