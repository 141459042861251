import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFlat } from 'store/flat/getFlat/getFlat.actions';
import { Loader } from 'components';
import {
  updateFlat,
  updateFlatClear
} from 'store/flat/updateFlat/updateFlat.actions';
import EditFlat from './EditFlat';

const EditFlatContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const flatId = parseInt(match.params.flatId);
  const dispatch = useDispatch();
  const history = useHistory();

  const flats = useSelector(state => state.flats);
  const flat = flats.find(x => x.id === flatId);

  useEffect(() => {
    dispatch(getFlat(flatId));
  }, []);

  const { success, loading: isLoading } = useSelector(
    state => state.request.updateFlat
  );

  useEffect(() => {
    if (success) {
      dispatch(updateFlatClear());
      history.push(`/dashboard/data/flat/${flat.id}`);
    }
  }, [success]);

  const onSubmit = ({ level, symbol, squareMeters, houseId, description }) => {
    dispatch(
      updateFlat(flatId, symbol, level, description, squareMeters, houseId)
    );
  };

  return flat !== undefined ? (
    <Loader prop={flat}>
      <EditFlat {...{ t, flat, onSubmit, isLoading }} />
    </Loader>
  ) : (
    <div />
  );
};

EditFlatContainer.propTypes = { match: PropTypes.object.isRequired };

export default EditFlatContainer;
