import { put, takeLatest, call } from 'redux-saga/effects';
import { settingsApi } from 'utils/api';
import i18n from 'i18next';
import successToast from 'utils/successToast';
import {
  SAVE_CREDIT_SETTINGS,
  saveCreditSettingsSuccess,
  saveCreditSettingsFailure
} from './saveCreditSettings.actions';
import { getCreditSettingsReset } from '../getCreditSettings/getCreditSettings.actions';

function* saveCreditSettings({ creditAdvertiser, creditAdvertised, bonus }) {
  try {
    const { data, errors } = yield call(settingsApi.saveCreditSettings, { creditAdvertiser, creditAdvertised, bonus });

    if (!errors && data.errors.length === 0) {
      yield put(saveCreditSettingsSuccess(data.resultObject));
      yield put(getCreditSettingsReset());
      successToast('saveSettings');
    } else {
      yield put(saveCreditSettingsFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(saveCreditSettingsFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(SAVE_CREDIT_SETTINGS, saveCreditSettings);
}
