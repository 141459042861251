import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'components/Content';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactImageGallery from 'react-image-gallery';
import { toDisplayDate } from 'utils/date.formatter';

const Room = ({
  roomId,
  room,
  handleEditRoomClick,
  handleDeleteRoomClick,
  t,
}) => (
  <Content>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('room.editRoom')}</Tooltip>}
    >
      <Button
        style={{ float: 'right' }}
        onClick={handleEditRoomClick}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('room.deleteRoom')}</Tooltip>}
    >
      <Button
        style={{ float: 'right', marginRight: 20 }}
        onClick={handleDeleteRoomClick}
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </OverlayTrigger>

    <h2>
      {t('room.room')} {roomId}
    </h2>
    <h5>{t('room.symbol')}</h5>
    <span>{room.symbol}</span>
    <h5>{t('room.squareMeters')}</h5>
    <span>{room.squareMeters}</span>
    <h5>{t('room.price')}</h5>
    <span>{room.price}</span>
    <h5>{t('room.deposit')}</h5>
    <span>{room.deposit}</span>
    <h5>{t('room.freeAt')}</h5>
    <span>{toDisplayDate(room.freeAt)}</span>
    <h5>{t('room.house')}</h5>
    <span>
      {room.house &&
        `${room.house.address.postalCode} ${room.house.address.location} ${room.house.address.street} ${room.house.address.houseNumber}`}
    </span>
    <h5>{t('room.flat')}</h5>
    <p style={{ whiteSpace: 'pre-wrap' }}>{room.flat.description}</p>
    <h5>{t('room.equipment')}</h5>
    <FormCheck
      label={t('room.furnished')}
      checked={room.equipment.furnished}
      readOnly
    />
    <FormCheck
      label={t('room.washer')}
      checked={room.equipment.washer}
      readOnly
    />
    <FormCheck
      label={t('room.internet')}
      checked={room.equipment.internet}
      readOnly
    />
    <FormCheck
      label={t('room.kitchen')}
      checked={room.equipment.kitchen}
      readOnly
    />
    <FormCheck
      label={t('room.allInclusive')}
      checked={room.equipment.allInclusive}
      readOnly
    />
    <FormCheck
      label={t('room.tenancyAgreementInclusive')}
      checked={room.equipment.tenancyAgreementInclusive}
      readOnly
    />

    <h5>{t('room.description')}</h5>
    <p style={{ whiteSpace: 'pre-wrap' }}>{room.description}</p>
    <h5>{t('room.locationOfRoom')}</h5>
    <p style={{ whiteSpace: 'pre-wrap' }}>{room.locationOfRoom}</p>
    <h5>{t('room.images')}</h5>

    {room.images.length > 0 && (
      <div style={{ backgroundColor: '#333333' }}>
        <ReactImageGallery
          items={room.images.map((x) => {
            return {
              original: x.imageUrl,
              thumbnail: x.imageUrl,
            };
          })}
          showPlayButton={false}
          slideDuration={200}
        />
      </div>
    )}
  </Content>
);

Room.propTypes = {
  room: PropTypes.object.isRequired,
  roomId: PropTypes.number.isRequired,
  handleEditRoomClick: PropTypes.func.isRequired,
  handleDeleteRoomClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Room;
