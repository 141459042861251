import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { LoadingButton, FormGroupControl } from 'components';

const ConfirmEmailByCode = ({
  t,
  code,
  loading,
  handleCodeChange,
  confirmUsersEmail
}) => (
  <Form
    className="mx-3"
    onSubmit={e => {
      e.preventDefault();
      confirmUsersEmail(code);
    }}
  >
    <FormGroupControl
      label={t('code')}
      value={code}
      onChange={handleCodeChange}
      required
      type="code"
    ></FormGroupControl>

    <LoadingButton className="w-100 mt-3" type="submit" isLoading={loading}>
      {t('verifyEmail')}
    </LoadingButton>
  </Form>
);

ConfirmEmailByCode.propTypes = {
  t: PropTypes.func.isRequired,
  confirmUsersEmail: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleCodeChange: PropTypes.func.isRequired
};

export default ConfirmEmailByCode;
