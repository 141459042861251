import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_TICKETS,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_FAILURE
} from './getTickets.actions';

export default createReducer(
  {
    [GET_TICKETS]: defaultRequest,
    [GET_TICKETS_SUCCESS]: defaultSuccess,
    [GET_TICKETS_FAILURE]: defaultError
  },
  defaultInitialState
);
