import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import {
  REGISTER_TENANT,
  REGISTER_TENANT_SUCCESS,
  REGISTER_TENANT_FAILURE,
  REGISTER_TENANT_CLEAR
} from './registerTenant.actions';

export default createReducer(
  {
    [REGISTER_TENANT]: defaultRequest,
    [REGISTER_TENANT_SUCCESS]: defaultSuccess,
    [REGISTER_TENANT_FAILURE]: defaultError,
    [REGISTER_TENANT_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
