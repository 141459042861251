import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import TenantsModalDetailModal from '../TenantsModal';
import EditTenantBalanceModal from './editTenantBalanceModal';

const Balance = ({
  data,
  columns,
  t,
  firstName,
  lastName,
  customerId,
  isModalOpen,
  onHide,
  openModal,
  onChange,
  value,
  submitCreatePayment,
  onChangeReference,
  isLoading,
  isTenantModalOpen,
  setIsTenantModalOpen,
  tenant,
}) => {
  const { SearchBar } = Search;
  let sum = 0;
  data.map((x) => (sum += x.amount));
  return (
    <Container className="mt-5">
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        search={{ searchFormatted: true }}
      >
        {(props) => (
          <div>
            <Row>
              <Col
                className="col-6"
                style={{
                  fontSize: '20px',
                  marginBottom: '20px',
                }}
              >
                <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsTenantModalOpen(true)}
                >
                  {firstName} {lastName}, {customerId}
                </b>
              </Col>
              <Col className="col-md-3 col-12 mt-3 mt-md-0">
                <Button onClick={openModal}>{t('transfer')}</Button>
              </Col>
              <Col className="col-md-3 col-12 mt-3 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  srText={null}
                  placeholder={i18n.t('base:search')}
                />
              </Col>
              <Col
                className="col-12 mt-3 mt-md-0"
                style={{
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                <b
                  style={sum > 0 ? { color: '#28A745' } : { color: '#DC3545' }}
                >{`${t('balance')}: ${sum.toFixed(2)}€`}</b>
              </Col>
            </Row>

            <hr />
            <div className="noselect" style={{ cursor: 'pointer' }}>
              <BootstrapTable
                {...props.baseProps}
                hover
                wrapperClasses="table-responsive"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      <EditTenantBalanceModal
        size={'lg'}
        onHide={onHide}
        show={isModalOpen}
        title={t('transfer')}
        onChange={onChange}
        value={value}
        submitCreatePayment={submitCreatePayment}
        onChangeReference={onChangeReference}
        t={t}
        isLoading={isLoading}
      />
      <TenantsModalDetailModal
        isOpen={isTenantModalOpen}
        onHide={() => setIsTenantModalOpen(false)}
        tenant={tenant}
      />
    </Container>
  );
};

Balance.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  baseProps: PropTypes.object.isRequired,
  searchProps: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  firstName: PropTypes.object.isRequired,
  lastName: PropTypes.object.isRequired,
  customerId: PropTypes.object.isRequired,
  isModalOpen: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  modalOpen: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  submitCreatePayment: PropTypes.func.isRequired,
  onChangeReference: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isTenantModalOpen: PropTypes.bool.isRequired,
  setIsTenantModalOpen: PropTypes.func.isRequired,
  tenant: PropTypes.object.isRequired,
};

export default Balance;
