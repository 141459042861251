import React from 'react';
import HouseOverview from './Houses';
import { useTranslation } from 'react-i18next';

const HouseOverviewContainer = () => {
  const { t } = useTranslation('data');

  return <HouseOverview t={t} />;
};

export default HouseOverviewContainer;
