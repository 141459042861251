export const UPDATE_HOUSE_GIVER = 'UPDATE_HOUSE_GIVER';
export const UPDATE_HOUSE_GIVER_SUCCESS = 'UPDATE_HOUSE_GIVER_SUCCESS';
export const UPDATE_HOUSE_GIVER_FAILURE = 'UPDATE_HOUSE_GIVER_FAILURE';
export const UPDATE_HOUSE_GIVER_CLEAR = 'UPDATE_HOUSE_GIVER_CLEAR';

export const updateHouseGiver = (id, firstName, lastName, address) => ({
  type: UPDATE_HOUSE_GIVER,
  id,
  firstName,
  lastName,
  address
});
export const updateHouseGiverSuccess = payload => ({
  type: UPDATE_HOUSE_GIVER_SUCCESS,
  payload
});
export const updateHouseGiverFailure = errors => ({
  type: UPDATE_HOUSE_GIVER_FAILURE,
  errors
});
export const updateHouseGiverClear = () => ({
  type: UPDATE_HOUSE_GIVER_CLEAR
});
