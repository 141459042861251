import React from 'react';
import { useTranslation } from 'react-i18next';
import HouseOwners from './HouseOwners';

const HouseOwnersContainer = () => {
  const { t } = useTranslation('data');

  return <HouseOwners {...{ t }} />;
};

export default HouseOwnersContainer;
