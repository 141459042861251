import { ServiceProviderTypeBadges } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getServiceProviders } from 'store/serviceProvider/getServiceProviders/getServiceProviders.actions';
import { ServiceProviderTypes } from 'utils/constants/serviceProviderTypes';
import ServiceProviderList from './ServiceProviderList';

const ServiceProviderListContainer = ({ serviceProviderSelector }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('data');

  const serviceProviders = useSelector(serviceProviderSelector);
  const { success } = useSelector(state => state.request.getServiceProviders);

  useEffect(() => {
    if (!success) dispatch(getServiceProviders());
  }, [success]);

  const columnConfiguration = [
    {
      dataField: 'firstName',
      text: t('serviceProvider.firstName'),
      sort: true
    },
    {
      dataField: 'lastName',
      text: t('serviceProvider.lastName'),
      sort: true
    },
    {
      dataField: 'serviceProviderTypes',
      text: t('serviceProvider.types'),
      formatter: types => (
        <ServiceProviderTypeBadges
          types={types.map(x => t(`${ServiceProviderTypes.getType(x)}`))}
        />
      ),
      sort: true
    }
  ];

  const handleNewServiceProviderClick = () =>
    history.push('/dashboard/data/serviceProvider/add');
  const handleServiceProviderRowClick = serviceProvider =>
    history.push(`/dashboard/data/serviceProvider/${serviceProvider.id}`);

  return (
    <ServiceProviderList
      {...{
        t,
        serviceProviders,
        columnConfiguration,
        handleNewServiceProviderClick,
        handleServiceProviderRowClick
      }}
    />
  );
};

ServiceProviderListContainer.defaultProps = {
  serviceProviderSelector: state => state.serviceProviders
};

ServiceProviderListContainer.propTypes = {
  serviceProviderSelector: PropTypes.func
};

export default ServiceProviderListContainer;
