import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import {
  DELETE_FLAT,
  deleteFlatSuccess,
  deleteFlatFailure
} from './deleteFlat.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteFlat({ id }) {
  try {
    const { data, errors } = yield call(flatApi.deleteFlat, id);

    if (!errors) {
      yield put(deleteFlatSuccess(data));
      successToast('deleteFlat');
    } else {
      yield put(deleteFlatFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteFlatFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_FLAT, deleteFlat);
}
