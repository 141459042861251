import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

const JobFormControl = ({ t, job, handleOnChange, jobs, ...rest }) => (
  <FormControl
    as="select"
    value={job.value}
    onChange={handleOnChange}
    {...rest}
  >
    <option>{t('select')}</option>
    {jobs.map(x => (
      <option key={x.key}>{x.value}</option>
    ))}
  </FormControl>
);

JobFormControl.propTypes = {
  t: PropTypes.func.isRequired,
  job: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired
};

export default JobFormControl;
