import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import DebtOverviewListItem from './components/DebtOverviewListItem';

const DebtOverview = ({ t, debts }) => {
  return (
    <Container>
      <Row>
        <Col style={{ marginTop: '20px', marginBottom: '20px' }}>
          <h1>{t('debtOverview')}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className="list-group list-group-flush">
            <a className="list-group-item">
              <Row>
                <Col className="col-4">
                  <b>{t('firstName')}</b>
                </Col>
                <Col className="col-7">
                  <b>{t('lastName')}</b>
                </Col>
                <Col className="col-1">
                  <b>{t('balance')}</b>
                </Col>
              </Row>
            </a>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className="list-group list-group-flush">
            {debts.map(debt => (
              <DebtOverviewListItem key={debt.email} {...debt} />
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

DebtOverview.propTypes = {
  t: PropTypes.func.isRequired,
  debts: PropTypes.array.isRequired
};

export default DebtOverview;
