export const GET_DEBTS = 'GET_DEBTS';
export const GET_DEBTS_SUCCESS = 'GET_DEBTS_SUCCESS';
export const GET_DEBTS_FAILURE = 'GET_DEBTS_FAILURE';

export const getDebts = () => ({
  type: GET_DEBTS
});
export const getDebtsSuccess = payload => ({
  type: GET_DEBTS_SUCCESS,
  payload
});
export const getDebtsFailure = errors => ({
  type: GET_DEBTS_FAILURE,
  errors
});
