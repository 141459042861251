import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import Content from 'components/Content';

const BaseData = ({
  onHandleHousesClick,
  onHandleFlatsClick,
  onHandleRoomsClick,
  onHandleServiceProvidersClick,
  onHandleHouseOwnersClick,
  onHandleHouseGiversClick,
  t
}) => (
  <Content>
    <h2>{t('baseData')}</h2>
    <h4>{t('rentalProperty')}</h4>
    <div className="d-flex gap-2 mb-3">
      <Button className="flex-fill" onClick={onHandleHousesClick}>
        {t('houses.houses')}
      </Button>
      <Button className="flex-fill" onClick={onHandleFlatsClick}>
        {t('flats.flats')}
      </Button>
      <Button className="flex-fill" onClick={onHandleRoomsClick}>
        {t('rooms.rooms')}
      </Button>
    </div>
    <h4>{t('persons')}</h4>
    <div className="d-flex gap-2  mb-3">
      <Button className="flex-fill" onClick={onHandleHouseOwnersClick}>
        {t('houseOwner.houseOwner')}
      </Button>
      <Button className="flex-fill" onClick={onHandleHouseGiversClick}>
        {t('houseGiver.houseGiver')}
      </Button>
      <Button className="flex-fill" onClick={onHandleServiceProvidersClick}>
        {t('serviceProvider.serviceProvider')}
      </Button>
    </div>
  </Content>
);

BaseData.propTypes = {
  onHandleHousesClick: PropTypes.func.isRequired,
  onHandleFlatsClick: PropTypes.func.isRequired,
  onHandleRoomsClick: PropTypes.func.isRequired,
  onHandleServiceProvidersClick: PropTypes.func.isRequired,
  onHandleHouseOwnersClick: PropTypes.func.isRequired,
  onHandleHouseGiversClick: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired
};

export default BaseData;
