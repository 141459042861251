export const UPDATE_HOUSE = 'UPDATE_HOUSE';
export const UPDATE_HOUSE_SUCCESS = 'UPDATE_HOUSE_SUCCESS';
export const UPDATE_HOUSE_FAILURE = 'UPDATE_HOUSE_FAILURE';
export const UPDATE_HOUSE_CLEAR = 'UPDATE_HOUSE_CLEAR';

export const updateHouse = (
  houseId,
  levels,
  minutesToTrainStation,
  giverId,
  serviceProviderIds,
  ownerId,
  addressId,
  address
) => ({
  type: UPDATE_HOUSE,
  houseId,
  levels,
  minutesToTrainStation,
  giverId,
  serviceProviderIds,
  ownerId,
  addressId,
  address
});
export const updateHouseSuccess = payload => ({
  type: UPDATE_HOUSE_SUCCESS,
  payload
});
export const updateHouseFailure = errors => ({
  type: UPDATE_HOUSE_FAILURE,
  errors
});
export const updateHouseClear = () => ({
  type: UPDATE_HOUSE_CLEAR
});
