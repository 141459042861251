import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import localization from '../../utils/localization/de';
import Faq from './faq';

const FaqContainer = () => {
  const { t } = useTranslation('faq');
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const keys = [];
    for (var key in localization.faq.questions) {
      keys.push(key);
    }
    setKeys(keys);
  }, []);

  return <Faq t={t} keys={keys}></Faq>;
};

export default FaqContainer;
