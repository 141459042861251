import {
  DepositFormGroup,
  DescriptionFormGroup,
  FlatFormGroup,
  FreeAtFormGroup,
  HouseFormGroup,
  ImageGallery,
  ImagesFormGroup,
  PriceFormGroup,
  RoomEquipmentFormGroup,
  SizeFormGroup,
  SubmitButtonFormGroup,
  SymbolFormGroup,
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const RoomForm = ({
  imagePreviews,
  handleSubmit,
  symbol,
  handleSymbolChange,
  squareMeters,
  handleSquareMetersChange,
  price,
  handlePriceChange,
  deposit,
  handleDepositChange,
  freeAt,
  handleFreeAtChange,
  houseId,
  setHouseId,
  flatId,
  setFlatId,
  description,
  handleDescriptionChange,
  t,
  isSubmitEnabled,
  handleSelectImages,
  images,
  sliderRef,
  handleDeleteImageClick,
  equipment,
  handleEquipmentChange,
  flatSelector,
  isLoading,
  locationOfRoom,
  handleLocationOfRoomChange,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <SymbolFormGroup value={symbol} onChange={handleSymbolChange} required />

      <SizeFormGroup
        value={squareMeters}
        onChange={handleSquareMetersChange}
        required
      />

      <PriceFormGroup value={price} onChange={handlePriceChange} required />

      <DepositFormGroup
        value={deposit}
        onChange={handleDepositChange}
        required
      />

      <FreeAtFormGroup value={freeAt} onChange={handleFreeAtChange} />

      <HouseFormGroup value={houseId} onChange={setHouseId} required />

      <FlatFormGroup
        value={flatId}
        onChange={setFlatId}
        disabled={!houseId}
        selector={flatSelector}
      />

      <FormGroup className="mb-3">
        <FormLabel>{t('room.equipment')}</FormLabel>
        <RoomEquipmentFormGroup
          onChange={handleEquipmentChange}
          equipment={equipment}
        />
      </FormGroup>

      <DescriptionFormGroup
        value={description}
        onChange={handleDescriptionChange}
        required
      />

      <FormGroup className="mb-3" controlId="locationOfRoom">
        <FormLabel>{t('roomForm.locationOfRoom')}</FormLabel>
        <FormControl
          as="textarea"
          value={locationOfRoom}
          onChange={handleLocationOfRoomChange}
          name="locationOfRoom"
          rows={6}
          required
        />
      </FormGroup>

      {images.length > 0 && (
        <ImageGallery
          customRef={sliderRef}
          imageUrls={imagePreviews}
          onDeleteImage={handleDeleteImageClick}
          deletable
        />
      )}

      <ImagesFormGroup onChange={handleSelectImages} />

      <SubmitButtonFormGroup isLoading={isLoading} />
    </Form>
  );
};

RoomForm.propTypes = {
  squareMeters: PropTypes.number,
  price: PropTypes.number,
  deposit: PropTypes.number,
  freeAt: PropTypes.string,
  houseId: PropTypes.string,
  flatId: PropTypes.string,
  description: PropTypes.string,
  isSubmitEnabled: PropTypes.bool.isRequired,
  images: PropTypes.array,
  equipment: PropTypes.object,
  t: PropTypes.func.isRequired,
  sliderRef: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSquareMetersChange: PropTypes.func.isRequired,
  handlePriceChange: PropTypes.func.isRequired,
  handleDepositChange: PropTypes.func.isRequired,
  handleFreeAtChange: PropTypes.func.isRequired,
  setHouseId: PropTypes.func.isRequired,
  setFlatId: PropTypes.func.isRequired,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleSelectImages: PropTypes.func.isRequired,
  handleDeleteImageClick: PropTypes.func.isRequired,
  handleEquipmentChange: PropTypes.func.isRequired,
  imagePreviews: PropTypes.array,
  flatSelector: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  handleSymbolChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locationOfRoom: PropTypes.string.isRequired,
  handleLocationOfRoomChange: PropTypes.func.isRequired,
};

export default RoomForm;
