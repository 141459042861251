import { FileUpload, LoadingButton } from 'components';
import { RoomSelect } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';

const CreateClaimReportServiceProvider = ({
  description,
  handleDescriptionChanged,
  handleSelectFiles,
  handleSubmitform,
  t,
  handleSubjectChanged,
  subject,
  roomId,
  setRoomId,
  isLoading,
  images,
}) => (
  <Container className="mt-5">
    <Row>
      <Col>
        <Form onSubmit={handleSubmitform}>
          <div className="mb-3">
            <b>{t('createClaimReport')}</b>
          </div>

          <FormGroup className="mb-3">
            <FormLabel>{t('room')}</FormLabel>
            <RoomSelect
              onChange={(roomId) => setRoomId(roomId)}
              value={roomId}
              selector={(state) => state.rooms.filter((x) => !x.bookable)}
              selectProp="id"
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>{t('subject')}</FormLabel>
            <FormControl value={subject} onChange={handleSubjectChanged} />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>{t('description')}</FormLabel>
            <FormControl
              as="textarea"
              value={description}
              onChange={handleDescriptionChanged}
              rows="6"
            />
          </FormGroup>

          <FileUpload
            selectedFiles={images}
            handleSelectFiles={handleSelectFiles}
            required={false}
          />
          <LoadingButton className="mb-3" type="submit" isLoading={isLoading}>
            {t('submit')}
          </LoadingButton>
        </Form>
      </Col>
    </Row>
  </Container>
);

CreateClaimReportServiceProvider.propTypes = {
  description: PropTypes.string,
  handleDescriptionChanged: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  handleSubmitform: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isEntryClaimReport: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  handleSubjectChanged: PropTypes.func.isRequired,
  roomId: PropTypes.string.isRequired,
  setRoomId: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
};

export default CreateClaimReportServiceProvider;
