import { all, fork } from 'redux-saga/effects';
import activateAccount from './account/activateAccount/activateAccount.saga';
import confirmEmail from './account/confirmEmail/confirmEmail.saga';
import confirmEmailByCode from './account/confirmEmailByCode/confirmEmailByCode.saga';
import forgotPassword from './account/forgotPassword/forgotPassword.saga';
import getUser from './account/getUser/user.saga';
import login from './account/login/login.saga';
import logout from './account/logout/logout.saga';
import register from './account/register/register.saga';
import registerTenant from './account/registerTenant/registerTenant.saga';
import resendConfirmationEmail from './account/resendConfirmationEmail/resendConfirmationEmail.saga';
import resetPassword from './account/resetPassword/resetPassword.saga';
import updateEmail from './account/updateEmail/updateEmail.saga';
import updateIban from './account/updateIban/updateIban.saga';
import updatePhoneNumber from './account/updatePhoneNumber/updatePhoneNumber.saga';
import updateServiceProviderInfo from './account/updateServiceProviderInfo/updateServiceProviderInfo.saga';
import updateUserInfo from './account/updateUserInfo/updateUserInfo.saga';
import addAddress from './address/addAddress/addAddress.saga';
import deleteAddress from './address/deleteAddress/deleteAddress.saga';
import getAddress from './address/getAddress/getAddress.saga';
import getAddresses from './address/getAddresses/getAddresses.saga';
import getBankingStatus from './banking/getBankingStatus/getBankingStatus.saga';
import synchronizeBanking from './banking/synchronizeBanking/synchronizeBanking.saga';
import bookAsLandlord from './booking/bookAsLandlord/bookAsLandlord.saga';
import bookingNextTenant from './booking/bookingNextTenant/bookingNextTenant.saga';
import canBookRoom from './booking/canBookRoom/canBookRoom.saga';
import createBooking from './booking/createBooking/createBooking.saga';
import getBookings from './booking/getBookings/getBookings.saga';
import updateBooking from './booking/updateBooking/updateBooking.saga';
import createClaim from './claim/createClaim/createClaim.saga';
import getClaimReport from './claimReport/getClaimReport/getClaimReport.saga';
import getClaimReports from './claimReport/getClaimReports/getClaimReports.saga';
import invoiceClaimReport from './claimReport/invoiceClaimReport/invoiceClaimReport.saga';
import sendClaimReport from './claimReport/sendClaimReport/sendClaimReport.saga';
import getAllCreditNotes from './creditNotes/getAllCreditNotes/getAllCreditNotes.saga';
import getCurrentAdvertiserPosition from './creditNotes/getCurrentAdvertiserPosition/getCurrentAdvertiserPosition.saga';
import getOfferedCreditNotes from './creditNotes/getOfferedCreditNotes/getOfferedCreditNotes.saga';
import getDebts from './debt/getDebts/getDebts.saga';
import approveDepositSettlement from './depositSettlement/approveDepositSettlement/approveDepositSettlement.saga';
import addFlat from './flat/addFlat/addFlat.saga';
import deleteFlat from './flat/deleteFlat/deleteFlat.saga';
import getFlat from './flat/getFlat/getFlat.saga';
import getFlatHouse from './flat/getFlatHouse/getFlatHouse.saga';
import getFlatRooms from './flat/getFlatRooms/getFlatRooms.saga';
import getFlats from './flat/getFlats/getFlats.saga';
import updateFlat from './flat/updateFlat/updateFlat.saga';
import addHouseGiver from './houseGiver/addHouseGiver/addHouseGiver.saga';
import deleteHouseGiver from './houseGiver/deleteHouseGiver/deleteHouseGiver.saga';
import getHouseGiver from './houseGiver/getHouseGiver/getHouseGiver.saga';
import getHouseGivers from './houseGiver/getHouseGivers/getHouseGivers.saga';
import updateHouseGiver from './houseGiver/updateHouseGiver/updateHouseGiver.saga';
import addHouseOwner from './houseOwner/addHouseOwner/addHouseOwner.saga';
import deleteHouseOwner from './houseOwner/deleteHouseOwner/deleteHouseOwner.saga';
import getHouseOwner from './houseOwner/getHouseOwner/getHouseOwner.saga';
import getHouseOwners from './houseOwner/getHouseOwners/getHouseOwners.saga';
import updateHouseOwner from './houseOwner/updateHouseOwner/updateHouseOwner.saga';
import addHouse from './houses/addHouse/addHouse.saga';
import deleteHouse from './houses/deleteHouse/deleteHouse.saga';
import getHouse from './houses/getHouse/getHouse.saga';
import getHouseFlats from './houses/getHouseFlats/getHouseFlats.saga';
import getHouseRooms from './houses/getHouseRooms/getHouseRooms.saga';
import getHouses from './houses/getHouses/getHouses.saga';
import updateHouse from './houses/updateHouse/updateHouse.saga';
import addLandlord from './landlord/addLandlord/addLandlord.saga';
import deleteLandlord from './landlord/deleteLandlord/deleteLandlord.saga';
import getLandlord from './landlord/getLandlord/getLandlord.saga';
import getLandlords from './landlord/getLandlords/getLandlords.saga';
import getTenantBalanceForLandlord from './landlord/getTenantBalanceForLandlord/getTenantBalanceForLandlord.saga';
import getTenantForLandlord from './landlord/getTenantForLandlord/getTenantForLandlord.saga';
import getTenantsForLandlord from './landlord/getTenantsForLandlord/getTenantsForLandlord.saga';
import upadteLandlord from './landlord/updateLandlord/updateLandlord.saga';
import createLog from './logging/createLog/createLog.saga';
import addPayments from './payment/addPayments/addPayments.saga';
import getPayments from './payment/getPayments/getPayments.saga';
import savePayment from './payment/savePayment/savePayment.saga';
import validatePromotionCode from './promotionCode/validatePromotionCode/validatePromotionCode.saga';
import addRoom from './room/addRoom/addRoom.saga';
import deleteRoom from './room/deleteRoom/deleteRoom.saga';
import getAllRooms from './room/getAllRooms/getAllRooms.saga';
import getRoom from './room/getRoom/getRoom.saga';
import getRoomFlat from './room/getRoomFlat/getRoomFlat.saga';
import getRoomHouse from './room/getRoomHouse/getRoomHouse.saga';
import updateRoom from './room/updateRoom/updateRoom.saga';
import addServiceProvider from './serviceProvider/addServiceProvider/addServiceProvider.saga';
import deleteServiceProvider from './serviceProvider/deleteServiceProvider/deleteServiceProvider.saga';
import getServiceProvider from './serviceProvider/getServiceProvider/getServiceProvider.saga';
import getServiceProviders from './serviceProvider/getServiceProviders/getServiceProviders.saga';
import registerServiceProvider from './serviceProvider/registerServiceProvider/registerServiceProvider.saga';
import updateServiceProvider from './serviceProvider/updateServiceProvider/updateServiceProvider.saga';
import getAdvertisedBonusForRoom from './settings/credit/getAdvertisedBonusForRoom/getAdvertisedBonusForRoom.saga';
import getCreditSettings from './settings/credit/getCreditSettings/getCreditSettings.saga';
import saveCreditSettings from './settings/credit/saveCreditSettings/saveCreditSettings.saga';
import getNewsletterSubscription from './settings/newsletterSubscription/getNewsletterSubscription/getNewsletterSubscription.saga';
import setNewsletterSubscription from './settings/newsletterSubscription/setNewsletterSubscription/setNewsletterSubscription.saga';
import rentalEntrance from './statistics/rentalEntrance/rentalEntrance.saga';
import createNewTenant from './tenants/createNewTenant/createNewTenant.saga';
import updateTenantData from './tenants/updateTenantData/updateTenantData.saga';
import addInvitedNewTenant from './termination/addInvitedNewTenant/addInvitedNewTenant.saga';
import createTermination from './termination/createTermination/createTermination.saga';
import deleteTermination from './termination/deleteTermination/deleteTermination.saga';
import doesTerminationExist from './termination/doesTerminationExist/doesTerminationExist.saga';
import getTermination from './termination/getTermination/getTermination.saga';
import assignTicket from './ticket/assignTicket/assignTicket.saga';
import closeTicket from './ticket/closeTicket/closeTicket.saga';
import createTicket from './ticket/createTicket/createTicket.saga';
import getTickets from './ticket/getTickets/getTickets.saga';

export default function* () {
  yield all([
    fork(confirmEmail),
    fork(getUser),
    fork(login),
    fork(logout),
    fork(register),
    fork(resendConfirmationEmail),
    fork(updateEmail),
    fork(updatePhoneNumber),
    fork(updateUserInfo),
    fork(addAddress),
    fork(deleteAddress),
    fork(getAddress),
    fork(getAddresses),
    fork(createBooking),
    fork(getClaimReport),
    fork(getClaimReports),
    fork(sendClaimReport),
    fork(invoiceClaimReport),
    fork(getTenantsForLandlord),
    fork(getTenantForLandlord),
    fork(addFlat),
    fork(deleteFlat),
    fork(getFlat),
    fork(getFlatHouse),
    fork(getFlatRooms),
    fork(getFlats),
    fork(updateFlat),
    fork(addHouseGiver),
    fork(deleteHouseGiver),
    fork(getHouseGiver),
    fork(getHouseGivers),
    fork(updateHouseGiver),
    fork(addHouseOwner),
    fork(deleteHouseOwner),
    fork(getHouseOwner),
    fork(getHouseOwners),
    fork(updateHouseOwner),
    fork(addHouse),
    fork(deleteHouse),
    fork(getHouse),
    fork(getHouseFlats),
    fork(getHouseRooms),
    fork(getHouses),
    fork(updateHouse),
    fork(addLandlord),
    fork(deleteLandlord),
    fork(getLandlord),
    fork(getLandlords),
    fork(upadteLandlord),
    fork(savePayment),
    fork(addRoom),
    fork(deleteRoom),
    fork(getAllRooms),
    fork(getRoom),
    fork(getRoomFlat),
    fork(getRoomHouse),
    fork(updateRoom),
    fork(createTermination),
    fork(deleteTermination),
    fork(getTermination),
    fork(getTickets),
    fork(createTicket),
    fork(closeTicket),
    fork(getServiceProviders),
    fork(addServiceProvider),
    fork(updateServiceProvider),
    fork(getServiceProvider),
    fork(deleteServiceProvider),
    fork(getDebts),
    fork(getPayments),
    fork(canBookRoom),
    fork(bookingNextTenant),
    fork(getBookings),
    fork(registerServiceProvider),
    fork(rentalEntrance),
    fork(forgotPassword),
    fork(resetPassword),
    fork(createNewTenant),
    fork(activateAccount),
    fork(getTenantBalanceForLandlord),
    fork(addPayments),
    fork(confirmEmailByCode),
    fork(getTenantBalanceForLandlord),
    fork(saveCreditSettings),
    fork(getCreditSettings),
    fork(getOfferedCreditNotes),
    fork(getAllCreditNotes),
    fork(getCurrentAdvertiserPosition),
    fork(getCreditSettings),
    fork(registerTenant),
    fork(validatePromotionCode),
    fork(getAdvertisedBonusForRoom),
    fork(getNewsletterSubscription),
    fork(setNewsletterSubscription),
    fork(getBankingStatus),
    fork(synchronizeBanking),
    fork(updateIban),
    fork(updateServiceProviderInfo),
    fork(doesTerminationExist),
    fork(updateTenantData),
    fork(updateBooking),
    fork(bookAsLandlord),
    fork(addInvitedNewTenant),
    fork(assignTicket),
    fork(createLog),
    fork(createClaim),
    fork(approveDepositSettlement)
  ]);
}
