export const UPDATE_ROOM = 'UPDATE_ROOM';
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE';
export const UPDATE_ROOM_CLEAR = 'UPDATE_ROOM_CLEAR';

export const updateRoom = (
  id,
  symbol,
  squareMeters,
  price,
  deposit,
  description,
  equipment,
  images,
  imageIds,
  freeAt,
  locationOfRoom
) => ({
  type: UPDATE_ROOM,
  id,
  symbol,
  squareMeters,
  price,
  deposit,
  description,
  equipment,
  images,
  imageIds,
  freeAt,
  locationOfRoom,
});
export const updateRoomSuccess = (payload) => ({
  type: UPDATE_ROOM_SUCCESS,
  payload,
});
export const updateRoomFailure = (errors) => ({
  type: UPDATE_ROOM_FAILURE,
  errors,
});
export const updateRoomClear = () => ({
  type: UPDATE_ROOM_CLEAR,
});
