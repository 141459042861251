import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
  removeItemInArray
} from '../../utils/reducer.helper';
import { GET_ALL_ROOMS_SUCCESS } from './getAllRooms/getAllRooms.actions';
import { ADD_ROOM_SUCCESS } from './addRoom/addRoom.actions';
import { UPDATE_ROOM_SUCCESS } from './updateRoom/updateRoom.actions';
import { DELETE_ROOM_SUCCESS } from './deleteRoom/deleteRoom.actions';

export default createReducer(
  {
    [GET_ALL_ROOMS_SUCCESS]: (state, action) => {
      const rooms = action.payload.map(room => ({
        ...room
      }));
      return updateArray(state, rooms);
    },
    [ADD_ROOM_SUCCESS]: (state, action) => pushToArray(state, action.payload),
    [UPDATE_ROOM_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [DELETE_ROOM_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id)
  },
  []
);
