import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

const Faq = ({ t, keys }) => {
  return (
    <Container className="mt-5">
      {keys.map(key => {
        return (
          <>
            <h5>{t('questions.' + key)}</h5>
            <p>{t('answers.' + key)}</p>
            <br />
          </>
        );
      })}
    </Container>
  );
};

Faq.propTypes = {
  t: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired
};

export default Faq;
