import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Statistics from './Statistics';
import { getRentalEntrance } from '../../store/statistics/rentalEntrance/rentalEntrance.actions';

const StatisticsContainer = () => {
  const { t } = useTranslation('statistics');
  const dispatch = useDispatch();

  const { success, data } = useSelector(state => state.rentalEntrance);

  useEffect(() => {
    if (!success) dispatch(getRentalEntrance());
  }, [success]);

  const rowEvents = {
    onClick: () => {},
    onMouseEnter: () => {}
  };

  const columns = [
    {
      dataField: 'date',
      text: t('date'),
      sort: true,
      headerStyle: { width: '80%' },
      formatter: date => moment(date).format('MM.YYYY')
    },
    {
      dataField: 'amount',
      text: t('entrance'),
      sort: true,
      headerStyle: { width: '20%' },
      style: { color: 'green' },
      formatter: amount => `${amount}€`
    }
  ];

  return (
    <>
      {success && (
        <Statistics rentalEntrances={data} {...{ t, rowEvents, columns }} />
      )}
    </>
  );
};

export default StatisticsContainer;
