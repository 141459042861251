import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import { ADD_FLAT, addFlatSuccess, addFlatFailure } from './addFlat.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* addFlat({ symbol, level, description, squareMeters, houseId }) {
  try {
    const { data, errors } = yield call(
      flatApi.addFlat,
      symbol,
      parseInt(level),
      description,
      parseInt(squareMeters),
      houseId
    );

    if (!errors) {
      yield put(addFlatSuccess(data));
      successToast('addFlat');
    } else {
      yield put(addFlatFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addFlatFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(ADD_FLAT, addFlat);
}
