import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Imprint = () => {
  const { t } = useTranslation('imprint');
  return (
    <Container className="my-5">
      <h2>{t('imprint')}</h2>
      <b>Shadet UG (haftungsbeschränkt)</b>
      <br />
      <br />
      Fauviller Ring 1
      <br />
      53501 Grafschaft
      <br />
      <br />
      <p>
        <b>{t('contact')}</b>

        <br />
        {`${t('email')}: info@shadet.de`}
      </p>
      <div className="mt-5">
        <p>
          <h5>{t('liabilityForContent')}</h5>
          <p>{t('liabilityForContentText')}</p>
        </p>
        <p>
          <h5>{t('liabilityForLinks')}</h5>
          <p>{t('liabilityForLinksText')}</p>
        </p>
        <p>
          <h5>{t('copyright')}</h5>
          <p>{t('copyrightText')}</p>
        </p>
        <p>
          <h5>{t('dataPrivacy')}</h5>
          <p>
            <a href="/dataPrivacy">{t('dataPrivacyText')} </a>
          </p>
        </p>
        <p>
          <h5>{t('legalValidityOfThisDisclaimer')}</h5>
          <p>{t('legalValidityOfThisDisclaimerText')}</p>
        </p>
        <p>
          <h5>{t('usedPictures')}</h5>
        </p>
      </div>
    </Container>
  );
};

export default Imprint;
