import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { confirmEmailByCode } from 'store/account/confirmEmailByCode/confirmEmailByCode.actions';
import ConfirmEmailByCode from './ConfirmEmailByCode';

const ConfirmEmailByCodeContainer = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('register');
  const [code, setCode] = useState([]);
  const email = localStorage.getItem('email');

  const { loading } = useSelector(state => state.request.confirmEmailByCode);

  const handleSubmit = code => {
    dispatch(confirmEmailByCode(email, code));
  };

  const handleCodeChange = e => setCode(e.target.value);

  return (
    <ConfirmEmailByCode
      {...{
        t,
        code,
        loading,
        handleCodeChange,
        confirmUsersEmail: handleSubmit,
        email
      }}
    />
  );
};

ConfirmEmailByCodeContainer.propTypes = {};

export default ConfirmEmailByCodeContainer;
