import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ticketApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  createTicketFailure,
  createTicketSuccess,
  CREATE_TICKET
} from './createTicket.actions';

function* createTicket({
  serviceProviderId,
  description,
  entityType,
  entityTypeId,
  intervalInDays,
  images
}) {
  try {
    const formData = new FormData();
    if (typeof serviceProviderId === 'number') {
      formData.append('serviceProviderId', serviceProviderId);
    }
    formData.append('description', description);
    formData.append('entityType', entityType);
    formData.append('entityTypeId', entityTypeId);
    if (intervalInDays) {
      formData.append('intervalInDays', intervalInDays);
    }
    images.forEach(file => {
      formData.append('images', file);
    });

    const { data, errors } = yield call(ticketApi.createTicket, formData);

    if (!errors) {
      yield put(createTicketSuccess(data));
      successToast('createTicket');
    } else {
      yield put(createTicketFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(createTicketFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(CREATE_TICKET, createTicket);
}
