import API from './API';

const prefix = 'house/owner';

export const getHouseOwner = id => API.get(`${prefix}/${id}`);
export const getHouseOwners = () => API.get(`${prefix}`);
export const addHouseOwner = (addressId, firstName, lastName) =>
  API.post(`${prefix}/`, { addressId, firstName, lastName });
export const updateHouseOwner = (id, addressId, firstName, lastName) =>
  API.put(`${prefix}/`, { houseOwnerId: id, addressId, firstName, lastName });
export const deleteHouseOwner = id => API.delete(`${prefix}/${id}`);

export default {
  getHouseOwner,
  getHouseOwners,
  addHouseOwner,
  updateHouseOwner,
  deleteHouseOwner
};
