import { put, takeLatest, call } from 'redux-saga/effects';
import { terminationApi } from 'utils/api';
import i18n from 'i18next';
import {
  DELETE_TERMINATION,
  deleteTerminationSuccess,
  deleteTerminationFailure
} from './deleteTermination.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteTermination() {
  try {
    const { data, errors } = yield call(terminationApi.deleteTermination);

    if (!errors) {
      yield put(deleteTerminationSuccess(data));
      successToast('deleteTermination');
    } else {
      yield put(deleteTerminationFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteTerminationFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_TERMINATION, deleteTermination);
}
