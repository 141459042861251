import PropTypes from 'prop-types';
import React from 'react';
import { Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import MenuItem from '../MenuItem';

const Menu = ({
  t,
  history,
  menuItems,
  daysLeftForLackOfMoveIn,
  daysLeftForStartOfContract,
  highlightedMenuPoint,
  setHighlightedMenuPoint,
  isMobile,
  user,
}) => (
  <>
    <style>
      {`
        .menuItem:hover {
          background-color: #E0E0E0;
        }
      `}
    </style>
    {!isMobile ? (
      <Row
        className="my-2 flex-column d-lg-flex d-none"
        style={{ fontSize: '18px' }}
      >
        {menuItems.map((x) => (
          <div key={x.key}>
            {(x.key !== 3 || user.booking) &&
              (x.key !== 5 || daysLeftForStartOfContract <= 0) &&
              (x.key !== 4 ||
                (daysLeftForLackOfMoveIn > 0 &&
                  daysLeftForLackOfMoveIn < 6)) && (
                <MenuItem roles={[...x.roles]} key={x.key}>
                  <Col
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        highlightedMenuPoint === x.key ? '#E0E0E0' : '',
                    }}
                    onClick={() => {
                      history.push(x.destination);
                      setHighlightedMenuPoint(x.key);
                    }}
                    className={`menuItem p-0`}
                  >
                    <div
                      className="px-3"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '3rem',
                        overflow: 'hidden',
                      }}
                    >
                      <div>
                        {t(x.title) +
                          (x.key === 4
                            ? ` (${daysLeftForLackOfMoveIn} ${t('daysLeft')})`
                            : '')}
                      </div>
                    </div>
                  </Col>
                </MenuItem>
              )}
          </div>
        ))}
      </Row>
    ) : (
      <DropdownButton className="mt-3 d-lg-none d-flex" title={t('history')}>
        {menuItems.map((x) => (
          <div key={x.key}>
            {(x.key !== 4 ||
              (daysLeftForLackOfMoveIn > 0 && daysLeftForLackOfMoveIn < 6)) && (
              <MenuItem roles={[...x.roles]} key={x.key}>
                <Dropdown.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(x.destination)}
                >
                  {t(x.title) +
                    (x.key === 4
                      ? ` (${daysLeftForLackOfMoveIn} ${t('daysLeft')})`
                      : '')}{' '}
                </Dropdown.Item>
              </MenuItem>
            )}
          </div>
        ))}
      </DropdownButton>
    )}
  </>
);

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  active: PropTypes.string,
};

export default Menu;
