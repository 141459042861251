import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_LANDLORDS,
  GET_LANDLORDS_SUCCESS,
  GET_LANDLORDS_FAILURE,
  GET_LANDLORDS_CLEAR
} from './getLandlords.actions';

export default createReducer(
  {
    [GET_LANDLORDS]: defaultRequest,
    [GET_LANDLORDS_SUCCESS]: defaultSuccess,
    [GET_LANDLORDS_FAILURE]: defaultError,
    [GET_LANDLORDS_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
