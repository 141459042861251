import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  RESET_PASSWORD,
  resetPasswordSuccess,
  resetPasswordFailure
} from './resetPassword.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* resetPassword({ email, token, password }) {
  try {
    const { data, errors } = yield call(
      accountApi.resetPassword,
      email,
      token,
      password
    );

    if (!errors) {
      successToast('passwordReset');
      yield put(resetPasswordSuccess(data));
    } else {
      yield put(resetPasswordFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(resetPasswordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
