import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LandLordSettings from './LandlordSettings';
import { saveCreditSettings } from 'store/settings/credit/saveCreditSettings/saveCreditSettings.actions';
import { getCreditSettings } from 'store/settings/credit/getCreditSettings/getCreditSettings.actions';
import { getBankingStatus } from 'store/banking/getBankingStatus/getBankingStatus.actions.js';

const LandlordSettingsContainer = () => {
  const { t } = useTranslation('settings');
  const dispatch = useDispatch();

  const [creditAdvertiser, setCreditAdvertiser] = useState(0);
  const [creditAdvertised, setCreditAdvertised] = useState(0);
  const [bonus, setBonus] = useState(0);

  const creditSettings = useSelector(state => state.settings.credit);
  const { errors } = useSelector(state => state.request.getCreditSettings);

  useEffect(() => {
    if (Object.keys(creditSettings).length !== 0) return;
    dispatch(getCreditSettings());
    dispatch(getBankingStatus());
  }, []);

  useEffect(() => {
    if (Object.keys(creditSettings).length === 0) return;
    setCreditAdvertiser(creditSettings.creditAdvertiser);
    setCreditAdvertised(creditSettings.creditAdvertised);
    setBonus(creditSettings.bonus);
  }, [creditSettings]);

  const onCreditAdvertiserChange = e =>
    setCreditAdvertiser(parseFloat(e.target.value));
  const onCreditAdvertisedChange = e =>
    setCreditAdvertised(parseFloat(e.target.value));
  const onBonusChange = e => setBonus(parseFloat(e.target.value));

  const onSubmit = e => {
    e.preventDefault();
    dispatch(saveCreditSettings({ bonus, creditAdvertised, creditAdvertiser }));
  };

  const submitDisabled = !(
    !isNaN(creditAdvertiser) &&
    !isNaN(creditAdvertised) &&
    !isNaN(bonus) &&
    (bonus !== creditSettings.bonus ||
      creditAdvertised !== creditSettings.creditAdvertised ||
      creditAdvertiser !== creditSettings.creditAdvertiser)
  );

  return (
    <LandLordSettings
      {...{
        creditAdvertiser,
        onCreditAdvertiserChange,
        creditAdvertised,
        onCreditAdvertisedChange,
        bonus,
        onBonusChange,
        onSubmit,
        t,
        errors,
        submitDisabled
      }}
    />
  );
};

export default LandlordSettingsContainer;
