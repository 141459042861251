import { createReducer, updateArray, pushToArray } from 'utils/reducer.helper';

import { GET_CLAIM_REPORTS_SUCCESS } from './getClaimReports/getClaimReports.actions';
import { INVOICE_CLAIM_REPORT_SUCCESS } from './invoiceClaimReport/invoiceClaimReport.actions';
import { SEND_CLAIM_REPORT_SUCCESS } from './sendClaimReport/sendClaimReport.actions';

export default createReducer(
  {
    [GET_CLAIM_REPORTS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [SEND_CLAIM_REPORT_SUCCESS]: (state, action) =>
      pushToArray(state, { ...action.payload }),
    [INVOICE_CLAIM_REPORT_SUCCESS]: (state, action) =>
      pushToArray(state, { ...action.payload })
  },
  []
);
