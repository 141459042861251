export const GET_ADVERTISED_BONUS_FOR_ROOM = 'GET_ADVERTISED_BONUS_FOR_ROOM';
export const GET_ADVERTISED_BONUS_FOR_ROOM_SUCCESS =
  'GET_ADVERTISED_BONUS_FOR_ROOM_SUCCESS';
export const GET_ADVERTISED_BONUS_FOR_ROOM_FAILURE =
  'GET_ADVERTISED_BONUS_FOR_ROOM_FAILURE';
export const GET_ADVERTISED_BONUS_FOR_ROOM_RESET =
  'GET_ADVERTISED_BONUS_FOR_ROOM_RESET';

export const getAdvertisedBonusForRoom = (roomId) => ({
  type: GET_ADVERTISED_BONUS_FOR_ROOM,
  roomId
});
export const getAdvertisedBonusForRoomSuccess = payload => ({
  type: GET_ADVERTISED_BONUS_FOR_ROOM_SUCCESS,
  payload
});
export const getAdvertisedBonusForRoomFailure = errors => ({
  type: GET_ADVERTISED_BONUS_FOR_ROOM_FAILURE,
  errors
});

export const getAdvertisedBonusForRoomReset = () => ({
  type: GET_ADVERTISED_BONUS_FOR_ROOM_RESET
});
