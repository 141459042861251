import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DebtOverviewListItem = ({ firstName, lastName, accountBalance }) => (
  <Link to="#" className="list-group-item list-group-item-action">
    <Row>
      <Col className="col-4">{firstName}</Col>
      <Col className="col-7">{lastName}</Col>
      <Col
        className="col-1"
        style={{ textAlign: 'right', color: 'red' }}
      >{`${accountBalance}€`}</Col>
    </Row>
  </Link>
);

DebtOverviewListItem.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  accountBalance: PropTypes.string.isRequired
};

export default DebtOverviewListItem;
