import API from './API';

const prefix = 'termination';

export const getTermination = () => API.get(`${prefix}/`);

export const createTermination = (effectiveFrom, nextTenantEmail, bookingId) =>
  API.post(`${prefix}/`, { effectiveFrom, nextTenantEmail, bookingId });

export const deleteTermination = () => API.delete(`${prefix}/`);

export const doesTerminationExist = id =>
  API.get(`${prefix}/doesTerminationExist/${id}`);

export const addInvitedNewTenant = email =>
  API.put(`${prefix}/newTenant?email=${email}`);

export default {
  getTermination,
  createTermination,
  deleteTermination,
  doesTerminationExist,
  addInvitedNewTenant
};
