import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_USERINFO,
  UPDATE_USERINFO_SUCCESS,
  UPDATE_USERINFO_FAILURE
} from './updateUserInfo.actions';

export default createReducer(
  {
    [UPDATE_USERINFO]: defaultRequest,
    [UPDATE_USERINFO_SUCCESS]: defaultSuccess,
    [UPDATE_USERINFO_FAILURE]: defaultError
  },
  defaultInitialState
);
