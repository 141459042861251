export const CREATE_NEW_TENANT = 'CREATE_NEW_TENANT';
export const CREATE_NEW_TENANT_SUCCESS = 'CREATE_NEW_TENANT_SUCCESS';
export const CREATE_NEW_TENANT_FAILURE = 'CREATE_NEW_TENANT_FAILURE';

export const createNewTenant = (firstName, lastName, email, password) => ({
  type: CREATE_NEW_TENANT,
  firstName,
  lastName,
  email,
  password
});
export const createNewTenantSuccess = payload => ({
  type: CREATE_NEW_TENANT_SUCCESS,
  payload
});
export const createNewTenantFailure = errors => ({
  type: CREATE_NEW_TENANT_FAILURE,
  errors
});
