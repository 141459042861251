export const GET_FLAT_ROOMS = 'GET_FLAT_ROOMS';
export const GET_FLAT_ROOMS_SUCCESS = 'GET_FLAT_ROOMS_SUCCESS';
export const GET_FLAT_ROOMS_FAILURE = 'GET_FLAT_ROOMS_FAILURE';

export const getFlatRooms = id => ({
  type: GET_FLAT_ROOMS,
  id
});
export const getFlatRoomsSuccess = payload => ({
  type: GET_FLAT_ROOMS_SUCCESS,
  payload
});
export const getFlatRoomsFailure = errors => ({
  type: GET_FLAT_ROOMS_FAILURE,
  errors
});
