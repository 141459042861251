import moment from 'moment';

export const toIsoDate = dateTime => new Date(dateTime).toISOString();

export const toShortIsoDate = dateTime =>
  new Date(dateTime).toISOString().split('T')[0];

export const toAge = date => {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }

  return age;
};

export const toInputValue = dateTime => moment(dateTime).format('YYYY-MM-DD');

export const toDisplayDate = dateTime => moment(dateTime).format('DD.MM.YYYY');
