import { DateFormControl, LoadingButton } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { FormLabel, Modal } from 'react-bootstrap';

const KickOutModal = ({
  isOpen,
  handleClose,
  t,
  handleKickOut,
  isLoading,
  date,
  setDate,
}) => (
  <Modal
    show={isOpen}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>{t('releaseRoom')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormLabel>{t('terminateTo')}</FormLabel>
      <DateFormControl
        min={moment().startOf('day')}
        value={date}
        onChange={setDate}
      />
      {t('releaseRoomValidation')}
    </Modal.Body>
    <Modal.Footer>
      <LoadingButton
        onClick={() => handleKickOut()}
        type="submit"
        isLoading={isLoading}
      >
        {t('kickOut')}
      </LoadingButton>
    </Modal.Footer>
  </Modal>
);

KickOutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleKickOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default KickOutModal;
