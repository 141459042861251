import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  INVOICE_CLAIM_REPORT,
  INVOICE_CLAIM_REPORT_SUCCESS,
  INVOICE_CLAIM_REPORT_FAILURE,
  INVOICE_CLAIM_REPORT_CLEAR
} from './invoiceClaimReport.actions';

export default createReducer(
  {
    [INVOICE_CLAIM_REPORT]: defaultRequest,
    [INVOICE_CLAIM_REPORT_SUCCESS]: defaultSuccess,
    [INVOICE_CLAIM_REPORT_FAILURE]: defaultError,
    [INVOICE_CLAIM_REPORT_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
