export const CLOSE_TICKET = 'CLOSE_TICKET';
export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS';
export const CLOSE_TICKET_FAILURE = 'CLOSE_TICKET_FAILURE';

export const closeTicket = (id, amount) => ({
  type: CLOSE_TICKET,
  id,
  amount
});
export const closeTicketSuccess = payload => ({
  type: CLOSE_TICKET_SUCCESS,
  payload
});
export const closeTicketFailure = errors => ({
  type: CLOSE_TICKET_FAILURE,
  errors
});
