import {
  createReducer,
  pushToArray,
  updateArray,
  updateItemInArray
} from 'utils/reducer.helper';
import { ASSIGN_TICKET_SUCCESS } from './assignTicket/assignTicket.actions';
import { CLOSE_TICKET_SUCCESS } from './closeTicket/closeTicket.actions';
import { CREATE_TICKET_SUCCESS } from './createTicket/createTicket.actions';
import { GET_TICKETS_SUCCESS } from './getTickets/getTickets.actions';

export default createReducer(
  {
    [GET_TICKETS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [CREATE_TICKET_SUCCESS]: (state, action) =>
      pushToArray(state, { ...action.payload }),
    [CLOSE_TICKET_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [ASSIGN_TICKET_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload)
  },
  []
);
