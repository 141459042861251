import {
  createReducer,
  defaultError,
  defaultInitialState,
  defaultRequest,
  defaultSuccess,
} from 'utils/reducer.helper';
import {
  CREATE_CLAIM,
  CREATE_CLAIM_CLEAR,
  CREATE_CLAIM_FAILURE,
  CREATE_CLAIM_SUCCESS,
} from './createClaim.actions';

export default createReducer(
  {
    [CREATE_CLAIM]: defaultRequest,
    [CREATE_CLAIM_SUCCESS]: defaultSuccess,
    [CREATE_CLAIM_FAILURE]: defaultError,
    [CREATE_CLAIM_CLEAR]: () => defaultInitialState,
  },
  defaultInitialState
);
