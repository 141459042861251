import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_FLAT,
  ADD_FLAT_SUCCESS,
  ADD_FLAT_FAILURE,
  ADD_FLAT_CLEAR
} from './addFlat.actions';

export default createReducer(
  {
    [ADD_FLAT]: defaultRequest,
    [ADD_FLAT_SUCCESS]: defaultSuccess,
    [ADD_FLAT_FAILURE]: defaultError,
    [ADD_FLAT_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
