import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FlatForm from './FlatForm';

const FlatFormContainer = ({
  onSubmit,
  level: _level,
  squareMeters: _size,
  houseId: _houseId,
  description: _description,
  symbol: _symbol,
  isLoading
}) => {
  const { t } = useTranslation('data');

  const houses = useSelector(state => state.houses);

  const [symbol, setSymbol] = useState(_symbol);
  const [level, setLevel] = useState(_level);
  const [squareMeters, setSize] = useState(_size);
  const [houseId, setHouseId] = useState(_houseId);
  const [description, Description] = useState(_description);
  const house = houses.find(x => x.id === houseId);

  const handleLevelChange = e => setLevel(e.target.value);
  const handleSquareMetersChange = e => setSize(e.target.value);
  const handleHouseIdChange = houseId => {
    setHouseId(houseId);
    setLevel('');
  };
  const handleDescriptionChange = e => Description(e.target.value);
  const handleSymbolChange = e => setSymbol(e.target.value);

  const isSubmitEnabled = house !== undefined && level <= house.levels;

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      symbol,
      level,
      squareMeters,
      houseId,
      house,
      description
    });
  };

  return (
    <FlatForm
      {...{
        level,
        symbol,
        handleLevelChange,
        squareMeters,
        handleSquareMetersChange,
        houseId,
        handleHouseIdChange,
        description,
        handleDescriptionChange,
        handleSymbolChange,
        handleSubmit,
        t,
        isSubmitEnabled,
        houses,
        house,
        isLoading
      }}
    />
  );
};

FlatFormContainer.defaultProps = {
  onSubmit: () => {},
  squareMeters: '0'
};

FlatFormContainer.propTypes = {
  symbol: PropTypes.string,
  level: PropTypes.number,
  squareMeters: PropTypes.number,
  houseId: PropTypes.number,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default FlatFormContainer;
