import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHouseGivers } from 'store/houseGiver/getHouseGivers/getHouseGivers.actions';

const useHouseGivers = (selector = state => state.houseGivers) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getHouseGivers);
  const houseGivers = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getHouseGivers());
  }, [success]);

  return houseGivers;
};

export default useHouseGivers;
