import {
  createReducer,
  defaultError,
  defaultInitialState,
  defaultRequest,
  defaultSuccess,
} from 'utils/reducer.helper';
import {
  SEND_CLAIM_REPORT,
  SEND_CLAIM_REPORT_CLEAR,
  SEND_CLAIM_REPORT_FAILURE,
  SEND_CLAIM_REPORT_SUCCESS,
} from './sendClaimReport.actions';

export default createReducer(
  {
    [SEND_CLAIM_REPORT]: defaultRequest,
    [SEND_CLAIM_REPORT_SUCCESS]: defaultSuccess,
    [SEND_CLAIM_REPORT_FAILURE]: defaultError,
    [SEND_CLAIM_REPORT_CLEAR]: () => defaultInitialState,
  },
  defaultInitialState
);
