import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const SizeFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formSymbol">
    <FormLabel>{i18n.t('forms:symbol')}</FormLabel>
    <FormControl value={value} onChange={onChange} name="symbol" {...rest} />
  </FormGroup>
);

SizeFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SizeFormGroup;
