export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';

export const getBookings = () => ({
  type: GET_BOOKINGS
});

export const getBookingsSuccess = payload => ({
  type: GET_BOOKINGS_SUCCESS,
  payload
});

export const getBookingsFailure = errors => ({
  type: GET_BOOKINGS_FAILURE,
  errors
});
