import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE
} from './addAddress.actions';

export default createReducer(
  {
    [ADD_ADDRESS]: defaultRequest,
    [ADD_ADDRESS_SUCCESS]: defaultSuccess,
    [ADD_ADDRESS_FAILURE]: defaultError
  },
  defaultInitialState
);
