import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'components/Content';
import { RoomList } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Flat = ({
  flat,
  handleEditFlatClick,
  handleDeleteFlatClick,
  t,
  flatSelector
}) => (
  <Content>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('flat.editFlat')}</Tooltip>}
    >
      <Button
        style={{ float: 'right' }}
        onClick={handleEditFlatClick}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('flat.deleteFlat')}</Tooltip>}
    >
      <Button
        style={{ float: 'right', marginRight: 20 }}
        onClick={handleDeleteFlatClick}
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </OverlayTrigger>
    <h2>
      {t('flat.flat')} {flat.id}
    </h2>
    <h5>{t('flat.symbol')}</h5>
    <span>{flat.symbol}</span>
    <h5>{t('flat.description')}</h5>
    <p style={{ whiteSpace: 'pre-wrap' }}>{flat.description}</p>
    <h5>{t('flat.address')}</h5>
    <span>{`${flat.address.postalCode} ${flat.address.location} ${flat.address.street} ${flat.address.houseNumber}`}</span>
    <h5>{t('flat.level')}</h5>
    <span>{flat.level}</span>
    <h5>{t('flat.size')}</h5>
    <span>{flat.squareMeters}</span>
    <h5 style={{ marginTop: '20px' }}>{t('flat.rooms')}</h5>
    <RoomList roomSelector={flatSelector} />
  </Content>
);

Flat.propTypes = {
  flat: PropTypes.object.isRequired,
  handleEditFlatClick: PropTypes.func.isRequired,
  handleDeleteFlatClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  flatSelector: PropTypes.func.isRequired
};

export default Flat;
