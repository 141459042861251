import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseApi } from 'utils/api';
import {
  GET_HOUSE_ROOMS,
  getHouseRoomsSuccess,
  getHouseRoomsFailure
} from './getHouseRooms.actions';

function* getHouseRooms({ id }) {
  try {
    const { data, errors } = yield call(houseApi.getHouseRooms, id);

    if (!errors) {
      yield put(getHouseRoomsSuccess(data));
    } else {
      yield put(getHouseRoomsFailure(errors));
    }
  } catch (error) {
    yield put(getHouseRoomsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE_ROOMS, getHouseRooms);
}
