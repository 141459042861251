import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const Description = ({
  t,
  descriptonRoom,
  descriptionApartment,
  symbolRoom,
  symbolFlat,
  freeAt,
  locationOfRoom,
}) => (
  <>
    <h6 className="mt-2">
      <strong>
        {t('room:descriptionRoom')} - {symbolRoom}
      </strong>
    </h6>
    <p style={{ whiteSpace: 'pre-wrap' }}>{locationOfRoom}</p>
    <p style={{ whiteSpace: 'pre-wrap' }}>{descriptonRoom}</p>

    <h6 className="mt-2">
      <strong>
        {t('room:descriptionApartment')} - {symbolFlat}
      </strong>
    </h6>
    <p style={{ whiteSpace: 'pre-wrap' }}>{descriptionApartment}</p>

    <h6 className="mt-2">
      <strong>{t('room:tour')}</strong>
    </h6>
    <p style={{ whiteSpace: 'pre-wrap' }}>
      {moment().isBefore(freeAt)
        ? t('room:tourNotPossible')
        : t('room:tourInstructions').replace('{roomSymbol}', symbolRoom)}
    </p>
  </>
);

Description.propTypes = {
  t: PropTypes.func.isRequired,
  descriptonRoom: PropTypes.string.isRequired,
  descriptionApartment: PropTypes.string.isRequired,
  symbolRoom: PropTypes.string.isRequired,
  symbolFlat: PropTypes.string.isRequired,
};

export default Description;
