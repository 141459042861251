export const GET_ROOM_FLAT = 'GET_ROOM_FLAT';
export const GET_ROOM_FLAT_SUCCESS = 'GET_ROOM_FLAT_SUCCESS';
export const GET_ROOM_FLAT_FAILURE = 'GET_ROOM_FLAT_FAILURE';

export const getRoomFlat = id => ({
  type: GET_ROOM_FLAT,
  id
});
export const getRoomFlatSuccess = payload => ({
  type: GET_ROOM_FLAT_SUCCESS,
  payload
});
export const getRoomFlatFailure = errors => ({
  type: GET_ROOM_FLAT_FAILURE,
  errors
});
