import { put, takeLatest, call } from 'redux-saga/effects';
import { ticketApi } from 'utils/api';
import i18n from 'i18next';
import {
  CLOSE_TICKET,
  closeTicketSuccess,
  closeTicketFailure
} from './closeTicket.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* closeTicket({ id, amount }) {
  try {
    const { data, errors } = yield call(ticketApi.closeTicket, id, amount);

    if (!errors) {
      yield put(closeTicketSuccess(data));
      successToast('closeTicket');
    } else {
      yield put(closeTicketFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(closeTicketFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(CLOSE_TICKET, closeTicket);
}
