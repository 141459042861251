import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi } from 'utils/api';
import {
  ADD_LANDLORD,
  addLandlordSuccess,
  addLandlordFailure
} from './addLandlord.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* addLandlordSaga({ houseId, addressId, firstName, lastName }) {
  try {
    const { data, errors } = yield call(
      landlordApi.addLandlord,
      houseId,
      addressId,
      firstName,
      lastName
    );

    if (!errors) {
      yield put(addLandlordSuccess(data));
      successToast('addLandlord');
    } else {
      yield put(addLandlordFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addLandlordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_LANDLORD, addLandlordSaga);
}
