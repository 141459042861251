import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
  removeItemInArray,
  mergeArrays
} from 'utils/reducer.helper';

import { ADD_FLAT_SUCCESS } from './addFlat/addFlat.actions';
import { DELETE_FLAT_SUCCESS } from './deleteFlat/deleteFlat.actions';
import { GET_FLAT_SUCCESS } from './getFlat/getFlat.actions';
import { GET_FLATS_SUCCESS } from './getFlats/getFlats.actions';
import { UPDATE_FLAT_SUCCESS } from './updateFlat/updateFlat.actions';
import { GET_HOUSE_FLATS_SUCCESS } from '../houses/getHouseFlats/getHouseFlats.actions';

export default createReducer(
  {
    [GET_FLATS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [GET_FLAT_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [ADD_FLAT_SUCCESS]: (state, action) => pushToArray(state, action.payload),
    [UPDATE_FLAT_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [DELETE_FLAT_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id),
    [GET_HOUSE_FLATS_SUCCESS]: (state, action) =>
      mergeArrays(state, action.payload)
  },
  []
);
