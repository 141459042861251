import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RoomEquipmentFormGroup from './RoomEquipmentFormGroup';

const RoomEquipmentFormGroupContainer = ({ equipment, onChange }) => {
  const { t } = useTranslation('data');

  if (!equipment.allInclusive) {
    equipment = {
      ...equipment,
      allInclusive: equipment.allInclusive ? equipment.allInclusive : false
    };
  }
  if (!equipment.furnished) {
    equipment = {
      ...equipment,
      furnished: equipment.furnished ? equipment.furnished : false
    };
  }
  if (!equipment.internet) {
    equipment = {
      ...equipment,
      internet: equipment.internet ? equipment.internet : false
    };
  }
  if (!equipment.kitchen) {
    equipment = {
      ...equipment,
      kitchen: equipment.kitchen ? equipment.kitchen : false
    };
  }
  if (!equipment.tenancyAgreementInclusive) {
    equipment = {
      ...equipment,
      tenancyAgreementInclusive: equipment.tenancyAgreementInclusive
        ? equipment.tenancyAgreementInclusive
        : false
    };
  }
  if (!equipment.washer) {
    equipment = {
      ...equipment,
      washer: equipment.washer ? equipment.washer : false
    };
  }

  const onAllInclusiveChange = e => {
    onChange({
      ...e,
      target: {
        ...e.target,
        value: { ...equipment, allInclusive: !equipment.allInclusive }
      }
    });
  };

  const onFurnishedChange = e =>
    onChange({
      ...e,
      target: {
        ...e.target,
        value: { ...equipment, furnished: !equipment.furnished }
      }
    });

  const onInternetChange = e =>
    onChange({
      ...e,
      target: {
        ...e.target,
        value: { ...equipment, internet: !equipment.internet }
      }
    });

  const onKitchenChange = e =>
    onChange({
      ...e,
      target: {
        ...e.target,
        value: { ...equipment, kitchen: !equipment.kitchen }
      }
    });

  const onTenancyAgreementInclusiveChange = e =>
    onChange({
      ...e,
      target: {
        ...e.target,
        value: {
          ...equipment,
          tenancyAgreementInclusive: !equipment.tenancyAgreementInclusive
        }
      }
    });

  const onWasherChange = e =>
    onChange({
      ...e,
      target: {
        ...e.target,
        value: { ...equipment, washer: !equipment.washer }
      }
    });

  return (
    <RoomEquipmentFormGroup
      {...{
        allInclusive: equipment.allInclusive,
        furnished: equipment.furnished,
        internet: equipment.internet,
        kitchen: equipment.kitchen,
        tenancyAgreementInclusive: equipment.tenancyAgreementInclusive,
        washer: equipment.washer,
        onAllInclusiveChange,
        onFurnishedChange,
        onInternetChange,
        onKitchenChange,
        onTenancyAgreementInclusiveChange,
        onWasherChange,
        t
      }}
    />
  );
};

RoomEquipmentFormGroupContainer.defaultProps = {
  equipment: {},
  onChange: () => {}
};

RoomEquipmentFormGroupContainer.propTypes = {
  equipment: PropTypes.object,
  onChange: PropTypes.func
};

export default RoomEquipmentFormGroupContainer;
