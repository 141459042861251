import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { bookingApi } from 'utils/api/';
import {
  BOOK_AS_LANDLORD,
  bookAsLandlordSuccess,
  bookAsLandlordFailure
} from './bookAsLandlord.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* bookAsLandlord({
  startDate,
  durationInMonths,
  roomId,
  userId,
  rentalFee,
  deposit
}) {
  try {
    const { data, errors } = yield call(
      bookingApi.bookAsLandlord,
      startDate,
      parseInt(durationInMonths),
      parseInt(roomId),
      parseInt(userId),
      Number.parseFloat(rentalFee),
      Number.parseFloat(deposit)
    );

    if (!errors) {
      yield put(bookAsLandlordSuccess(data));
      successToast('bookAsLandlord');
    } else {
      yield put(bookAsLandlordFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(bookAsLandlordFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(BOOK_AS_LANDLORD, bookAsLandlord);
}
