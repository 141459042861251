import { put, takeLatest, call } from 'redux-saga/effects';
import { roomApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_ALL_ROOMS,
  getAllRoomsSuccess,
  getAllRoomsFailure
} from './getAllRooms.actions';

function* getAllRooms() {
  try {
    const { data, errors } = yield call(roomApi.getRooms);

    if (!errors) {
      yield put(getAllRoomsSuccess(data));
    } else {
      yield put(getAllRoomsFailure(errors));
    }
  } catch (error) {
    yield put(getAllRoomsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_ALL_ROOMS, getAllRooms);
}
