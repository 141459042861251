import { put, takeLatest, call } from 'redux-saga/effects';
import { settingsApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_NEWSLETTER_SUBSCRIPTION,
  getNewsletterSubscriptionSuccess,
  getNewsletterSubscriptionFailure
} from './getNewsletterSubscription.actions';

function* getNewsletterSubscription() {
  try {
    const { data, errors } = yield call(settingsApi.getNewsletterSubscription);

    if (!errors && data.errors.length === 0) {
      yield put(getNewsletterSubscriptionSuccess(data.resultObject));
    } else {
      yield put(getNewsletterSubscriptionFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(getNewsletterSubscriptionFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_NEWSLETTER_SUBSCRIPTION, getNewsletterSubscription);
}
