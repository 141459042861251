import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  CLOSE_TICKET,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAILURE
} from './closeTicket.actions';

export default createReducer(
  {
    [CLOSE_TICKET]: defaultRequest,
    [CLOSE_TICKET_SUCCESS]: defaultSuccess,
    [CLOSE_TICKET_FAILURE]: defaultError
  },
  defaultInitialState
);
