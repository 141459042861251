import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

const Dropdown = ({
  selectProp,
  options,
  value,
  onChange,
  useFirstAsDefault,
  output,
  ...rest
}) => (
  <FormControl as="select" value={value} onChange={onChange} {...rest}>
    {!useFirstAsDefault && <option hidden disabled></option>}
    {options.map(x => (
      <option key={x[selectProp]} value={x[selectProp]}>
        {output(x)}
      </option>
    ))}
  </FormControl>
);

Dropdown.defaultProps = {
  onChange: () => {},
  selector: state => state.rooms,
  options: [],
  value: '',
  selectProp: 'id',
  useFirstAsDefault: false
};

Dropdown.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
  selector: PropTypes.func,
  output: PropTypes.any,
  selectProp: PropTypes.string,
  useFirstAsDefault: PropTypes.bool
};

export default Dropdown;
