export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE';

export const getAddresses = () => ({
  type: GET_ADDRESSES
});
export const getAddressesSuccess = payload => ({
  type: GET_ADDRESSES_SUCCESS,
  payload
});
export const getAddressesFailure = errors => ({
  type: GET_ADDRESSES_FAILURE,
  errors
});
