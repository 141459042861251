import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  GET_CREDIT_SETTINGS,
  GET_CREDIT_SETTINGS_SUCCESS,
  GET_CREDIT_SETTINGS_FAILURE,
  GET_CREDIT_SETTINGS_RESET
} from './getCreditSettings.actions';

export default createReducer(
  {
    [GET_CREDIT_SETTINGS]: defaultRequest,
    [GET_CREDIT_SETTINGS_SUCCESS]: defaultSuccess,
    [GET_CREDIT_SETTINGS_FAILURE]: defaultError,
    [GET_CREDIT_SETTINGS_RESET]: defaultReset
  },
  defaultInitialState
);
