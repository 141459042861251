export const CONFIRM_EMAIL_BY_CODE = 'CONFIRM_EMAIL_BY_CODE';
export const CONFIRM_EMAIL_BY_CODE_SUCCESS = 'CONFIRM_EMAIL_BY_CODE_SUCCESS';
export const CONFIRM_EMAIL_BY_CODE_FAILURE = 'CONFIRM_EMAIL_BY_CODE_FAILURE';
export const CONFIRM_EMAIL_BY_CODE_CLEAR = 'CONFIRM_EMAIL_BY_CODE_CLEAR';

export const confirmEmailByCode = (email, code) => ({
  type: CONFIRM_EMAIL_BY_CODE,
  email,
  code
});
export const confirmEmailByCodeSuccess = payload => ({
  type: CONFIRM_EMAIL_BY_CODE_SUCCESS,
  payload
});
export const confirmEmailByCodeFailure = errors => ({
  type: CONFIRM_EMAIL_BY_CODE_FAILURE,
  errors
});
export const confirmEmailByCodeClear = errors => ({
  type: CONFIRM_EMAIL_BY_CODE_CLEAR,
  errors
});
