export const DELETE_TERMINATION = 'DELETE_TERMINATION';
export const DELETE_TERMINATION_SUCCESS = 'DELETE_TERMINATION_SUCCESS';
export const DELETE_TERMINATION_FAILURE = 'DELETE_TERMINATION_FAILURE';

export const deleteTermination = () => ({
  type: DELETE_TERMINATION
});
export const deleteTerminationSuccess = payload => ({
  type: DELETE_TERMINATION_SUCCESS,
  payload
});
export const deleteTerminationFailure = errors => ({
  type: DELETE_TERMINATION_FAILURE,
  errors
});
