import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPayments } from 'store/payment/getPayments/getPayments.actions';

const usePayments = (selector = state => state.payments) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getPayments);
  const payments = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getPayments());
  }, [success]);

  return payments;
};

export default usePayments;
