import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

const ErrorAlerts = ({ errors, variant }) =>
  errors.map((error, i) => (
    <Alert key={i} variant={variant}>
      {error}
    </Alert>
  ));

ErrorAlerts.propTypes = {
  errors: PropTypes.array,
  variant: PropTypes.string
};

ErrorAlerts.defaultProps = {
  errors: [],
  variant: 'warning'
};

export default ErrorAlerts;
