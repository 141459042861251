import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBookings } from 'store/booking/getBookings/getBookings.actions';

const useBookings = (selector = state => state.bookings) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getBookings);
  const bookings = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getBookings());
  }, [success]);

  return bookings;
};

export default useBookings;
