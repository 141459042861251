export const GET_HOUSE_OWNERS = 'GET_HOUSE_OWNERS';
export const GET_HOUSE_OWNERS_SUCCESS = 'GET_HOUSE_OWNERS_SUCCESS';
export const GET_HOUSE_OWNERS_FAILURE = 'GET_HOUSE_OWNERS_FAILURE';
export const GET_HOUSE_OWNERS_CLEAR = 'GET_HOUSE_OWNERS_CLEAR';

export const getHouseOwners = () => ({
  type: GET_HOUSE_OWNERS
});
export const getHouseOwnersSuccess = payload => ({
  type: GET_HOUSE_OWNERS_SUCCESS,
  payload
});
export const getHouseOwnersFailure = errors => ({
  type: GET_HOUSE_OWNERS_FAILURE,
  errors
});
export const getHouseOwnersClear = () => ({
  type: GET_HOUSE_OWNERS_CLEAR
});
