import PrivateRoute from 'components/PrivateRoute';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Switch } from 'react-router-dom';
import TenantProfile from 'views/tenantProfile';
import Faq from '../faq/faq.container';
import Statistics from '../statistics/Statistics.container';
import { Menu } from './components';
import Balance from './views/balance/Balance.container';
import AddFlat from './views/baseData/flat/addFlat';
import EditFlat from './views/baseData/flat/editFlat';
import Flat from './views/baseData/flat/flat';
import Flats from './views/baseData/flat/flats';
import AddHouse from './views/baseData/house/addHouse';
import EditHouse from './views/baseData/house/editHouse';
import House from './views/baseData/house/house';
import Houses from './views/baseData/house/houses';
import AddHouseGiver from './views/baseData/houseGiver/addHouseGiver';
import EditHouseGiver from './views/baseData/houseGiver/editHouseGiver';
import HouseGiver from './views/baseData/houseGiver/houseGiver';
import HouseGivers from './views/baseData/houseGiver/houseGivers';
import AddHouseOwner from './views/baseData/houseOwner/addHouseOwner';
import EditHouseOwner from './views/baseData/houseOwner/editHouseOwner';
import HouseOwner from './views/baseData/houseOwner/houseOwner';
import HouseOwners from './views/baseData/houseOwner/houseOwners';
import BaseData from './views/baseData/menu';
import AddRoom from './views/baseData/room/addRoom';
import EditRoom from './views/baseData/room/editRoom';
import Room from './views/baseData/room/room';
import RoomsOverview from './views/baseData/room/rooms';
import AddServiceProviders from './views/baseData/serviceProvider/addServiceProvider';
import EditServiceProviders from './views/baseData/serviceProvider/editServiceProvider';
import ServiceProvider from './views/baseData/serviceProvider/serviceProvider';
import ServiceProviders from './views/baseData/serviceProvider/serviceProviders';
import Bookings from './views/bookings';
import ClaimReport from './views/claimReport';
import ClaimReportOverview from './views/claimReports';
import CreateClaimReportServiceProvider from './views/createClaimReport/serviceProvider/CreateClaimReport.serviceProvider.container';
import CreateClaimReportUser from './views/createClaimReport/user/CreateClaimReport.user.container';
import CreateTicket from './views/createTicket/CreateTicket.container';
import CreditNotes from './views/creditNotes';
import DebtOverview from './views/debtOverview';
import Documents from './views/documents';
import EarnMoney from './views/earnMoney';
import CreateNewTenant from './views/landlord/createNewTenant';
import InvoiceDamage from './views/landlord/invoiceDamage';
import Rooms from './views/rooms';
import serviceProviderProfile from './views/serviceProviderProfile';
import { LandlordSettings, TenantSettings } from './views/settings';
import Tenants from './views/tenants';
import BalanceContainer from './views/tenants/tenantBalance/tenantBalance.container';
import Termination from './views/termination';
import Tickets from './views/tickets';

const Routes = (
  <Switch>
    <PrivateRoute
      path="/dashboard/claimReports"
      component={ClaimReportOverview}
      exact
    />

    <PrivateRoute path="/dashboard/tickets" exact component={Tickets} />
    <PrivateRoute path="/overview/reports/:reportId" component={ClaimReport} />
    <PrivateRoute path="/dashboard/termination" component={Termination} />

    <PrivateRoute
      path="/dashboard/report/initial"
      exact
      component={() => <CreateClaimReportUser isEntryClaimReport />}
    />
    <PrivateRoute
      path="/dashboard/report/serviceProvider"
      exact
      component={() => <CreateClaimReportServiceProvider isEntryClaimReport />}
    />
    <PrivateRoute path="/dashboard/report" component={CreateClaimReportUser} />

    <PrivateRoute
      path="/dashboard/balance/:tenantId"
      component={BalanceContainer}
    />
    <PrivateRoute path="/dashboard/balance/" component={Balance} />
    <PrivateRoute path="/dashboard/user/profile" component={TenantProfile} />
    <PrivateRoute path="/dashboard/documents" component={Documents} />
    <PrivateRoute path="/dashboard/rooms" component={Rooms} />
    <PrivateRoute path="/dashboard/tenants" component={Tenants} />
    <PrivateRoute path="/dashboard/overview/debts" component={DebtOverview} />
    <PrivateRoute path="/dashboard/data" component={BaseData} exact />
    <PrivateRoute path="/dashboard/data/houses" component={Houses} />
    <PrivateRoute path="/dashboard/data/house/add" component={AddHouse} />
    <PrivateRoute
      path="/dashboard/data/house/:houseId/edit"
      component={EditHouse}
    />
    <PrivateRoute path="/dashboard/data/house/:houseId" component={House} />
    <PrivateRoute path="/dashboard/data/flats" component={Flats} />
    <PrivateRoute path="/dashboard/data/flat/add" component={AddFlat} />
    <PrivateRoute
      path="/dashboard/data/flat/:flatId/edit"
      component={EditFlat}
    />
    <PrivateRoute path="/dashboard/data/flat/:flatId" component={Flat} />
    <PrivateRoute path="/dashboard/data/rooms" component={RoomsOverview} />
    <PrivateRoute path="/dashboard/data/room/add" component={AddRoom} />
    <PrivateRoute
      path="/dashboard/data/room/:roomId/edit"
      component={EditRoom}
    />
    <PrivateRoute path="/dashboard/data/room/:roomId" component={Room} />
    <PrivateRoute
      path="/dashboard/data/serviceProviders"
      component={ServiceProviders}
    />
    <PrivateRoute
      path="/dashboard/data/serviceProvider/add"
      component={AddServiceProviders}
    />
    <PrivateRoute
      path="/dashboard/data/serviceProvider/:serviceProviderId/edit"
      component={EditServiceProviders}
    />
    <PrivateRoute
      path="/dashboard/data/serviceProvider/:serviceProviderId"
      component={ServiceProvider}
    />
    <PrivateRoute path="/dashboard/data/houseOwners" component={HouseOwners} />
    <PrivateRoute
      path="/dashboard/data/houseOwner/add"
      component={AddHouseOwner}
    />
    <PrivateRoute
      path="/dashboard/data/houseOwner/:houseOwnerId/edit"
      component={EditHouseOwner}
    />
    <PrivateRoute
      path="/dashboard/data/houseOwner/:houseOwnerId"
      component={HouseOwner}
    />
    <PrivateRoute path="/dashboard/data/houseGivers" component={HouseGivers} />
    <PrivateRoute
      path="/dashboard/data/houseGiver/add"
      component={AddHouseGiver}
    />
    <PrivateRoute
      path="/dashboard/data/houseGiver/:houseGiverId/edit"
      component={EditHouseGiver}
    />
    <PrivateRoute
      path="/dashboard/data/houseGiver/:houseGiverId"
      component={HouseGiver}
    />
    <PrivateRoute
      path="/dashboard/landlord/InvoiceDamage"
      component={InvoiceDamage}
    />
    <PrivateRoute
      path="/dashboard/landlord/createNewTenant"
      component={CreateNewTenant}
    />
    <PrivateRoute path="/dashboard/tickets/add" component={CreateTicket} />
    <PrivateRoute path="/dashboard/history" component={Bookings} />
    <PrivateRoute path="/dashboard/statistic" component={Statistics} />
    <PrivateRoute path="/dashboard/faq" component={Faq} />
    <PrivateRoute
      path="/dashboard/settings/landlord"
      component={LandlordSettings}
    />
    <PrivateRoute
      path="/dashboard/settings/tenant"
      component={TenantSettings}
    />
    <PrivateRoute path="/dashboard/earnmoney" component={EarnMoney} />
    <PrivateRoute path="/dashboard/creditNotes" component={CreditNotes} />
    <PrivateRoute
      path="/dashboard/serviceProvider/profile"
      component={serviceProviderProfile}
    />
  </Switch>
);

const Dashboard = ({ t }) => {
  const bootstrapLargeSize = 992;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < bootstrapLargeSize
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < bootstrapLargeSize);
    });
  }, []);

  return (
    <Container fluid className="dashboardContainer">
      {!isMobile ? (
        <Row className="d-lg-flex d-none" style={{ height: '100%' }}>
          <Col
            style={{ backgroundColor: '#F7F7F7' }}
            className="dashboardContainer col-2"
          >
            <h4 className="mt-3" style={{ fontWeight: '500' }}>
              {t('dashboard')}
            </h4>
            <hr />
            <Menu />
          </Col>
          <Col className="col-10">{Routes}</Col>
        </Row>
      ) : (
        <div className="d-lg-none d-flex flex-column">
          <Menu />
          <div>{Routes}</div>
        </div>
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
  canDoEntryClaimReport: PropTypes.bool.isRequired,
};

export default Dashboard;
