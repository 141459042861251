import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const OverviewTable = ({
  rows,
  columns,
  rowEvents,
  keyField,
  searchable,
  actionButton
}) => {
  const { SearchBar } = Search;
  return (
    <ToolkitProvider
      keyField={keyField}
      data={rows}
      columns={columns}
      search={searchable}
    >
      {props => (
        <div>
          <SearchBar
            {...props.searchProps}
            srText={null}
            placeholder={i18n.t('base:search')}
          />
          {actionButton}
          <hr />
          <div className="noselect" style={{ cursor: 'pointer' }}>
            <BootstrapTable
              {...props.baseProps}
              rowEvents={rowEvents}
              hover
              wrapperClasses="table-responsive"
            />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

OverviewTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowEvents: PropTypes.object.isRequired,
  actionButton: PropTypes.node.isRequired,
  keyField: PropTypes.string.isRequired,
  searchable: PropTypes.bool.isRequired,
  baseProps: PropTypes.bool.isRequired,
  searchProps: PropTypes.bool.isRequired
};

export default OverviewTable;
