import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  FormControl,
  FormLabel,
  FormGroup,
  Form
} from 'react-bootstrap';
import { FileUpload, LoadingButton } from 'components';

const CreateClaimReportUser = ({
  firstTierCategories,
  secondTierCategories,
  thirdTierCategories,
  selectedFirstTierCategory,
  selectedSecondTierCategory,
  selectedThirdTierCategory,
  handleFirstTierCategoryChange,
  handleSecondTierCategoryChange,
  handleThirdTierCategoryChange,
  description,
  handleDescriptionChanged,
  handleSelectFiles,
  handleSubmitform,
  isEntryClaimReport,
  subject,
  handleSubjectChanged,
  t,
  isLoading,
  createClaimReport
}) => (
  <Container className="mt-5">
    <Row>
      <Col>
        <Form onSubmit={handleSubmitform}>
          <div className="mb-3">
            <b>{t('createClaimReport')}</b>
          </div>

          {!isEntryClaimReport && (
            <Categories
              {...{
                selectedFirstTierCategory,
                handleFirstTierCategoryChange,
                firstTierCategories,
                secondTierCategories,
                selectedSecondTierCategory,
                handleSecondTierCategoryChange,
                selectedThirdTierCategory,
                handleThirdTierCategoryChange,
                thirdTierCategories,
                t
              }}
            />
          )}

          {isEntryClaimReport && (
            <FormGroup className="mb-3">
              <FormLabel>{t('subject')}</FormLabel>
              <FormControl value={subject} onChange={handleSubjectChanged} />
            </FormGroup>
          )}

          <FormGroup className="mb-3">
            <FormLabel>{t('description')}</FormLabel>
            <FormControl
              as="textarea"
              value={description}
              onChange={handleDescriptionChanged}
              rows="6"
              maxLength="200"
            />
          </FormGroup>

          <FileUpload handleSelectFiles={handleSelectFiles} required={false} />
          <LoadingButton
            isLoading={isLoading}
            type="submit"
            onClick={createClaimReport}
            className="mb-3"
          >
            {t('submit')}
          </LoadingButton>
        </Form>
      </Col>
    </Row>
  </Container>
);

const Categories = ({
  t,
  selectedFirstTierCategory,
  handleFirstTierCategoryChange,
  firstTierCategories,
  secondTierCategories,
  selectedSecondTierCategory,
  handleSecondTierCategoryChange,
  selectedThirdTierCategory,
  handleThirdTierCategoryChange,
  thirdTierCategories
}) => (
  <>
    <FormGroup className="mb-3">
      <FormLabel>{t('category1')}</FormLabel>
      <FormControl
        as="select"
        value={selectedFirstTierCategory.value}
        onChange={handleFirstTierCategoryChange}
      >
        {firstTierCategories.map(x => (
          <option key={x.value}>{x.value}</option>
        ))}
      </FormControl>
    </FormGroup>

    {selectedFirstTierCategory.subtypes && (
      <FormGroup className="mb-3">
        <FormLabel>{t('category2')}</FormLabel>
        <FormControl
          as="select"
          value={selectedSecondTierCategory.value}
          onChange={handleSecondTierCategoryChange}
        >
          {secondTierCategories.map(x => (
            <option key={x.value}>{x.value}</option>
          ))}
        </FormControl>
      </FormGroup>
    )}

    {selectedSecondTierCategory.subtypes && (
      <FormGroup className="mb-3">
        <FormLabel>{t('category3')}</FormLabel>
        <FormControl
          as="select"
          value={selectedThirdTierCategory.value}
          onChange={handleThirdTierCategoryChange}
        >
          {thirdTierCategories.map(x => (
            <option key={x.value}>{x.value}</option>
          ))}
        </FormControl>
      </FormGroup>
    )}
  </>
);

Categories.propTypes = {
  firstTierCategories: PropTypes.array,
  secondTierCategories: PropTypes.array,
  thirdTierCategories: PropTypes.array,
  selectedFirstTierCategory: PropTypes.object,
  selectedSecondTierCategory: PropTypes.object,
  selectedThirdTierCategory: PropTypes.object,
  handleFirstTierCategoryChange: PropTypes.func.isRequired,
  handleSecondTierCategoryChange: PropTypes.func.isRequired,
  handleThirdTierCategoryChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

CreateClaimReportUser.propTypes = {
  firstTierCategories: PropTypes.array,
  secondTierCategories: PropTypes.array,
  thirdTierCategories: PropTypes.array,
  selectedFirstTierCategory: PropTypes.object,
  selectedSecondTierCategory: PropTypes.object,
  selectedThirdTierCategory: PropTypes.object,
  handleFirstTierCategoryChange: PropTypes.func.isRequired,
  handleSecondTierCategoryChange: PropTypes.func.isRequired,
  handleThirdTierCategoryChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  handleDescriptionChanged: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  handleSubmitform: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isEntryClaimReport: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  handleSubjectChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default CreateClaimReportUser;
