import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Form,
  Button,
  Container
} from 'react-bootstrap';
import { TenantsSelect } from 'containers';
import { FileUpload, LoadingButton } from 'components';

const InvoiceDamage = ({
  description,
  setDescription,
  handleSubmitform,
  t,
  damageAmount,
  setDamageAmount,
  claim,
  setClaim,
  tenantId,
  setTenantId,
  handleSelectFiles,
  isLoading
}) => (
  <Container>
    <Form onSubmit={handleSubmitform} className="my-5">
      <h2>Schaden berechnen</h2>

      <FormGroup className="mb-3">
        <FormLabel>Mieter</FormLabel>
        <TenantsSelect
          value={tenantId}
          onChange={tenantId => setTenantId(tenantId)}
          selector={state => {
            return state.tenants;
          }}
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel>Betrag</FormLabel>
        <FormControl
          value={damageAmount}
          onChange={e => setDamageAmount(e.target.value)}
          type="number"
          min="0"
          step="1"
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel>{t('subject')}</FormLabel>
        <FormControl value={claim} onChange={e => setClaim(e.target.value)} />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel>{t('description')}</FormLabel>
        <FormControl
          as="textarea"
          value={description}
          onChange={e => setDescription(e.target.value)}
          rows="6"
        />
      </FormGroup>
      <FileUpload handleSelectFiles={handleSelectFiles} required={false} />
      <LoadingButton className="mb-3" isLoading={isLoading} type="submit">
        {t('submit')}
      </LoadingButton>
    </Form>
  </Container>
);

InvoiceDamage.propTypes = {
  description: PropTypes.string,
  setDescription: PropTypes.func.isRequired,
  handleSubmitform: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  damageAmount: PropTypes.string,
  setDamageAmount: PropTypes.func.isRequired,
  claim: PropTypes.string,
  setClaim: PropTypes.func.isRequired,
  tenantId: PropTypes.number.isRequired,
  setTenantId: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default InvoiceDamage;
