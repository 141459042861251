export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const forgotPassword = email => ({
  type: FORGOT_PASSWORD,
  email
});
export const forgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload
});
export const forgotPasswordFailure = errors => ({
  type: FORGOT_PASSWORD_FAILURE,
  errors
});
