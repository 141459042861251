import axios from 'axios';
import { toast } from 'react-toastify';
import Config from '../../config';
import {
  getAccessToken,
  getLocalization,
  removeAccessToken
} from '../localStorage.adapter';

const API = axios.create({
  baseURL: Config.ApiUrl,
  responseType: 'json'
});

API.interceptors.request.use(request => {
  request.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Accept-Language': getLocalization()
  };
  return request;
});

API.interceptors.response.use(
  response => response,
  error => {
    const status = error.response.status;
    const errors = error.response.data;

    if (errors && errors.errors) {
      errors.errors.forEach(error => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
    }

    if (status === 500) {
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    if (status === 401) {
      removeAccessToken();
      window.location = '/login';
    } else {
      return errors || error.response.data;
    }
  }
);

export default API;
