import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { addressApi } from 'utils/api';
import {
  ADD_ADDRESS,
  addAddressSuccess,
  addAddressFailure
} from './addAddress.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';

function* addAddress({ postalCode, street, houseNumber, location }) {
  try {
    const { data, errors } = yield call(addressApi.addAddress, {
      postalCode,
      street,
      houseNumber,
      location
    });

    if (!errors) {
      yield put(addAddressSuccess(data));
    } else {
      yield put(addAddressFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addAddressFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_ADDRESS, addAddress);
}
