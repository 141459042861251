export const ADD_PAYMENTS = 'ADD_PAYMENTS';
export const ADD_PAYMENTS_SUCCESS = 'ADD_PAYMENTS_SUCCESS';
export const ADD_PAYMENTS_FAILURE = 'ADD_PAYMENTS_FAILURE';
export const ADD_PAYMENTS_CLEAR = 'ADD_PAYMENTS_CLEAR';

export const addPayments = (amount, userId, reference) => ({
  type: ADD_PAYMENTS,
  userId,
  reference,
  amount
});
export const addPaymentsSuccess = payload => ({
  type: ADD_PAYMENTS_SUCCESS,
  payload
});
export const addPaymentsFailure = errors => ({
  type: ADD_PAYMENTS_FAILURE,
  errors
});
export const addPaymentsClear = () => ({
  type: ADD_PAYMENTS_CLEAR
});
