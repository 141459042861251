import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

const NationalityFormControl = ({ t, value, countries, onChange, ...rest }) => (
  <FormControl as="select" value={value} onChange={onChange} {...rest}>
    <option>{t('select')}</option>
    {countries.map(country => (
      <option key={country}>{country}</option>
    ))}
  </FormControl>
);

NationalityFormControl.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default NationalityFormControl;
