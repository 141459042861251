import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

const Content = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col
          className="col-10"
          style={{
            margin: '50px auto',
            padding: '1em',
            border: '.2rem solid #ececec',
            borderWidth: '.2rem',
            borderRadius: '8px'
          }}
        >
          {children}
        </Col>
      </Row>
    </Container>
  );
};

Content.propTypes = {
  children: PropTypes.node
};

export default Content;
