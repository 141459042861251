import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Room from './Room';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRoom } from 'store/room/getRoom/getRoom.actions';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { deleteRoom } from 'store/room/deleteRoom/deleteRoom.actions';

const RoomContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();
  const roomId = parseInt(match.params.roomId);

  const room = useSelector(state => state.room);

  useEffect(() => {
    dispatch(getRoom(roomId));
  }, [roomId]);

  const handleNewFlatClick = () => history.push('/dashboard/data/flat/add');
  const handleEditRoomClick = () =>
    history.push(`/dashboard/data/room/${roomId}/edit`);

  const handleDeleteRoomClick = () => {
    dispatch(deleteRoom(room.id));
    history.push(`/dashboard/data/rooms`);
  };

  return room !== undefined ? (
    <Loader prop={room}>
      <Room
        {...{
          roomId,
          room,
          handleNewFlatClick,
          handleDeleteRoomClick,
          handleEditRoomClick,
          t
        }}
      />
    </Loader>
  ) : (
    <div />
  );
};

RoomContainer.propTypes = { match: PropTypes.object.isRequired };

export default RoomContainer;
