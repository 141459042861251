export const REGISTER_SERVICE_PROVIDER = 'REGISTER_SERVICE_PROVIDER';
export const REGISTER_SERVICE_PROVIDER_SUCCESS =
  'REGISTER_SERVICE_PROVIDER_SUCCESS';
export const REGISTER_SERVICE_PROVIDER_FAILURE =
  'REGISTER_SERVICE_PROVIDER_FAILURE';

export const registerServiceProvider = (email, password, token) => ({
  type: REGISTER_SERVICE_PROVIDER,
  email,
  password,
  token
});
export const registerServiceProviderSuccess = payload => ({
  type: REGISTER_SERVICE_PROVIDER_SUCCESS,
  payload
});
export const registerServiceProviderFailure = errors => ({
  type: REGISTER_SERVICE_PROVIDER_FAILURE,
  errors
});
