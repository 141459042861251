import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddOwnerModal from 'components/addOwnerModal';
import { HouseOwnerSelect } from 'containers';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const HouseOwnerFormGroup = ({ value, onChange, allowAddValue, ...rest }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <FormGroup className="mb-3" controlId="formHouseOwner">
        <FormLabel>{i18n.t('forms:houseOwner')}</FormLabel>
        <div style={{ display: 'flex' }}>
          <HouseOwnerSelect onChange={onChange} value={value} {...rest} />
          {allowAddValue && (
            <Button
              className="mb-3"
              style={{ marginLeft: 10 }}
              onClick={() => setIsModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          )}
        </div>
      </FormGroup>
      <AddOwnerModal
        isOpen={isModalOpen}
        onHide={() => setIsModalOpen(false)}
      />
    </>
  );
};

HouseOwnerFormGroup.defaultProps = {
  value: '',
  allowAddValue: true
};

HouseOwnerFormGroup.propTypes = {
  value: PropTypes.string,
  allowAddValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default HouseOwnerFormGroup;
