import { DateFormControl } from 'components';
import IbanFormControl from 'components/IbanFormControl';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Alert,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel
} from 'react-bootstrap';
import { toInputValue } from 'utils/date.formatter';

const WithNextTenant = ({
  t,
  noticeDate,
  handleOnChange,
  nextTenantEmail,
  handleNextTenantEmailChanged,
  iban,
  handleIbanChanged,
  isStudent,
  handleIsStudentChanged,
  ageBetween,
  handleAgeBetweenChanged,
  credentialSettings
}) => {
  return (
    <>
      <FormGroup className="mb-3" style={{ width: '100%' }}>
        <FormLabel>{t('terminationDate')}</FormLabel>
        <DateFormControl
          min={moment()}
          value={toInputValue(noticeDate)}
          onChange={handleOnChange}
        />
      </FormGroup>
      <FormGroup className="mb-3" style={{ width: '100%' }}>
        <FormLabel>{t('nextTenant')}</FormLabel>
        <FormControl
          type="email"
          value={nextTenantEmail}
          onChange={handleNextTenantEmailChanged}
          required
        />
      </FormGroup>
      <FormGroup className="mb-3" style={{ width: '100%' }}>
        <FormLabel>{t('iban')}</FormLabel>
        <IbanFormControl value={iban} onChange={handleIbanChanged} required />
      </FormGroup>
      <div className="my-2">{t('nextTenantRequirement')}</div>
      <Alert variant="info">
        <FormCheck
          inline
          onChange={handleIsStudentChanged}
          checked={isStudent}
          label={t('isStudent')}
          name="isStudent"
          type="checkbox"
          required
        />
        <br />
        <FormCheck
          inline
          onChange={handleAgeBetweenChanged}
          checked={ageBetween}
          label={t('ageBetween')}
          name="ageBetween"
          type="checkbox"
          required
        />
      </Alert>
      <div className="my-2">
        <b>
          {t('tenantReward').replace(
            '{creditAdvertiser}',
            credentialSettings.creditAdvertiser
          )}
        </b>
      </div>
      <div className="my-2">{t('nextTenantBefore')}</div>
    </>
  );
};

WithNextTenant.propTypes = {
  t: PropTypes.func.isRequired,
  noticeDate: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  nextTenantEmail: PropTypes.array.isRequired,
  handleNextTenantEmailChanged: PropTypes.func.isRequired,
  iban: PropTypes.string.isRequired,
  handleIbanChanged: PropTypes.func.isRequired,
  isStudent: PropTypes.bool.isRequired,
  handleIsStudentChanged: PropTypes.func.isRequired,
  ageBetween: PropTypes.bool.isRequired,
  handleAgeBetweenChanged: PropTypes.func.isRequired,
  credentialSettings: PropTypes.object.isRequired
};

export default WithNextTenant;
