import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useInputState from 'hooks/useInputState';
import CreateBaseData from './CreateBaseData';
import { updateUserInfo } from 'store/account/updateUserInfo/updateUserInfo.actions';
import { handleLoadUser } from 'store/account/getUser/user.actions';
import moment from 'moment';

const CreateBaseDataContainer = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { success, loading, errors: apiErrors } = useSelector(
    state => state.request.updateUserInfo
  );

  const { t } = useTranslation('setBaseData');
  const [mobileNumber, setMobileNumber] = useInputState('');
  const [nationality, setNationality] = useInputState(t('select'));
  const momentBefore18Years = moment()
    .startOf('day')
    .add(-18, 'years');
  const [birthDate, setBirthDate] = useState(momentBefore18Years);
  const [gender, setGender] = useInputState();
  const [job, setJob] = useInputState();
  const [firstName, setFirstName] = useInputState('');
  const [lastName, setLastName] = useInputState('');
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    dispatch(handleLoadUser(localStorage.getItem('access_token')));
  }, []);

  useEffect(() => {
    if (apiErrors.length > 0) setErrors(apiErrors);
  }, [apiErrors, success, history]);

  const handleSubmit = e => {
    e.preventDefault();
    setErrors([]);

    if (mobileNumber.length === 0)
      setErrors(prev => [...prev, t('noPhoneNumber')]);

    if (nationality === t('select'))
      setErrors(prev => [...prev, t('noNationality')]);

    if (gender == null) setErrors(prev => [...prev, t('noGender')]);

    if (job == null) setErrors(prev => [...prev, t('noJob')]);

    if (errors.length > 0) return;

    dispatch(
      updateUserInfo(
        mobileNumber,
        nationality,
        birthDate.format('YYYY-MM-DD'),
        gender,
        job,
        firstName,
        lastName
      )
    );
  };

  return (
    <CreateBaseData
      {...{
        t,
        firstName,
        lastName,
        mobileNumber,
        nationality,
        birthDate,
        gender,
        job,
        errors,
        loading,
        success,
        handleMobileNumberChange: setMobileNumber,
        handleNationalityChange: setNationality,
        handleBirthDateChange: setBirthDate,
        handleGenderChange: setGender,
        handleJobChange: setJob,
        handleFirstNameChange: setFirstName,
        handleLastNameChange: setLastName,
        handleSubmit
      }}
    />
  );
};

CreateBaseDataContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default CreateBaseDataContainer;
