import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TenantData } from 'components';
import { Button } from 'react-bootstrap';
import ChangePhoneNumberModal from './components/ChangePhoneNumberModal';
import ChangeEMailModal from './components/ChangeEMailModal';
import { getGenderByKey } from 'utils/genderService/gender.service';
import { getJobByKey } from 'utils/jobService/job.service';
import { toDisplayDate } from 'utils/date.formatter';

const TenantProfile = ({ userInfo, t }) => (
  <Container className="mt-5">
    <TenantData
      {...{
        ...userInfo,
        gender: getGenderByKey(userInfo.gender).value,
        job: getJobByKey(userInfo.job).value
      }}
    />
    <div className="mt-5">
      <div className="mb-2">
        <strong>{t('contract')}</strong>
      </div>
      {userInfo.booking !== null ? (
        <Row>
          <Col className="col-6 col-xl-3 col-lg-3">
            <div className="mb-1 ellipsis">{`${t('startDateContract')}`}</div>
            <div className="mb-1 ellipsis">{`${t('durationInMonths')}`}</div>
            <div className="mb-1 ellipsis">{t('customerNumber')}</div>
            <div className="mb-1 ellipsis">{t('rentalContract')}</div>
            <div className="mb-1 ellipsis">
              {t('proofOfResidenceCertificate')}
            </div>
          </Col>
          <Col className="col-6 col-xl-9 col-lg-9 mb-3">
            <div className="mb-1 ellipsis">
              {toDisplayDate(userInfo.booking.startDateContract)}
            </div>
            <div className="mb-1 ellipsis">
              {`${userInfo.booking.durationInMonths} ${t('months')}`}{' '}
            </div>
            <div className="mb-1 ellipsis">{userInfo.customerId}</div>
            <div className="mb-1 ellipsis">
              <a
                onClick={() =>
                  (window.location.href = userInfo.documents.rentalContract)
                }
                href={userInfo.documents.rentalContract}
              >
                {t('open')}
              </a>
            </div>
            <div className="mb-5 ellipsis">
              <a
                href="#"
                onClick={() =>
                  (window.location.href =
                    userInfo.documents.proofOfResidenceCertificate)
                }
              >
                {t('open')}
              </a>
            </div>
          </Col>
        </Row>
      ) : (
        <div>{t('noRoom')}</div>
      )}
    </div>
  </Container>
);

TenantProfile.propTypes = {
  userInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default TenantProfile;
