export const GET_ROOM = 'GET_ROOM';
export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS';
export const GET_ROOM_FAILURE = 'GET_ROOM_FAILURE';

export const getRoom = roomId => ({
  type: GET_ROOM,
  roomId
});
export const getRoomSuccess = payload => ({
  type: GET_ROOM_SUCCESS,
  payload
});
export const getRoomFailure = errors => ({
  type: GET_ROOM_FAILURE,
  errors
});
