import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  SYNCHRONIZE_BANKING,
  SYNCHRONIZE_BANKING_SUCCESS,
  SYNCHRONIZE_BANKING_FAILURE
} from './synchronizeBanking.actions';

export default createReducer(
  {
    [SYNCHRONIZE_BANKING]: defaultRequest,
    [SYNCHRONIZE_BANKING_SUCCESS]: defaultSuccess,
    [SYNCHRONIZE_BANKING_FAILURE]: defaultError
  },
  defaultInitialState
);
