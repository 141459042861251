import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  GET_ADVERTISED_BONUS_FOR_ROOM,
  GET_ADVERTISED_BONUS_FOR_ROOM_SUCCESS,
  GET_ADVERTISED_BONUS_FOR_ROOM_FAILURE,
  GET_ADVERTISED_BONUS_FOR_ROOM_RESET
} from './getAdvertisedBonusForRoom.actions';

export default createReducer(
  {
    [GET_ADVERTISED_BONUS_FOR_ROOM]: defaultRequest,
    [GET_ADVERTISED_BONUS_FOR_ROOM_SUCCESS]: defaultSuccess,
    [GET_ADVERTISED_BONUS_FOR_ROOM_FAILURE]: defaultError,
    [GET_ADVERTISED_BONUS_FOR_ROOM_RESET]: defaultReset
  },
  defaultInitialState
);
