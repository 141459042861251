import React, { useState } from 'react';
import { Container, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createLog } from 'store/logging/createLog/createLog.actions';
import { savePayment } from 'store/payment/savePayment/savePayment.actions';
import Payment from './Payment';
import TestSystemDisclaimer from './TestSystemDisclaimer';

const PaymentContainer = () => {
  const { t } = useTranslation('payment');
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const handlePaymentSuccess = (orderId) => {
    dispatch(savePayment(orderId));
  };

  const handlePaymentError = (e) => {
    dispatch(createLog(4, 'Payment failed with error {e}', [String(e)]));
  };

  const [isLoading, setIsLoading] = useState(true);

  return (
    <Container>
      <div className="col-12 col-md-6">
        <FormGroup className="mb-3">
          <FormLabel>{t('amount')}</FormLabel>
          <FormControl
            type="number"
            placeholder="€"
            value={amount}
            min="0"
            onChange={(e) => setAmount(Math.abs(e.target.value))}
          />
        </FormGroup>
      </div>
      <Payment
        handlePaymentSuccess={handlePaymentSuccess}
        handlePaymentError={handlePaymentError}
        xs={12}
        md={12}
        lg={12}
        amount={(Number(amount) + Number(amount) * 0.0179 + 0.3).toFixed(2)}
        header={t('header')}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      >
        <p className="mb-5">
          {t('internalPayment1')} <b>{t('internalPayment2')}</b>
          {t('internalPayment3')}
          <b>{(Number(amount) * 0.0179 + 0.3).toFixed(2)}€</b>
          {t('internalPayment4')}
          <br />
          <br />
          <TestSystemDisclaimer t={t} />
        </p>
      </Payment>
    </Container>
  );
};

PaymentContainer.propTypes = {};

export default PaymentContainer;
