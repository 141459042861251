import useRooms from 'hooks/store/useRooms';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../shared/select';

const RoomSelectContainer = ({
  selectProp,
  onChange,
  value,
  selector,
  output,
  ...rest
}) => {
  const rooms = useRooms(selector);

  return (
    <Select
      className="mb-3"
      value={value}
      onChange={onChange}
      options={rooms}
      output={output}
      selectProp={selectProp}
      {...rest}
    />
  );
};

RoomSelectContainer.defaultProps = {
  onChange: () => {},
  selector: state => state.rooms,
  output: room =>
    `${room.symbol}: ${room.address.street} ${room.address.houseNumber}, ${room.squareMeters} qm`
};

RoomSelectContainer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  selector: PropTypes.func,
  output: PropTypes.func
};

export default RoomSelectContainer;
