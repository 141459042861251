import PropTypes from 'prop-types';
import React from 'react';
import FormCheck from 'react-bootstrap/FormCheck';

const CheckBoxes = ({
  t,
  firstCheckBox,
  handleFirstCheckBoxChecked,
  secondCheckBox,
  handleSecondCheckBoxChecked,
}) => (
  <>
    <div style={{ margin: '10px 0' }}>
      <FormCheck
        checked={firstCheckBox}
        onChange={handleFirstCheckBoxChecked}
        label={
          <div>
            {t('checkBoxText1')}
            <a
              href={`${process.env.PUBLIC_URL + '/Mietvertrag.pdf'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('checkBoxText2')}
            </a>
            {t('checkBoxText3')}
            <a
              href={`${process.env.PUBLIC_URL + '/AGB.pdf'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('checkBoxText4')}
            </a>
            {t('checkBoxText5')}
            <a
              href={`${process.env.PUBLIC_URL + '/Nutzungsbedingungen.pdf'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('checkBoxText8')}
            </a>
            {t('checkBoxText9')}
            <br />
            {t('checkBoxText10')}
            <a
              href={`${process.env.PUBLIC_URL + '/Widerrufsbelehrung.pdf'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('checkBoxText11')}
            </a>
            {t('checkBoxText12')}
          </div>
        }
      />
    </div>
    <div style={{ margin: '10px 0' }}>
      <FormCheck
        checked={secondCheckBox}
        onChange={handleSecondCheckBoxChecked}
        label={
          <div>
            {t('checkBoxText13')} <br />
            {t('checkBoxText14')}
          </div>
        }
      />
    </div>
  </>
);

CheckBoxes.propTypes = {
  t: PropTypes.func.isRequired,
  firstCheckBox: PropTypes.bool.isRequired,
  handleFirstCheckBoxChecked: PropTypes.func.isRequired,
  secondCheckBox: PropTypes.bool.isRequired,
  handleSecondCheckBoxChecked: PropTypes.func.isRequired,
};

export default CheckBoxes;
