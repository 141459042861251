import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  GET_OFFERED_CREDIT_NOTES,
  GET_OFFERED_CREDIT_NOTES_SUCCESS,
  GET_OFFERED_CREDIT_NOTES_FAILURE,
  GET_OFFERED_CREDIT_NOTES_RESET
} from './getOfferedCreditNotes.actions';

export default createReducer(
  {
    [GET_OFFERED_CREDIT_NOTES]: defaultRequest,
    [GET_OFFERED_CREDIT_NOTES_SUCCESS]: defaultSuccess,
    [GET_OFFERED_CREDIT_NOTES_FAILURE]: defaultError,
    [GET_OFFERED_CREDIT_NOTES_RESET]: defaultReset
  },
  defaultInitialState
);
