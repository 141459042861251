import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, FormControl, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as userRoles from 'utils/constants/userRole.constants';
import TenantsModal from '../tenants/TenantsModal';
import SelfInflictedModal from './SelfInflictedModal';
import TicketDetailModal from './TicketDetailModal';

const Tickets = ({
  tickets,
  columns,
  rowEvents,
  filter,
  handleFilterChange,
  t,
  handleNewTicketClick,
  userRole,
  isModalOpen,
  selectedTicket,
  setIsModalOpen,
  handleCloseTicket,
  isModal2Open,
  setIsModal2Open,
  isTenantModalOpen,
  selectedTenant,
  setIsTenantModalOpen,
  defaultSorted,
  handleAssignTicket,
}) => {
  const { SearchBar } = Search;
  return (
    <Container className="mt-5">
      <ToolkitProvider
        keyField="id"
        data={tickets}
        columns={columns}
        search={{ searchFormatted: true }}
      >
        {(props) => (
          <div>
            {selectedTenant && (
              <TenantsModal
                isOpen={isTenantModalOpen}
                tenant={selectedTenant}
                onHide={() => setIsTenantModalOpen(false)}
              />
            )}

            {selectedTicket && (
              <>
                <SelfInflictedModal
                  isOpen={isModal2Open}
                  ticketId={selectedTicket.id}
                  handleDoneClick={(id, amount) => {
                    setIsModal2Open(false);
                    handleCloseTicket(id, amount);
                  }}
                  onHide={() => setIsModal2Open(false)}
                  t={t}
                />

                <TicketDetailModal
                  isOpen={isModalOpen}
                  ticket={selectedTicket}
                  onHide={() => setIsModalOpen(false)}
                  handleAssignTicket={handleAssignTicket}
                  handleDoneClick={() => {
                    setIsModalOpen(false);
                    if (selectedTicket.claimReport) {
                      setIsModal2Open(true);
                    }
                    if (!selectedTicket.claimReport) {
                      handleCloseTicket(selectedTicket.id, null);
                    }
                  }}
                  t={t}
                />
              </>
            )}
            <Row>
              <Col
                className={
                  userRole === userRoles.USER
                    ? 'col-md-8 col-12'
                    : 'col-md-7 col-12'
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                <b>{t('tickets')}</b>
              </Col>
              <Col className="col-1">
                <Button
                  className="float-md-right"
                  onClick={handleNewTicketClick}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <FormControl
                  as="select"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option>{t('filter.all')}</option>
                  <option>{t('filter.open')}</option>
                  <option>{t('filter.closed')}</option>
                </FormControl>
              </Col>
              <Col className="col-md-2 col-12 mt-2 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  placeholder={i18n.t('base:search')}
                  srText={null}
                  style={{ float: 'right' }}
                />
              </Col>
            </Row>
            <hr />
            <div className="noselect" style={{ cursor: 'pointer' }}>
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                defaultSorted={defaultSorted}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
};

Tickets.propTypes = {
  tickets: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowEvents: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleNewTicketClick: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  selectedTicket: PropTypes.object,
  setIsModalOpen: PropTypes.func.isRequired,
  handleCloseTicket: PropTypes.func.isRequired,
  isModal2Open: PropTypes.bool.isRequired,
  setIsModal2Open: PropTypes.func.isRequired,
  baseProps: PropTypes.object.isRequired,
  searchProps: PropTypes.object.isRequired,
  isTenantModalOpen: PropTypes.bool.isRequired,
  selectedTenant: PropTypes.object,
  setIsTenantModalOpen: PropTypes.isRequired,
  defaultSorted: PropTypes.array,
  handleAssignTicket: PropTypes.func.isRequired,
};

export default Tickets;
