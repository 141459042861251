import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bookingNextTenant } from 'store/booking/bookingNextTenant/bookingNextTenant.actions';
import { canBookRoom } from 'store/booking/canBookRoom/canBookRoom.actions';
import { createBooking } from 'store/booking/createBooking/createBooking.actions';
import { createLog } from 'store/logging/createLog/createLog.actions';
import Payment from './Payment';
import TestSystemDisclaimer from './TestSystemDisclaimer';

const BookingPaymentContainer = ({ location }) => {
  const dispatch = useDispatch();
  const { search } = location;
  const queryStrings = qs.parse(search, { ignoreQueryPrefix: true });
  const nextTenant = location.state ? location.state.nextTenant : null;
  const { t } = useTranslation('payment');
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [documentsGenerating, setDocumentsGenerating] = useState(false);

  const startofRental = queryStrings.startofrental;
  const rentalDuration = queryStrings.rentalduration;
  const deposit = queryStrings.deposit;

  const { success: createBookingSuccess } = useSelector(
    (state) => state.request.createBooking
  );

  const { loading: canBookRoomLoading, success: canBookRoomSuccess } =
    useSelector((state) => state.request.canBookRoom);

  const { success: bookingNextTenantSuccess } = useSelector(
    (state) => state.request.bookingNextTenant
  );

  const { roomId } = useParams();

  useEffect(() => {
    if (!nextTenant) {
      dispatch(canBookRoom(startofRental, rentalDuration, roomId));
    }
  }, []);

  useEffect(() => {
    if (createBookingSuccess) history.push('/user/profile');
    if (bookingNextTenantSuccess) history.push('/user/profile');
  }, [createBookingSuccess, bookingNextTenantSuccess]);

  const handlePaymentSuccess = (orderId) => {
    setDocumentsGenerating(true);
    if (nextTenant) {
      dispatch(
        bookingNextTenant(
          nextTenant.terminationDate,
          nextTenant.tenantId,
          roomId,
          orderId,
          nextTenant.moveInDate,
          rentalDuration,
          nextTenant.secret
        )
      );
      return;
    }
    dispatch(createBooking(startofRental, rentalDuration, roomId, orderId));
  };

  const handlePaymentError = (e) => {
    dispatch(createLog(4, 'Payment failed with error {e}', [String(e)]));
  };

  return (
    <>
      {nextTenant && (
        <Alert className="text-center" variant="info">
          {t('nextTenantBooking')}
        </Alert>
      )}
      {canBookRoomLoading && (
        <Alert variant="info">{t('bookingValidation')}</Alert>
      )}
      {canBookRoomSuccess && !documentsGenerating && (
        <Container className="my-5">
          <Payment
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentError={handlePaymentError}
            amount={deposit}
            xs={12}
            md={10}
            lg={6}
            header={t('header')}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          >
            <p className="mb-5">
              {t('caution')}
              <br />
              <br />
              <TestSystemDisclaimer t={t} />
            </p>
          </Payment>
        </Container>
      )}
      {nextTenant && !documentsGenerating && (
        <Container className="my-5">
          <Payment
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentError={handlePaymentError}
            amount={deposit}
            xs={12}
            md={10}
            lg={6}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Container>
      )}
      {documentsGenerating && (
        <Container className="my-5">
          <Col className="text-center justify-content-center mb-3">
            <h2 className="mb-3">{t('paymentSuccess')}</h2>
            {t('documentsGenerating')}
          </Col>
          <Row className="text-center justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Row>
        </Container>
      )}
    </>
  );
};

BookingPaymentContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BookingPaymentContainer;
