import React, {
  useState,
  isValidElement,
  cloneElement,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PaymentContainer from './Payment.container';
import { useSelector } from 'react-redux';

const PaymentModal = ({ children }) => {
  const { t } = useTranslation('payment');
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const { success: savePaymentSuccess } = useSelector(
    state => state.request.savePayment
  );

  useEffect(() => {
    if (savePaymentSuccess) handleHideModal();
  }, [savePaymentSuccess]);

  return (
    <>
      {isValidElement(children) &&
        cloneElement(children, { onClick: handleShowModal })}
      <Modal onHide={handleHideModal} show={showModal} scrollable>
        <Modal.Header closeButton>
          <ModalTitle> {t('header')} </ModalTitle>
        </Modal.Header>
        <ModalBody>
          <PaymentContainer></PaymentContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

PaymentModal.defaultProps = {
  size: 'md'
};

PaymentModal.propTypes = {
  children: PropTypes.object.isRequired
};

export default PaymentModal;
