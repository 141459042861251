export const GET_FLAT_HOUSE = 'GET_FLAT_HOUSE';
export const GET_FLAT_HOUSE_SUCCESS = 'GET_FLAT_HOUSE_SUCCESS';
export const GET_FLAT_HOUSE_FAILURE = 'GET_FLAT_HOUSE_FAILURE';

export const getFlatHouse = id => ({
  type: GET_FLAT_HOUSE,
  id
});
export const getFlatHouseSuccess = payload => ({
  type: GET_FLAT_HOUSE_SUCCESS,
  payload
});
export const getFlatHouseFailure = errors => ({
  type: GET_FLAT_HOUSE_FAILURE,
  errors
});
