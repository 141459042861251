import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  updateObject
} from 'utils/reducer.helper';

import {
  HANDLE_LOAD_USER,
  HANDLE_LOAD_USER_SUCCESS,
  HANDLE_LOAD_USER_FAILURE
} from './user.actions';

import { LOGOUT_SUCCESS } from 'store/account/logout/logout.actions';
import { UPDATE_USERINFO_SUCCESS } from 'store/account/updateUserInfo/updateUserInfo.actions';
import { UPDATE_PHONE_NUMBER_SUCCESS } from 'store/account/updatePhoneNumber/updatePhoneNumber.actions';
import { UPDATE_EMAIL_SUCCESS } from 'store/account/updateEmail/updateEmail.actions';
import { UPDATE_SERVICE_PROVIDER_INFO_SUCCESS } from '../updateServiceProviderInfo/updateServiceProviderInfo.actions';

export default createReducer(
  {
    [HANDLE_LOAD_USER]: defaultRequest,
    [HANDLE_LOAD_USER_SUCCESS]: defaultSuccess,
    [HANDLE_LOAD_USER_FAILURE]: defaultError,
    [UPDATE_USERINFO_SUCCESS]: mutateStateWithRole,
    [UPDATE_SERVICE_PROVIDER_INFO_SUCCESS]: mutateStateWithRole,
    [UPDATE_PHONE_NUMBER_SUCCESS]: (state, action) => {
      return updateObject(state, {
        ...state,
        data: {
          ...state.data,
          phoneNumber: action.payload.phoneNumber
        }
      });
    },
    [UPDATE_EMAIL_SUCCESS]: (state, action) => {
      return updateObject(state, {
        ...state,
        data: {
          ...state.data,
          email: action.payload.email
        }
      });
    },
    [LOGOUT_SUCCESS]: () => defaultInitialState
  },
  defaultInitialState
);

function mutateStateWithRole(state, action) {
  return Object.assign({}, state, {
    ...state,
    data: { ...action.payload, role: state.data.role }
  });
}
