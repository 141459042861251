import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addHouseGiver,
  addHouseGiverClear
} from 'store/houseGiver/addHouseGiver/addHouseGiver.actions';
import AddHouseGiverModal from './AddGiverModal';

const AddHouseGiverModalContainer = ({ isOpen, onHide }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState();

  const { success, errors } = useSelector(state => state.request.addHouseGiver);

  const handleFirstNameChange = e => setFirstName(e.target.value);
  const handleLastNameChange = e => setLastName(e.target.value);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      addHouseGiver(
        firstName,
        lastName,
        address.street,
        address.houseNumber,
        address.postalCode,
        address.location
      )
    );
  };

  useEffect(() => {
    if (success) {
      setFirstName('');
      setLastName('');
      setAddress();
      onHide();
      addHouseGiverClear();
    }
  }, [success]);

  return (
    <AddHouseGiverModal
      {...{
        isOpen,
        onHide,
        firstName,
        lastName,
        handleFirstNameChange,
        handleLastNameChange,
        handleSubmit,
        errors,
        address,
        handleAddressChange: setAddress,
        t
      }}
    />
  );
};

AddHouseGiverModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default AddHouseGiverModalContainer;
