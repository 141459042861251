import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_ADDRESSES,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAILURE
} from './getAddresses.actions';

export default createReducer(
  {
    [GET_ADDRESSES]: defaultRequest,
    [GET_ADDRESSES_SUCCESS]: defaultSuccess,
    [GET_ADDRESSES_FAILURE]: defaultError
  },
  defaultInitialState
);
