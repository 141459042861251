export const GET_HOUSE_ROOMS = 'GET_HOUSE_ROOMS';
export const GET_HOUSE_ROOMS_SUCCESS = 'GET_HOUSE_ROOMS_SUCCESS';
export const GET_HOUSE_ROOMS_FAILURE = 'GET_HOUSE_ROOMS_FAILURE';

export const getHouseRooms = id => ({
  type: GET_HOUSE_ROOMS,
  id
});
export const getHouseRoomsSuccess = payload => ({
  type: GET_HOUSE_ROOMS_SUCCESS,
  payload
});
export const getHouseRoomsFailure = errors => ({
  type: GET_HOUSE_ROOMS_FAILURE,
  errors
});
