import PropTypes from 'prop-types';
import React from 'react';
import { FormCheck, FormGroup } from 'react-bootstrap';
import { ServiceProviderTypes } from 'utils/constants/serviceProviderTypes';

const ServiceProviderTypesFormGroup = ({
  serviceProviderTypes,
  handleChange,
  t
}) => (
  <FormGroup className="mb-3">
    <FormCheck
      type="checkbox"
      label={t('caretaker')}
      checked={serviceProviderTypes.includes(ServiceProviderTypes.caretaker)}
      onChange={() => handleChange(ServiceProviderTypes.caretaker)}
    />
    <FormCheck
      type="checkbox"
      label={t('cleaning')}
      checked={serviceProviderTypes.includes(ServiceProviderTypes.cleaning)}
      onChange={() => handleChange(ServiceProviderTypes.cleaning)}
    />
    <FormCheck
      type="checkbox"
      label={t('lockSmith')}
      checked={serviceProviderTypes.includes(ServiceProviderTypes.lockSmith)}
      onChange={() => handleChange(ServiceProviderTypes.lockSmith)}
    />
    <FormCheck
      type="checkbox"
      label={t('sanitary')}
      checked={serviceProviderTypes.includes(ServiceProviderTypes.sanitary)}
      onChange={() => handleChange(ServiceProviderTypes.sanitary)}
    />
    <FormCheck
      type="checkbox"
      label={t('electrician')}
      checked={serviceProviderTypes.includes(ServiceProviderTypes.electrician)}
      onChange={() => handleChange(ServiceProviderTypes.electrician)}
    />
  </FormGroup>
);

ServiceProviderTypesFormGroup.propTypes = {
  serviceProviderTypes: PropTypes.array,
  serviceProviderTypesChange: PropTypes.func,
  t: PropTypes.func
};

export default ServiceProviderTypesFormGroup;
