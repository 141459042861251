import API from './API';

const prefix = 'ticket';

export const getTickets = () => API.get(`${prefix}/`);
export const createTicket = formData => API.post(`${prefix}/`, formData);
export const closeTicket = (id, amount) =>
  API.put(`${prefix}/${id}${amount ? '?damageAmount=' + amount : ''}`);
export const assignTicket = (id, serviceProviderId) =>
  API.put(
    `${prefix}/assign/${id}${
      serviceProviderId ? '?serviceProviderId=' + serviceProviderId : ''
    }`
  );

export default {
  getTickets,
  createTicket,
  closeTicket,
  assignTicket
};
