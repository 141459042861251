import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import TerminationForm from './components/TerminationForm';
import NextTenantBookedInfo from './components/NextTenantBookedInfo';
import NextTenantNotBookedYet from './components/NextTenantNotBookedYet';
import TerminatedInfo from './components/TerminatedInfo';

const Termination = ({
  t,
  firstPossibleNoticeDate,
  handleSubmit,
  alreadyTerminated,
  noticeDate,
  handleOnChange,
  termination,
  handleCancelTermination,
  handleNextTenantEmailChanged,
  handleIbanChanged,
  nextTenantEmail,
  iban,
  tab,
  isStudent,
  ageBetween,
  handleIsStudentChanged,
  handleAgeBetweenChanged,
  handleTabChanged,
  user,
  createTerminationLoading,
  credentialSettings,
  promotionCode,
  possibleTerminationDates,
  addNewTenant
}) => {
  return (
    <Container className="mt-5">
      <b style={{ fontSize: '20px' }}>{t('termination')}</b>

      {!termination.withNextTenant && !termination.notWithdrawable && (
        <Alert variant={'info'} className={'mt-3'}>
          {t('promotionText')
            .replace('{creditAdvertiser}', credentialSettings.creditAdvertiser)
            .replace('{promotionCode}', promotionCode)}
        </Alert>
      )}

      {(() => {
        if (!alreadyTerminated) {
          return (
            <TerminationForm
              {...{
                t,
                user,
                firstPossibleNoticeDate,
                credentialSettings,
                handleSubmit,
                tab,
                handleTabChanged,
                noticeDate,
                nextTenantEmail,
                handleNextTenantEmailChanged,
                isStudent,
                handleIsStudentChanged,
                ageBetween,
                handleAgeBetweenChanged,
                possibleTerminationDates,
                handleOnChange,
                iban,
                handleIbanChanged,
                createTerminationLoading
              }}
            />
          );
        }

        if (termination.withNextTenant && termination.notWithdrawable) {
          return (
            <NextTenantBookedInfo
              {...{ t, credentialSettings, termination, user }}
            />
          );
        }

        if (termination.withNextTenant && !termination.notWithdrawable) {
          return (
            <NextTenantNotBookedYet
              {...{
                t,
                termination,
                addNewTenant,
                nextTenantEmail,
                handleNextTenantEmailChanged,
                handleCancelTermination
              }}
            />
          );
        }

        return (
          <TerminatedInfo
            {...{
              termination,
              t,
              user,
              credentialSettings,
              handleCancelTermination
            }}
          />
        );
      })()}
    </Container>
  );
};

Termination.propTypes = {
  t: PropTypes.func.isRequired,
  firstPossibleNoticeDate: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  alreadyTerminated: PropTypes.bool.isRequired,
  noticeDate: PropTypes.string.isRequired,
  nextTenantEmail: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  termination: PropTypes.object.isRequired,
  handleCancelTermination: PropTypes.func.isRequired,
  handleNextTenantEmailChanged: PropTypes.func.isRequired,
  createTerminationLoading: PropTypes.bool.isRequired,
  credentialSettings: PropTypes.object,
  promotionCode: PropTypes.string,
  addNewTenant: PropTypes.func
};

export default Termination;
