export const GET_HOUSES = 'GET_HOUSES';
export const GET_HOUSES_SUCCESS = 'GET_HOUSES_SUCCESS';
export const GET_HOUSES_FAILURE = 'GET_HOUSES_FAILURE';

export const getHouses = () => ({
  type: GET_HOUSES
});
export const getHousesSuccess = payload => ({
  type: GET_HOUSES_SUCCESS,
  payload
});
export const getHousesFailure = errors => ({
  type: GET_HOUSES_FAILURE,
  errors
});
