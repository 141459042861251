import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

const CreateNewTenant = ({
  handleSubmit,
  loading,
  t,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  email,
  handleEmailChange,
  password,
  handlePasswordChange
}) => {
  return (
    <Container>
      <Form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
        className="my-5"
      >
        <h2>{t('createNewTenant')}</h2>

        <FormGroup className="mb-3">
          <FormLabel>{t('firstName')}</FormLabel>
          <FormControl
            value={firstName}
            onChange={handleFirstNameChange}
            name="firstName"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel>{t('lastName')}</FormLabel>
          <FormControl
            value={lastName}
            onChange={handleLastNameChange}
            name="lastName"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel>{t('email')}</FormLabel>
          <OverlayTrigger
            placement="top"
            delay={{ show: 300, hide: 400 }}
            overlay={<Tooltip>{t('emailHint')}</Tooltip>}
          >
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              className="ms-1"
              icon={faInfoCircle}
            ></FontAwesomeIcon>
          </OverlayTrigger>
          <FormControl
            value={email}
            onChange={handleEmailChange}
            name="email"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel>{t('password')}</FormLabel>
          <FormControl
            value={password}
            onChange={handlePasswordChange}
            name="password"
            required
          ></FormControl>
        </FormGroup>

        <LoadingButton isLoading={loading} type="submit">
          {t('submit')}
        </LoadingButton>
      </Form>
    </Container>
  );
};

CreateNewTenant.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  handleFirstNameChange: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired
};

export default CreateNewTenant;
