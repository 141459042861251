import { put, takeLatest, call } from 'redux-saga/effects';
import { terminationApi } from 'utils/api';
import i18n from 'i18next';
import {
  ADD_INVITED_NEW_TENANT,
  addInvitedNewTenantSuccess,
  addInvitedNewTenantFailure
} from './addInvitedNewTenant.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* addInvitedNewTenant({ email }) {
  try {
    const { data, errors } = yield call(
      terminationApi.addInvitedNewTenant,
      email
    );

    if (!errors) {
      yield put(addInvitedNewTenantSuccess(data));
      successToast('enlistNewTenant');
    } else {
      yield put(addInvitedNewTenantFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addInvitedNewTenantFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_INVITED_NEW_TENANT, addInvitedNewTenant);
}
