import {
  createReducer,
  defaultError,
  defaultInitialState,
  defaultRequest,
  defaultSuccess
} from 'utils/reducer.helper';
import {
  ASSIGN_TICKET,
  ASSIGN_TICKET_FAILURE,
  ASSIGN_TICKET_SUCCESS
} from './assignTicket.actions';

export default createReducer(
  {
    [ASSIGN_TICKET]: defaultRequest,
    [ASSIGN_TICKET_SUCCESS]: defaultSuccess,
    [ASSIGN_TICKET_FAILURE]: defaultError
  },
  defaultInitialState
);
