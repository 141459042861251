import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFlats } from 'store/flat/getFlats/getFlats.actions';

const useFlats = (selector = state => state.flats) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getFlats);
  const flats = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getFlats());
  }, [success]);

  return flats;
};

export default useFlats;
