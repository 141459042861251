import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Viewer from 'react-viewer';
import { toDisplayDate } from 'utils/date.formatter';

const ClaimReportDetailModal = ({ claimReport, isOpen, onHide, t, state }) => {
  const [open, setStateOpen] = useState(false);
  const images = claimReport.claims[0].images.map((x) => ({ src: x.url }));
  const imagesCount = images.length;

  return (
    <Modal show={isOpen} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {claimReport.moveInClaimReport
            ? t('moveInClaimReport')
            : t('claimReport')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{t('description')}</b>
        <span>{claimReport.claims[0].description}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('reportDate')}</b>
        <span>{toDisplayDate(claimReport.claims[0].reportedAt)}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('state')}</b>
        <span>{state}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('reportedBy')}</b>
        <span>{claimReport.claims[0].reportedBy}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('roomSymbol')}</b>
        <span>{claimReport.roomSymbol}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('tenant')}</b>
        <span>
          {claimReport.tenant.firstName} {claimReport.tenant.lastName}
        </span>
        <div style={{ marginBottom: '10px' }} />
        <Button
          onClick={() => setStateOpen(true)}
          variant="secondary"
          disabled={imagesCount === 0}
        >
          {`${t('images')} ${imagesCount}`}
        </Button>
        <Viewer
          visible={open}
          onClose={() => {
            setStateOpen(false);
          }}
          zIndex={2000}
          images={images}
        />

        <div style={{ marginBottom: '10px' }} />
      </Modal.Body>
    </Modal>
  );
};

ClaimReportDetailModal.defaultState = {
  claimReport: undefined,
  isOpen: false,
};

ClaimReportDetailModal.propTypes = {
  claimReport: PropTypes.object,
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  handleSaveDateClick: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  userRoles: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
};

export default ClaimReportDetailModal;
