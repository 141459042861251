export const SYNCHRONIZE_BANKING = 'SYNCHRONIZE_BANKING';
export const SYNCHRONIZE_BANKING_SUCCESS = 'SYNCHRONIZE_BANKING_SUCCESS';
export const SYNCHRONIZE_BANKING_FAILURE = 'SYNCHRONIZE_BANKING_FAILURE';

export const synchronizeBanking = () => ({
  type: SYNCHRONIZE_BANKING
});

export const synchronizeBankingSuccess = payload => ({
  type: SYNCHRONIZE_BANKING_SUCCESS,
  payload
});

export const synchronizeBankingFailure = errors => ({
  type: SYNCHRONIZE_BANKING_FAILURE,
  errors
});
