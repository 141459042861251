import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  UPDATE_IBAN,
  updateIbanSuccess,
  updateIbanFailure
} from './updateIban.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';

function* updateIban({ iban }) {
  try {
    const { data, errors } = yield call(accountApi.updateIban, iban);

    if (!errors) {
      yield put(updateIbanSuccess(data));
    } else {
      yield put(updateIbanFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateIbanFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_IBAN, updateIban);
}
