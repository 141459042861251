import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { claimReportApi } from 'utils/api';
import {
  INVOICE_CLAIM_REPORT,
  invoiceClaimReportSuccess,
  invoiceClaimReportFailure
} from './invoiceClaimReport.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* invoiceClaimReportSaga({
  claim,
  description,
  userId,
  damageAmount,
  images
}) {
  try {
    const formData = new FormData();
    formData.append('claim', claim);
    formData.append('description', description);
    formData.append('userId', userId);
    formData.append('damageAmount', damageAmount);
    images.forEach(file => {
      formData.append('images', file);
    });

    const { data, errors } = yield call(
      claimReportApi.invoiceClaimReport,
      formData
    );

    if (!errors) {
      yield put(invoiceClaimReportSuccess(data));
      successToast('invoiceClaimReport');
    } else {
      yield put(invoiceClaimReportFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(invoiceClaimReportFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(INVOICE_CLAIM_REPORT, invoiceClaimReportSaga);
}
