import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { paymentApi } from 'utils/api';
import {
  SAVE_PAYMENT,
  savePaymentSuccess,
  savePaymentFailure
} from './savePayment.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* savePayment({ orderId }) {
  try {
    const { data, errors } = yield call(paymentApi.addPayment, orderId);

    if (!errors) {
      yield put(savePaymentSuccess(data));
      successToast('savePayment');
    } else {
      yield put(savePaymentFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(savePaymentFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(SAVE_PAYMENT, savePayment);
}
