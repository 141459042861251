import React from 'react';
import { Container } from 'react-bootstrap';

const DataPrivacy = () => {
  return (
    <Container className="my-5">
      <div>
        <header
          className="entry-header ast-no-thumbnail ast-no-meta"
          style={{
            boxSizing: 'inherit',
            display: 'block',
            marginBottom: '1.5em',
            overflowWrap: 'break-word',
            color: 'rgba(1, 22, 39, 0.75)',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '15px',
            fontStyle: 'normal',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            fontWeight: 400,
            letterSpacing: 'normal',
            orphans: 2,
            textAlign: 'start',
            textIndent: '0px',
            textTransform: 'none',
            whiteSpace: 'normal',
            widows: 2,
            wordSpacing: '0px',
            WebkitTextStrokeWidth: '0px',
            textDecorationThickness: 'initial',
            textDecorationStyle: 'initial',
            textDecorationColor: 'initial',
          }}
        >
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '2.4pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{
                fontSize: '32px',
                fontFamily: '"Arial",sans-serif',
                color: '#011627',
              }}
            >
              DATENSCHUTZ
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Allgemeiner Hinweis und Pflichtinformationen
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Benennung der verantwortlichen Stelle
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Shadet UG (haftungsbeschränkt)
              </span>
            </strong>
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              <br /> Geschäftsführer: Aaron Visang, Christian Surges
              <br /> Register: Amtsgericht Koblenz, HRB 26884
              <br /> Fauviller Ring 1
              <br />
              53501 Grafschaft
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
              anderen über die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Bei Fragen kontaktieren sie uns unter datenschutz@shadet.de
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Widerruf Ihrer Einwilligung zur Datenverarbeitung
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
              Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
              Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
              formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
              Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
              zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher
              Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in
              dem sich der Sitz unseres Unternehmens befindet. Der folgende Link
              stellt eine Liste der Datenschutzbeauftragten sowie deren
              Kontaktdaten bereit:{' '}
              <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                <span
                  style={{
                    color: '#FF7700',
                    border: 'none windowtext 1.0pt',
                    padding: '0cm',
                  }}
                >
                  Siehe hier
                </span>
              </a>
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Recht auf Datenübertragbarkeit
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
              Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern
              Sie die direkte Übertragung der Daten an einen anderen
              Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
              machbar ist.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Datenverarbeitung
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Die Daten auf dieser Demonstrationsplattform werden nicht
              verarbeitet. Täglich um 24 Uhr werden alle Daten gelöscht und
              Demodaten eingespielt. Diese Plattform ist ausschließlich für
              Präsentationszwecke.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Recht auf Auskunft, Berichtigung, Sperrung, Löschung
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Sie haben jederzeit im Rahmen der geltenden gesetzlichen
              Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, Herkunft der Daten, deren
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung, Sperrung oder Löschung dieser Daten.
              Diesbezüglich und auch zu weiteren Fragen zum Thema
              personenbezogene Daten können Sie sich jederzeit über die im
              Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                SSL- bzw. TLS-Verschlüsselung
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Aus Sicherheitsgründen und zum Schutz der Übertragung
              vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden,
              nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind
              Daten, die Sie über diese Website übermitteln, für Dritte nicht
              mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der
              „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der
              Browserzeile.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Server-Log-Dateien
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              In Server-Log-Dateien erhebt und speichert der Provider der
              Website automatisch Informationen, die Ihr Browser automatisch an
              uns übermittelt. Dies sind:
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              – Besuchte Seite auf unserer Domain
              <br /> – Datum und Uhrzeit der Serveranfrage
              <br /> – Browsertyp und Browserversion
              <br /> – Verwendetes Betriebssystem
              <br /> – Referrer URL
              <br /> – Hostname des zugreifenden Rechners
              <br /> – Anmelde Uhrzeit sowie Standort
              <br />
              &nbsp;– IP-Adresse
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Es findet keine Zusammenführung dieser Daten mit anderen
              Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6
              Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung
              eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Cookies
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Unsere Website verwendet Cookies. Das sind kleine Textdateien, die
              Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns
              dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer
              zu machen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Einige Cookies sind “Session-Cookies.”&nbsp;
            </span>
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst
              gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät
              bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns,
              Sie bei Rückkehr auf unserer Website wiederzuerkennen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Mit einem modernen Webbrowser können Sie das Setzen von Cookies
              überwachen, einschränken oder unterbinden. Viele Webbrowser lassen
              sich so konfigurieren, dass Cookies mit dem Schließen des
              Programms von selbst gelöscht werden. Die Deaktivierung von
              Cookies kann eine eingeschränkte Funktionalität unserer Website
              zur Folge haben.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Das Setzen von Cookies, die zur Ausübung elektronischer
              Kommunikationsvorgänge oder der Bereitstellung bestimmter, von
              Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind,
              erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
              Betreiber dieser Website haben wir ein berechtigtes Interesse an
              der Speicherung von Cookies zur technisch fehlerfreien und
              reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung
              anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden
              diese in dieser Datenschutzerklärung separat behandelt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Google Web Fonts
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Unsere Website verwendet Web Fonts von Google.&nbsp;
            </span>
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain
              View, CA 94043, USA.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von
              uns gewünschte Darstellung unserer Website zu präsentieren,
              unabhängig davon welche Schriften Ihnen lokal zur Verfügung
              stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem
              Server von Google in den USA und der damit verbundenen Weitergabe
              Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und
              welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web
              Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
              Betreiber dieser Website haben wir ein berechtigtes Interesse an
              der optimalen Darstellung und Übertragung unseres Webauftritts.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '19.2pt',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Das Unternehmen Google ist für das us-europäische
              Datenschutzübereinkommen „Privacy Shield“ zertifiziert. Dieses
              Datenschutzübereinkommen soll die Einhaltung des in der EU
              geltenden Datenschutzniveaus gewährleisten.
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <span
              style={{ fontSize: '15px', fontFamily: '"Arial",sans-serif' }}
            >
              Einzelheiten über Google Web Fonts finden Sie unter:{' '}
              <a href="https://www.google.com/fonts#AboutPlace:about">
                <span
                  style={{
                    color: '#FF7700',
                    border: 'none windowtext 1.0pt',
                    padding: '0cm',
                  }}
                >
                  https://www.google.com/fonts#AboutPlace:about
                </span>
              </a>{' '}
              und weitere Informationen in den Datenschutzbestimmungen von
              Google:{' '}
              <a href="https://policies.google.com/privacy/partners?hl=de">
                <span
                  style={{
                    color: '#FF7700',
                    border: 'none windowtext 1.0pt',
                    padding: '0cm',
                  }}
                >
                  https://policies.google.com/privacy/partners?hl=de
                </span>
              </a>
            </span>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '0cm',
              marginLeft: '0cm',
              lineHeight: 'normal',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
              verticalAlign: 'baseline',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  border: 'none windowtext 1.0pt',
                  padding: '0cm',
                }}
              >
                Bei Fragen kontaktieren sie uns unter datenschutz@shadet.de
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '0cm',
              marginRight: '0cm',
              marginBottom: '8.0pt',
              marginLeft: '0cm',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            &nbsp;
          </p>
        </header>
        <div
          className="entry-content clear"
          itemProp="text"
          style={{
            boxSizing: 'inherit',
            border: '0px',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 400,
            margin: '0px',
            outline: '0px',
            padding: '0px',
            verticalAlign: 'baseline',
            overflowWrap: 'break-word',
            color: 'rgba(1, 22, 39, 0.75)',
            fontFamily: 'Montserrat, sans-serif',
            fontVariantLigatures: 'normal',
            fontVariantCaps: 'normal',
            letterSpacing: 'normal',
            orphans: 2,
            textAlign: 'start',
            textIndent: '0px',
            textTransform: 'none',
            whiteSpace: 'normal',
            widows: 2,
            wordSpacing: '0px',
            WebkitTextStrokeWidth: '0px',
            textDecorationThickness: 'initial',
            textDecorationStyle: 'initial',
            textDecorationColor: 'initial',
          }}
        >
          <br />
        </div>
      </div>
    </Container>
  );
};

export default DataPrivacy;
