import { Equipment, ImageGallery } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import InfoBoard from '../../views/roomOverview/components/InfoBoard';

const RoomOverviewBase = ({
  room,
  canBook,
  t,
  handleBookClick,
  imageGallery,
  ...rest
}) => {
  return (
    <Row {...rest}>
      {imageGallery ? (
        <Col className="col-12 col-xl-8 col-lg-8 position-relative">
          <ImageGallery imageUrls={room.images.map((x) => x.imageUrl)} />
        </Col>
      ) : (
        <Col className="col-12 col-md-auto p-md-0">
          <Image
            src={room.images[0] ? room.images[0].imageUrl : null}
            className="roomOverviewImage"
            fluid
          />
        </Col>
      )}

      <Col className="col">
        <InfoBoard
          {...{
            t: t,
            tenants: room.flat.totalTenants,
            floor: room.flat.level,
            distance: room.house.minutesToTrainStation,
            address: room.house.address,
            price: room.price,
            deposit: room.deposit,
            squareMeters: room.squareMeters,
            flatSquareMeters: room.flat.squareMeters,
            freeAt: room.freeAt,
          }}
        />
        <Equipment equipment={room.equipment} t={t} />
        {canBook && (
          <Row>
            <Col>
              <Button onClick={handleBookClick} block>
                {t('room:toBookingOverview')}
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

RoomOverviewBase.defaultProps = {
  handleBookClick: () => {},
};

RoomOverviewBase.propTypes = {
  room: PropTypes.object.isRequired,
  t: PropTypes.object.isRequired,
  handleBookClick: PropTypes.func,
  canBook: PropTypes.bool.isRequired,
  imageGallery: PropTypes.bool.isRequired,
};

export default RoomOverviewBase;
