import PropTypes from 'prop-types';
import React from 'react';
import PhoneNumberFormControl from './PhoneNumberFormControl';

const PhoneNumberFormControlContainer = ({ onChange, value, ...rest }) => {
  const phoneNumberRegex = new RegExp(
    '^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$'
  );

  const handlePhoneNumberChange = e => {
    const { value } = e.target;
    const phoneNumberLength = value.length;

    if (phoneNumberRegex.test(value)) {
      if (value.startsWith('+')) {
        if (phoneNumberLength === 3 || phoneNumberLength === 8) {
          onChange({ ...e, target: { value: value + ' ' } });
          return;
        }
      }
      if (!value.startsWith('+')) {
        if (phoneNumberLength === 1 || phoneNumberLength === 6) {
          onChange({ ...e, target: { value: value + ' ' } });
          return;
        }
      }
      onChange(e);
    }
  };

  const handlePhoneNumberKeyDown = e => {
    const { key } = e;
    if (key === 'Backspace') {
      const phoneNumberLength = value.length;
      if (value.startsWith('+')) {
        if (phoneNumberLength === 4 || phoneNumberLength === 9) {
          onChange({ ...e, target: { value: value.slice(0, -1) } });
          return;
        }
      }
      if (!value.startsWith('+')) {
        if (phoneNumberLength === 2 || phoneNumberLength === 7) {
          onChange({ ...e, target: { value: value.slice(0, -1) } });
        }
      }
    }
  };

  return (
    <PhoneNumberFormControl
      {...{ value, handlePhoneNumberChange, handlePhoneNumberKeyDown, ...rest }}
    />
  );
};

PhoneNumberFormControlContainer.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PhoneNumberFormControlContainer;
