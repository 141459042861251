import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ServiceProvider from './ServiceProvider';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { getServiceProvider } from 'store/serviceProvider/getServiceProvider/getServiceProvider.actions';
import { deleteServiceProvider } from 'store/serviceProvider/deleteServiceProvider/deleteServiceProvider.actions';

const ServiceProviderContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();
  const serviceProviderId = parseInt(match.params.serviceProviderId);

  const serviceProviders = useSelector(state => state.serviceProviders);
  const serviceProvider = serviceProviders.find(
    x => x.id === serviceProviderId
  );

  useEffect(() => {
    dispatch(getServiceProvider(serviceProviderId));
  }, [serviceProviderId]);

  const handleEditServiceProviderClick = () =>
    history.push(`/dashboard/data/serviceProvider/${serviceProviderId}/edit`);

  const handleDeleteServiceProviderClick = () => {
    dispatch(deleteServiceProvider(serviceProvider.id));
    history.push(`/dashboard/data/serviceProviders`);
  };

  return serviceProvider !== undefined ? (
    <Loader prop={serviceProvider}>
      <ServiceProvider
        {...{
          serviceProvider,
          handleEditServiceProviderClick,
          handleDeleteServiceProviderClick,
          t
        }}
      />
    </Loader>
  ) : (
    <div />
  );
};

ServiceProviderContainer.propTypes = { match: PropTypes.object.isRequired };

export default ServiceProviderContainer;
