import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { landlordApi } from 'utils/api';
import {
  getTenantForLandlordFailure,
  getTenantForLandlordSuccess,
  GET_TENANT_FOR_LANDLORD,
} from './getTenantForLandlord.actions';

export function* getTenantForLandlord({ userId }) {
  try {
    const { data, errors } = yield call(landlordApi.getTenant, userId);

    if (!errors) {
      yield put(getTenantForLandlordSuccess(data));
    } else {
      yield put(getTenantForLandlordFailure(errors));
    }
  } catch (error) {
    yield put(getTenantForLandlordFailure([i18n.t('base:generalError')]));
  }
}

export default function* () {
  yield takeLatest(GET_TENANT_FOR_LANDLORD, getTenantForLandlord);
}
