import { GET_TENANT_FOR_LANDLORD_SUCCESS } from 'store/landlord/getTenantForLandlord/getTenantForLandlord.actions';
import {
  createReducer,
  pushToArray,
  updateArray,
  updateItemInArray,
} from 'utils/reducer.helper';
import { GET_TENANTS_FOR_LANDLORD_SUCCESS } from '../landlord/getTenantsForLandlord/getTenantsForLandlord.actions';
import { CREATE_NEW_TENANT_SUCCESS } from './createNewTenant/createNewTenant.actions';
import { UPDATE_TENANT_DATA_SUCCESS } from './updateTenantData/updateTenantData.actions';

export default createReducer(
  {
    [GET_TENANTS_FOR_LANDLORD_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [GET_TENANT_FOR_LANDLORD_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [CREATE_NEW_TENANT_SUCCESS]: (state, action) =>
      pushToArray(state, action.payload),
    [UPDATE_TENANT_DATA_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.userId, action.payload, 'userId'),
  },
  []
);
