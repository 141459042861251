import API from './API';

const prefix = 'tenant';

export const updateUserInfo = (
  phoneNumber,
  nationality,
  dateOfBirth,
  gender,
  job,
  firstName,
  lastName
) =>
  API.post(`/${prefix}`, {
    phoneNumber,
    nationality,
    dateOfBirth,
    gender,
    job,
    firstName,
    lastName
  });

export const updateTenantData = (
  id,
  phoneNumber,
  nationality,
  dateOfBirth,
  gender,
  job,
  firstName,
  lastName,
  email
) =>
  API.put(`/${prefix}/${id}`, {
    phoneNumber,
    nationality,
    dateOfBirth,
    gender,
    job,
    firstName,
    lastName,
    email
  });

export default {
  updateUserInfo,
  updateTenantData
};
