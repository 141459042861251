import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import {
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE
} from './activateAccount.actions';

export default createReducer(
  {
    [ACTIVATE_ACCOUNT]: defaultRequest,
    [ACTIVATE_ACCOUNT_SUCCESS]: defaultSuccess,
    [ACTIVATE_ACCOUNT_FAILURE]: defaultError
  },
  defaultInitialState
);
