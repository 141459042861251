import useUserRole from 'hooks/store/useUserRole';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createTicket,
  createTicketClear,
} from 'store/ticket/createTicket/createTicket.actions';
import { LANDLORD } from 'utils/constants/userRole.constants';
import CreateTicket from './CreateTicket';

const CreateTicketContainer = () => {
  const { t } = useTranslation('ticket');
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading: isLoading, success } = useSelector(
    (state) => state.request.createTicket
  );

  const userRole = useUserRole();

  const entityTypeDefaultValue = { key: 0, value: t('entityType.room') };
  const [serviceProviderId, setServiceProviderId] = useState('');
  const [description, setDescription] = useState();
  const [entityType, setEntityType] = useState(entityTypeDefaultValue);
  const [entity, setEntity] = useState({});
  const [intervalInDays, setIntervalInDays] = useState();
  const [images, setSelectedFiles] = useState([]);

  const entityTypeCategories = [
    entityTypeDefaultValue,
    { key: 1, value: t('entityType.flat') },
    { key: 2, value: t('entityType.house') },
  ];

  const handleEntityTypeChange = (e) => {
    const targetValue = parseInt(e.target.value);
    setEntityType(entityTypeCategories.find((x) => x.key === targetValue));
    setEntity('');
    setServiceProviderId('');
  };

  const handleEntityChange = (entity) => {
    setEntity(entity);
    setServiceProviderId('');
  };

  const handleServiceProviderChange = (serviceProviderId) => {
    setServiceProviderId(serviceProviderId);
  };

  const handleIntervalChange = (e) => {
    setIntervalInDays(parseInt(e.target.value));
  };

  const handleSelectFiles = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (images.length === 0 && userRole !== LANDLORD) {
      toast.error(t('noImage'));
      return;
    }
    dispatch(
      createTicket(
        serviceProviderId,
        description,
        entityType.key,
        entity.id,
        intervalInDays,
        images
      )
    );
  };
  useEffect(() => {
    if (success) {
      dispatch(createTicketClear());
      history.push(`/dashboard/tickets`);
    }
  }, [success]);

  return (
    <CreateTicket
      {...{
        serviceProviderId: serviceProviderId,
        description,
        entityType,
        entity,
        intervalInDays,
        setDescription,
        handleSubmit,
        t,
        setEntityType,
        entityTypeCategories,
        handleEntityTypeChange,
        handleEntityChange,
        handleServiceProviderChange,
        handleIntervalChange,
        handleSelectFiles,
        isLoading,
      }}
    />
  );
};

export default CreateTicketContainer;
