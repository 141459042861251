import { faMapMarkerAlt, faTrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetailValue } from 'components';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toDisplayDate } from 'utils/date.formatter';

const InfoBoard = ({
  t,
  tenants,
  floor,
  distance,
  address,
  price,
  deposit,
  squareMeters,
  flatSquareMeters,
  freeAt,
}) => (
  <Row>
    <Col className="col-12">
      <div className="d-flex flex-column">
        <h3 className="mt-3 mt-lg-0">Mieterverwaltung</h3>

        <div className="mt-5 d-flex">
          <div className="d-flex flex-column me-5">
            <DetailValue
              className="mb-2"
              label={t('room:rentalFee')}
              value={`${price}€`}
            />
            <DetailValue
              className="mb-2"
              label={t('room:deposit')}
              value={`${deposit}€`}
            />
            <DetailValue
              className="mb-2"
              label={t('room:floor')}
              value={floor}
            />
            <DetailValue
              label={t('room:freeAt')}
              value={
                moment().isBefore(freeAt)
                  ? toDisplayDate(freeAt)
                  : t('room:fromNowOn')
              }
            />
          </div>
          <div className="d-flex flex-column">
            <DetailValue
              label={t('room:floorSpace')}
              value={`${squareMeters}m²`}
              className="mb-2"
            />
            <DetailValue
              label={t('room:flat')}
              value={`${flatSquareMeters}m²`}
              className="mb-2"
            />
            <DetailValue label={t('room:tenants')} value={`${tenants}`} />
          </div>
        </div>

        <div className="d-flex mt-5">
          <FontAwesomeIcon icon={faTrain} className="me-2" />
          <span>{`${distance} min ${t('room:tram')}`}</span>
        </div>
        <div className="d-flex mt-3">
          <FontAwesomeIcon className="me-2" icon={faMapMarkerAlt} />
          <span>{`${address.street} ${address.houseNumber}, ${address.postalCode} ${address.location}`}</span>
        </div>
      </div>
    </Col>
  </Row>
);

InfoBoard.propTypes = {
  t: PropTypes.func.isRequired,
  tenants: PropTypes.number.isRequired,
  floor: PropTypes.number.isRequired,
  distance: PropTypes.number.isRequired,
  address: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  flatSquareMeters: PropTypes.number.isRequired,
  squareMeters: PropTypes.number.isRequired,
  deposit: PropTypes.number.isRequired,
};

export default InfoBoard;
