import {
  createReducer,
  defaultRequest,
  defaultError,
  defaultInitialState,
  defaultSuccess
} from 'utils/reducer.helper';

import {
  ADD_PAYMENTS,
  ADD_PAYMENTS_SUCCESS,
  ADD_PAYMENTS_FAILURE,
  ADD_PAYMENTS_CLEAR
} from './addPayments.actions';

export default createReducer(
  {
    [ADD_PAYMENTS]: defaultRequest,
    [ADD_PAYMENTS_SUCCESS]: defaultSuccess,
    [ADD_PAYMENTS_FAILURE]: defaultError,
    [ADD_PAYMENTS_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
