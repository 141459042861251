import PropTypes from 'prop-types';
import React from 'react';
import { Button, Container } from 'react-bootstrap';

const Documents = ({ t, userInfo }) => {
  return (
    <Container className="mt-5">
      <div className="mb-2">
        <b>{t('documents')}</b>
      </div>
      <div>
        {userInfo.documents.proofOfResidenceCertificate && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${userInfo.documents.proofOfResidenceCertificate}`}
          >
            <Button variant="secondary w-100 mb-2">
              {t('proofOfResidenceCertificate')}
            </Button>
          </a>
        )}
        {userInfo.documents.rentalContract && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${userInfo.documents.rentalContract}`}
          >
            <Button variant="secondary w-100 mb-2">
              {t('rentalContract')}
            </Button>
          </a>
        )}
        {userInfo.documents.depositSettlement && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${userInfo.documents.depositSettlement}`}
          >
            <Button variant="secondary w-100 mb-2">
              {t('depositSettlement')}
            </Button>
          </a>
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.PUBLIC_URL + '/AGB.pdf'}`}
        >
          <Button variant="secondary w-100 mb-2">{t('gtc')}</Button>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.PUBLIC_URL + '/Nutzungsbedingungen.pdf'}`}
        >
          <Button variant="secondary w-100">{t('termsOfUse')}</Button>
        </a>
      </div>
    </Container>
  );
};

Documents.propTypes = {
  userInfo: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Documents;
