export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE';
export const UPDATE_EMAIL_CLEAR = 'UPDATE_EMAIL_CLEAR';

export const updateEmail = email => ({
  type: UPDATE_EMAIL,
  email
});
export const updateEmailSuccess = payload => ({
  type: UPDATE_EMAIL_SUCCESS,
  payload
});
export const updateEmailFailure = errors => ({
  type: UPDATE_EMAIL_FAILURE,
  errors
});
export const updateEmailClear = () => ({
  type: UPDATE_EMAIL_CLEAR
});
