export const UPDATE_LANDLORD = 'UPDATE_LANDLORD';
export const UPDATE_LANDLORD_SUCCESS = 'UPDATE_LANDLORD_SUCCESS';
export const UPDATE_LANDLORD_FAILURE = 'UPDATE_LANDLORD_FAILURE';
export const UPDATE_LANDLORD_CLEAR = 'UPDATE_LANDLORD_CLEAR';

export const updateLandlord = (id, address, firstName, lastName) => ({
  type: UPDATE_LANDLORD,
  id,
  address,
  firstName,
  lastName
});
export const updateLandlordSuccess = payload => ({
  type: UPDATE_LANDLORD_SUCCESS,
  payload
});
export const updateLandlordFailure = errors => ({
  type: UPDATE_LANDLORD_FAILURE,
  errors
});
export const updateLandlordClear = () => ({
  type: UPDATE_LANDLORD_CLEAR
});
