import { createReducer, updateObject } from 'utils/reducer.helper';

import { SAVE_CREDIT_SETTINGS_SUCCESS } from './saveCreditSettings/saveCreditSettings.actions';
import { GET_CREDIT_SETTINGS_SUCCESS } from './getCreditSettings/getCreditSettings.actions';

export default createReducer(
  {
    [GET_CREDIT_SETTINGS_SUCCESS]: (state, action) =>
      updateObject(state, mapToCreditSettings(action.payload)),
    [SAVE_CREDIT_SETTINGS_SUCCESS]: (state, action) =>
      updateObject(state, mapToCreditSettings(action.payload))
  },
  {}
);

function mapToCreditSettings(settings) {
  const creditAdvertiser = parseFloat(
    settings.find(x => x.optionName === 'CreditAdvertiser').value.replace(',', '.')
  );
  const creditAdvertised = parseFloat(
    settings.find(x => x.optionName === 'CreditAdvertised').value.replace(',', '.')
  );
  const bonus = parseFloat(
    settings.find(x => x.optionName === 'Bonus').value.replace(',', '.')
  );
  return { creditAdvertiser, creditAdvertised, bonus };
}
