import { Footer, Header } from 'components';
import moment from 'moment';
import 'moment/locale/de';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { handleLoadUser } from 'store/account/getUser/user.actions';
import { getAccessToken } from 'utils/localStorage.adapter';
import Routes from './routes';

const App = () => {
  const jwt = getAccessToken();
  const dispatch = useDispatch();

  const { data } = useSelector(state => state.user);

  moment.locale(localStorage.getItem('i18nextLng') === 'en-US' ? 'en' : 'de');

  useEffect(() => {
    if (jwt) dispatch(handleLoadUser(jwt));
  }, [jwt]);

  return (
    <div className="main-container">
      <div className="body-container">
        <Header
          firstName={data ? data.firstName : ''}
          lastName={data ? data.lastName : ''}
        />
        <Routes />
        <ToastContainer newestOnTop={true} style={{ top: '65px' }} />
      </div>
      <Footer />
    </div>
  );
};

export default App;
