export const ADD_SERVICE_PROVIDER = 'ADD_SERVICE_PROVIDER';
export const ADD_SERVICE_PROVIDER_SUCCESS = 'ADD_SERVICE_PROVIDER_SUCCESS';
export const ADD_SERVICE_PROVIDER_FAILURE = 'ADD_SERVICE_PROVIDER_FAILURE';
export const ADD_SERVICE_PROVIDER_CLEAR = 'ADD_SERVICE_PROVIDER_CLEAR';

export const addServiceProvider = (
  firstName,
  lastName,
  email,
  serviceProviderTypes
) => ({
  type: ADD_SERVICE_PROVIDER,
  firstName,
  lastName,
  email,
  serviceProviderTypes
});
export const addServiceProviderSuccess = payload => ({
  type: ADD_SERVICE_PROVIDER_SUCCESS,
  payload
});
export const addServiceProviderFailure = errors => ({
  type: ADD_SERVICE_PROVIDER_FAILURE,
  errors
});
export const addServiceProviderClear = () => ({
  type: ADD_SERVICE_PROVIDER_CLEAR
});
