import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import * as moment from 'moment';

const DateFormControl = ({
  value,
  onChange,
  min,
  max,
  isValid,
  readOnly,
  disabled,
  className
}) => {
  const isValidDate = current => {
    let isValid = true;

    if (min) {
      isValid = isValid && current.isSameOrAfter(min);
    }

    if (max) {
      isValid = isValid && current.isSameOrBefore(max);
    }

    return isValid;
  };

  return (
    <Datetime
      utc={true}
      value={moment.utc(value)}
      onChange={onChange}
      timeFormat={false}
      isValidDate={isValidDate}
      closeOnSelect={true}
      className={className}
      inputProps={{
        style: {
          borderColor: !disabled && isValid === false ? 'red' : '#ced4da',
          backgroundColor: !disabled ? 'white' : '#e9ecef'
        },
        readOnly: readOnly,
        disabled: disabled
      }}
    />
  );
};

DateFormControl.defaultProps = {
  readOnly: true,
  isValid: null,
  disabled: false
};

DateFormControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.object,
  max: PropTypes.object,
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool
};

export default DateFormControl;
