export const SAVE_CREDIT_SETTINGS = 'SAVE_CREDIT_SETTINGS';
export const SAVE_CREDIT_SETTINGS_SUCCESS = 'SAVE_CREDIT_SETTINGS_SUCCESS';
export const SAVE_CREDIT_SETTINGS_FAILURE = 'SAVE_CREDIT_SETTINGS_FAILURE';

export const saveCreditSettings = ({ creditAdvertiser, creditAdvertised, bonus }) => ({
  type: SAVE_CREDIT_SETTINGS,
  creditAdvertiser,
  creditAdvertised,
  bonus
});
export const saveCreditSettingsSuccess = payload => ({
  type: SAVE_CREDIT_SETTINGS_SUCCESS,
  payload
});
export const saveCreditSettingsFailure = errors => ({
  type: SAVE_CREDIT_SETTINGS_FAILURE,
  errors
});
