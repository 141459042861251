import { FileUpload, LoadingButton } from 'components';
import {
  FlatSelect,
  HouseSelect,
  RoomSelect,
  ServiceProviderSelect
} from 'containers';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row
} from 'react-bootstrap';

const CreateTicket = ({
  handleSubmit,
  t,
  serviceProviderId,
  description,
  entityType,
  intervalInDays,
  handleIntervalChange,
  setDescription,
  handleServiceProviderChange,
  entityTypeCategories,
  handleEntityTypeChange,
  handleEntityChange,
  entity,
  handleSelectFiles,
  isLoading
}) => (
  <Container>
    <Row>
      <Col
        className="col-10"
        style={{
          margin: '50px auto',
          padding: '1em',
          border: '.2rem solid #ececec',
          borderWidth: '.2rem',
          borderRadius: '8px'
        }}
      >
        <h2>{t('createTicket')}</h2>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <FormLabel>{t('entityType.self')}</FormLabel>
            <FormControl
              as="select"
              value={entityType.key}
              onChange={e => handleEntityTypeChange(e)}
            >
              {entityTypeCategories.map(entityType => (
                <option key={entityType.key} value={entityType.key}>
                  {entityType.value}
                </option>
              ))}
            </FormControl>
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>{entityType.value}</FormLabel>
            {entityType.key === 0 && (
              <RoomSelect
                onChange={e => handleEntityChange(e)}
                value={entity}
              />
            )}
            {entityType.key === 1 && (
              <FlatSelect
                onChange={e => handleEntityChange(e)}
                value={entity}
              />
            )}
            {entityType.key === 2 && (
              <HouseSelect
                onChange={e => handleEntityChange(e)}
                value={entity}
              />
            )}
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>{t('serviceProvider')}</FormLabel>
            <ServiceProviderSelect
              onChange={e => handleServiceProviderChange(e)}
              value={serviceProviderId}
              selector={state => {
                return state.serviceProviders.filter(
                  x =>
                    x.houseIds.includes(entity?.flat?.houseId) ||
                    x.houseIds.includes(entity?.id) ||
                    x.houseIds.includes(entity?.houseId)
                );
              }}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>{t('description')}</FormLabel>
            <FormControl
              as="textarea"
              value={description}
              onChange={e => setDescription(e.target.value)}
              rows="6"
              required
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <FormLabel>{t('intervalInDays')}</FormLabel>
            <FormControl
              value={intervalInDays}
              onChange={e => handleIntervalChange(e)}
              type="number"
              min="0"
              step="1"
              max="365"
              required
            />
          </FormGroup>
          <FileUpload handleSelectFiles={handleSelectFiles} required={false} />
          <LoadingButton className="mb-3" isLoading={isLoading} type="submit">
            {t('submit')}
          </LoadingButton>
        </Form>
      </Col>
    </Row>
  </Container>
);

CreateTicket.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
  serviceProviderId: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  entityTypeId: PropTypes.number.isRequired,
  entityTypeCategories: PropTypes.array.isRequired,
  intervalInDays: PropTypes.number.isRequired,
  handleIntervalChange: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  handleEntityTypeChange: PropTypes.func.isRequired,
  handleEntityChange: PropTypes.func.isRequired,
  handleServiceProviderChange: PropTypes.func.isRequired,
  handleSelectFiles: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default CreateTicket;
