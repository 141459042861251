import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHouses } from 'store/houses/getHouses/getHouses.actions';

const useHouses = (selector = state => state.houses) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getHouses);
  const houses = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getHouses());
  }, [success]);

  return houses;
};

export default useHouses;
