export const UPDATE_IBAN = 'UPDATE_IBAN';
export const UPDATE_IBAN_SUCCESS = 'UPDATE_IBAN_SUCCESS';
export const UPDATE_IBAN_FAILURE = 'UPDATE_IBAN_FAILURE';
export const UPDATE_IBAN_CLEAR = 'UPDATE_IBAN_CLEAR';

export const updateIban = iban => ({
  type: UPDATE_IBAN,
  iban
});
export const updateIbanSuccess = payload => ({
  type: UPDATE_IBAN_SUCCESS,
  payload
});
export const updateIbanFailure = errors => ({
  type: UPDATE_IBAN_FAILURE,
  errors
});
export const updateIbanClear = () => ({
  type: UPDATE_IBAN_CLEAR
});
