import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';

const RoomPreview = ({
  price,
  images,
  symbol,
  flat,
  description,
  squareMeters,
  history,
  id,
  address,
  t
}) => {
  const goToRoomDetail = () => history.push(`/room/${id}`);
  return (
    <Col xs={12} md={6} lg={4} className="mb-4">
      <Card className="shadow">
        <Card.Img
          variant="top"
          src={images[0] ? images[0].imageUrl : null}
          style={{ minHeight: '200px', maxHeight: '200px', cursor: 'pointer' }}
          onClick={goToRoomDetail}
        />
        <Card.Body>
          <Card.Title>
            <b>
              <div
                style={{ cursor: 'pointer' }}
                onClick={goToRoomDetail}
                className="ellipsis"
              >
                {description}
              </div>
            </b>
          </Card.Title>
          <Card.Text className="mt-2">
            <div className="mb-2" style={{ display: 'flex' }}>
              <div className="me-3">
                <b style={{ fontSize: 18, fontWeight: 500 }}>{`${price} €`}</b>{' '}
                <br />
                <small style={{ color: '#757575' }}>{t('rentalFee')}</small>
              </div>
              <div className="me-3">
                <b
                  style={{ fontSize: 18, fontWeight: 500 }}
                >{`${squareMeters} m²`}</b>{' '}
                <br />
                <small style={{ color: '#757575' }}>{t('floorSpace')}</small>
              </div>
            </div>
            <div className="ellipsis">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="me-2"
              ></FontAwesomeIcon>
              {`${address.street} ${address.houseNumber}, `}
              {`${address.postalCode} ${address.location}`}
            </div>
            <div className="mt-2" style={{ display: 'flex' }}>
              <Button
                style={{ height: '100%', alignSelf: 'center' }}
                variant="primary"
                onClick={goToRoomDetail}
              >
                {t('seeMore')}
              </Button>
              <div className="ms-2">
                <div>
                  {t('roomSymbol')}: <b className="ms-1">{symbol}</b>
                </div>
                <div>
                  {t('flatSymbol')}: <b className="ms-1">{flat.symbol}</b>
                </div>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

RoomPreview.propTypes = {
  id: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  images: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired,
  description: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  squareMeters: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  symbol: PropTypes.string.isRequired,
  flat: PropTypes.object.isRequired
};

export default RoomPreview;
