import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalTitle,
  InputGroup,
  FormControl,
  FormLabel
} from 'react-bootstrap';
import { LoadingButton } from 'components';

const EditTenantBalanceModal = ({
  title,
  show,
  onHide,
  size,
  onChange,
  value,
  submitCreatePayment,
  reference,
  onChangeReference,
  isLoading,
  t
}) => {
  return (
    <Modal size={size} show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>
      <ModalBody>
        <FormLabel>{t('amount')}</FormLabel>
        <InputGroup>
          <FormControl
            value={value}
            onChange={onChange}
            name="amount"
            type="number"
          />
          <InputGroup.Text>{t('€')}</InputGroup.Text>
        </InputGroup>
        <FormLabel style={{ marginTop: '10px' }}>{t('reference')}</FormLabel>
        <FormControl
          value={reference}
          onChange={onChangeReference}
          name="reference"
        />
      </ModalBody>
      <Modal.Footer>
        <LoadingButton
          onClick={submitCreatePayment}
          isLoading={isLoading}
          style={{ marginLeft: '20px' }}
        >
          {t('submit')}
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
};

EditTenantBalanceModal.defaultProps = {
  size: 'md'
};

EditTenantBalanceModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  size: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  submitCreatePayment: PropTypes.func.isRequired,
  onChangeReference: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EditTenantBalanceModal;
