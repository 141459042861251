import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addAddressFailure,
  addAddressSuccess,
} from 'store/address/addAddress/addAddress.actions';
import { addressApi, houseApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  addHouseFailure,
  addHouseSuccess,
  ADD_HOUSE,
} from './addHouse.actions';

function* addHouse({
  levels,
  minutesToTrainStation,
  giverId,
  ownerId,
  address: addressToCreate,
}) {
  try {
    const { data: address, errors: addressErrors } = yield call(
      addressApi.addAddress,
      addressToCreate
    );

    if (!addressErrors) {
      yield put(addAddressSuccess(address));

      const { data: house, errors: houseErrors } = yield call(
        houseApi.addHouse,
        address.id,
        parseInt(minutesToTrainStation),
        parseInt(levels),
        parseInt(giverId),
        parseInt(ownerId)
      );

      const houseWithAddress = {
        ...house,
        address,
      };

      if (!houseErrors) {
        yield put(addHouseSuccess(houseWithAddress));
        successToast('addHouse');
      } else {
        yield put(addHouseFailure(houseErrors));
      }
    } else {
      yield put(addAddressFailure(addressErrors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addHouseFailure(i18n.t('base:generalError')));
    yield put(addAddressFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(ADD_HOUSE, addHouse);
}
