import { put, takeLatest, call, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi, addressApi } from 'utils/api';
import {
  UPDATE_LANDLORD,
  updateLandlordSuccess,
  updateLandlordFailure
} from './updateLandlord.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateLandlordSaga({ id, address, firstName, lastName }) {
  try {
    const state = yield select();
    const currentAddress = state.landlords.find(x => x.id === id).address;

    if (
      currentAddress.houseNumber !== address.houseNumber ||
      currentAddress.location !== address.location ||
      currentAddress.postalCode !== address.postalCode ||
      currentAddress.street !== address.street
    ) {
      yield call(
        addressApi.updateAddress,
        currentAddress.id,
        address.postalCode,
        address.street,
        address.houseNumber,
        address.location
      );
    }

    const { data, errors } = yield call(
      landlordApi.updateLandlord,
      id,
      address.id,
      firstName,
      lastName
    );

    if (!errors) {
      yield put(updateLandlordSuccess(data));
      successToast('updateLandlord');
    } else {
      yield put(updateLandlordFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateLandlordFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_LANDLORD, updateLandlordSaga);
}
