import API from './API';

const prefix = 'serviceProvider';

export const getServiceProvider = id => API.get(`${prefix}/${id}`);
export const getServiceProviders = () => API.get(`${prefix}`);
export const addServiceProvider = (
  firstName,
  lastName,
  email,
  serviceProviderTypes,
  houseIds
) =>
  API.post(`${prefix}/`, {
    firstName,
    lastName,
    email,
    serviceProviderTypes,
    houseIds
  });
export const updateServiceProvider = (
  id,
  firstName,
  lastName,
  email,
  serviceProviderTypes,
  houseIds
) =>
  API.put(`${prefix}`, {
    id,
    firstName,
    lastName,
    email,
    serviceProviderTypes,
    houseIds
  });
export const deleteServiceProvider = id => API.delete(`${prefix}/${id}`);
export const registerServiceProvider = (email, password, token) =>
  API.post(
    `account/${prefix}/confirmEmail?password=${password}&email=${email}&token=${token}`
  );

export default {
  getServiceProviders,
  getServiceProvider,
  addServiceProvider,
  updateServiceProvider,
  deleteServiceProvider,
  registerServiceProvider
};
