import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_HOUSE,
  ADD_HOUSE_SUCCESS,
  ADD_HOUSE_FAILURE,
  ADD_HOUSE_CLEAR
} from './addHouse.actions';

export default createReducer(
  {
    [ADD_HOUSE]: defaultRequest,
    [ADD_HOUSE_SUCCESS]: defaultSuccess,
    [ADD_HOUSE_FAILURE]: defaultError,
    [ADD_HOUSE_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
