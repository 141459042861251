import genders from './genders';
import i18n from 'i18next';

export const getAllGenders = () =>
  genders.map(gender => ({
    ...gender,
    value: i18n.t(`genders:${gender.value}`)
  }));

export const getGenderByKey = key =>
  getAllGenders().find(gender => gender.key === key);

export const getGenderByValue = value =>
  getAllGenders().find(gender => gender.value === value);
