import {
  createReducer,
  defaultError,
  defaultInitialState,
  defaultRequest,
  defaultSuccess,
} from 'utils/reducer.helper';
import {
  APPROVED_DEPOSIT_SETTLEMENT,
  APPROVED_DEPOSIT_SETTLEMENT_CLEAR,
  APPROVED_DEPOSIT_SETTLEMENT_FAILURE,
  APPROVED_DEPOSIT_SETTLEMENT_SUCCESS,
} from './approveDepositSettlement.actions';

export default createReducer(
  {
    [APPROVED_DEPOSIT_SETTLEMENT]: defaultRequest,
    [APPROVED_DEPOSIT_SETTLEMENT_SUCCESS]: defaultSuccess,
    [APPROVED_DEPOSIT_SETTLEMENT_FAILURE]: defaultError,
    [APPROVED_DEPOSIT_SETTLEMENT_CLEAR]: () => defaultInitialState,
  },
  defaultInitialState
);
