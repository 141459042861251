import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import { setAccessToken } from 'utils/localStorage.adapter';
import { LOGIN, loginSuccess, loginFailure } from './login.actions';
import { handleLoadUser } from '../getUser/user.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* login({ email, password }) {
  try {
    const { data, errors } = yield call(accountApi.loginUser, email, password);

    if (!errors) {
      if (data.redirect) {
        window.location = data.url;
        return;
      }

      yield call(setAccessToken, data.token);
      yield put(handleLoadUser(data.token));
      yield put(loginSuccess(data));
      successToast('login');
    } else {
      yield put(loginFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(loginFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(LOGIN, login);
}
