import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseOwnerApi } from 'utils/api';
import {
  GET_HOUSE_OWNERS,
  getHouseOwnersSuccess,
  getHouseOwnersFailure
} from './getHouseOwners.actions';

function* getHouseOwnersSaga() {
  try {
    const { data, errors } = yield call(houseOwnerApi.getHouseOwners);

    if (!errors) {
      yield put(getHouseOwnersSuccess(data));
    } else {
      yield put(getHouseOwnersFailure(errors));
    }
  } catch (error) {
    yield put(getHouseOwnersFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE_OWNERS, getHouseOwnersSaga);
}
