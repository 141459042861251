import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { ServiceProviderForm } from 'containers';

const AddServiceProvider = ({ onSubmit, t, isLoading }) => (
  <Content>
    <h2>{t('serviceProvider.serviceProvider')}</h2>
    <ServiceProviderForm onSubmit={onSubmit} isLoading={isLoading} />
  </Content>
);

AddServiceProvider.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AddServiceProvider;
