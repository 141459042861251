import React from 'react';
import DebtOverview from './DebtOverview';
import { useTranslation } from 'react-i18next';
import useTenants from 'hooks/store/useTenants';

const DebtOverviewContainer = () => {
  const { t } = useTranslation('debtOverview');
  const tenants = useTenants();

  return <DebtOverview t={t} debts={tenants} />;
};

export default DebtOverviewContainer;
