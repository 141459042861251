import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  UPDATE_PHONE_NUMBER,
  updatePhoneNumberSuccess,
  updatePhoneNumberFailure
} from './updatePhoneNumber.actions';
import successToast from 'utils/successToast';
import showGeneralErrorToast from 'utils/generalErrorToast';

function* updatePhoneNumber({ phoneNumber }) {
  try {
    const { data, errors } = yield call(
      accountApi.updatePhoneNumber,
      phoneNumber
    );

    if (!errors) {
      yield put(updatePhoneNumberSuccess(data));
      successToast('updatePhoneNumber');
    } else {
      yield put(updatePhoneNumberFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updatePhoneNumberFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_PHONE_NUMBER, updatePhoneNumber);
}
