import { FormGroupControl, LoadingButton } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormCheck } from 'react-bootstrap';

const TenantRegisterForm = ({
  t,
  registerUser,
  email,
  setEmail,
  password,
  repeatPassword,
  loading,
  handlePasswordChanged,
  handleRepeatPasswordChanged,
  showButton
}) => (
  <Form
    className="mx-3"
    onSubmit={e => {
      e.preventDefault();
      registerUser(email, password, repeatPassword);
    }}
  >
    <FormGroupControl
      label={t('emailAddress')}
      value={email}
      onChange={setEmail}
      required
      type="email"
    ></FormGroupControl>
    <FormGroupControl
      label={t('password')}
      value={password}
      onChange={handlePasswordChanged}
      required
      type="password"
    ></FormGroupControl>
    <FormGroupControl
      label={t('repeatPassword')}
      value={repeatPassword}
      onChange={handleRepeatPasswordChanged}
      required
      type="password"
    ></FormGroupControl>

    {showButton && (
      <>
        <FormCheck
          className="mt-3"
          required
          label={
            <>
              {t('acceptGTC')}{' '}
              {
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.PUBLIC_URL + '/AGB.pdf'}`}
                >
                  {t('GTCs')}
                </a>
              }
            </>
          }
        ></FormCheck>
        <LoadingButton
          className="w-100 mt-3"
          type="submit"
          isLoading={loading}
          showButton={showButton}
        >
          {t('continue')}
        </LoadingButton>
      </>
    )}
  </Form>
);

TenantRegisterForm.propTypes = {
  t: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChanged: PropTypes.func.isRequired,
  repeatPassword: PropTypes.string.isRequired,
  handleRepeatPasswordChanged: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  showButton: PropTypes.string.isRequired
};

export default TenantRegisterForm;
