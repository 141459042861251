import React from 'react';
import PropTypes from 'prop-types';
import { FormGroupControl, LoadingButton } from 'components';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ForgotPassword = ({
  t,
  handleSubmit,
  handleEmailChange,
  loading,
  email
}) => (
  <div className="d-flex justify-content-center">
    <div className="col-12 col-md-6 col-lg-4 my-5">
      <h1 className="text-center mb-5 mt-3 mt-md-0">
        {t('drweberimmobilien')}
      </h1>
      <Form
      className="mx-3"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormGroupControl
        label={t('explanation')}
        value={email}
        onChange={handleEmailChange}
        required
        type="email"
      />
      <LoadingButton className="w-100 mt-3" type="submit" isLoading={loading}>
        {t('submit')}
      </LoadingButton>
      <div
      className="mt-3"
      style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Link to="/login">{t('backToLogin')}</Link>
      </div>
     </Form>
    </div>
  </div>
);

ForgotPassword.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ForgotPassword;
