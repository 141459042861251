import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import {
  GET_FLATS,
  getFlatsSuccess,
  getFlatsFailure
} from './getFlats.actions';

function* getFlats() {
  try {
    const { data, errors } = yield call(flatApi.getFlats);

    if (!errors) {
      yield put(getFlatsSuccess(data));
    } else {
      yield put(getFlatsFailure(errors));
    }
  } catch (error) {
    yield put(getFlatsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_FLATS, getFlats);
}
