import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const FileUpload = ({ selectedFiles, handleSelectFiles, required }) => {
  const fileRef = React.createRef();

  useEffect(() => {
    setFilesCount(selectedFiles.length);
  }, [selectedFiles]);

  const [filesCount, setFilesCount] = useState(selectedFiles.length);
  const { t } = useTranslation('base');
  return (
    <FormGroup className="mb-3">
      <FormControl
        type="file"
        name="imageUpload"
        ref={fileRef}
        multiple
        className="d-none"
        onChange={(e) => {
          handleSelectFiles(e);
          setFilesCount(fileRef.current.files.length);
        }}
        required={required}
      />
      <Button
        variant="light"
        style={{ backgroundColor: '#E0E0E0' }}
        onClick={() => {
          fileRef.current.click();
        }}
      >
        {t('imageUpload') + ' ' + filesCount}
      </Button>
    </FormGroup>
  );
};

FileUpload.propTypes = {
  handleSelectFiles: PropTypes.func.isRequired,
  required: PropTypes.bool,
  selectedFiles: PropTypes.array,
};

FileUpload.defaultProps = {
  required: true,
  selectedFiles: [],
};

export default FileUpload;
