import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseGiverApi, addressApi } from 'utils/api';
import {
  ADD_HOUSE_GIVER,
  addHouseGiverSuccess,
  addHouseGiverFailure
} from './addHouseGiver.actions';
import {
  addAddressSuccess,
  addAddressFailure
} from 'store/address/addAddress/addAddress.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* addHouseGiverSaga({ firstName, lastName, address }) {
  try {
    const { data: addressResult, errors: addressErrors } = yield call(
      addressApi.addAddress,
      address
    );

    if (!addressErrors) {
      yield put(addAddressSuccess(address));

      const { data: houseGiver, errors: houseGiverErrors } = yield call(
        houseGiverApi.addHouseGiver,
        addressResult.id,
        firstName,
        lastName
      );

      const giverWithAddress = {
        ...houseGiver,
        addressResult
      };

      if (!houseGiverErrors) {
        yield put(addHouseGiverSuccess(giverWithAddress));
        successToast('addHouseGiver');
      } else {
        yield put(addHouseGiverFailure(houseGiverErrors));
      }
    } else {
      yield put(addAddressFailure(addressErrors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addHouseGiverFailure(i18n.t('base:generalError')));
    yield put(addAddressFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ADD_HOUSE_GIVER, addHouseGiverSaga);
}
