import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import { REGISTER, registerSuccess, registerFailure } from './register.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* register({ email, password, firstName, lastName }) {
  try {
    const { data, errors } = yield call(
      accountApi.registerUser,
      email,
      password,
      firstName,
      lastName
    );

    if (!errors) {
      yield put(registerSuccess(data));
      successToast('register');
    } else {
      yield put(registerFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(registerFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(REGISTER, register);
}
