import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  GET_CURRENT_ADVERTISER_POSITION,
  GET_CURRENT_ADVERTISER_POSITION_SUCCESS,
  GET_CURRENT_ADVERTISER_POSITION_FAILURE,
  GET_CURRENT_ADVERTISER_POSITION_RESET
} from './getCurrentAdvertiserPosition.actions';

export default createReducer(
  {
    [GET_CURRENT_ADVERTISER_POSITION]: defaultRequest,
    [GET_CURRENT_ADVERTISER_POSITION_SUCCESS]: defaultSuccess,
    [GET_CURRENT_ADVERTISER_POSITION_FAILURE]: defaultError,
    [GET_CURRENT_ADVERTISER_POSITION_RESET]: defaultReset
  },
  defaultInitialState
);
