import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

const GenderFormControl = ({ t, gender, handleOnChange, genders, ...rest }) => (
  <FormControl
    as="select"
    value={gender.value}
    onChange={handleOnChange}
    {...rest}
  >
    <option>{t('select')}</option>
    {genders.map(x => (
      <option key={x.key}>{x.value}</option>
    ))}
  </FormControl>
);
GenderFormControl.propTypes = {
  t: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  genders: PropTypes.array.isRequired
};

export default GenderFormControl;
