import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { FlatForm } from 'containers';

const EditFlat = ({ t, flat, onSubmit, isLoading }) => (
  <Content>
    <h2>{t('editFlat.editFlat')}</h2>
    <FlatForm
      symbol={flat.symbol}
      description={flat.description}
      houseId={flat.houseId}
      level={flat.level}
      squareMeters={flat.squareMeters}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  </Content>
);

EditFlat.propTypes = {
  t: PropTypes.func.isRequired,
  flat: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EditFlat;
