export const ADD_HOUSE_OWNER = 'ADD_HOUSE_OWNER';
export const ADD_HOUSE_OWNER_SUCCESS = 'ADD_HOUSE_OWNER_SUCCESS';
export const ADD_HOUSE_OWNER_FAILURE = 'ADD_HOUSE_OWNER_FAILURE';
export const ADD_HOUSE_OWNER_CLEAR = 'ADD_HOUSE_OWNER_CLEAR';

export const addHouseOwner = (firstName, lastName, address) => ({
  type: ADD_HOUSE_OWNER,
  firstName,
  lastName,
  address
});
export const addHouseOwnerSuccess = payload => ({
  type: ADD_HOUSE_OWNER_SUCCESS,
  payload
});
export const addHouseOwnerFailure = errors => ({
  type: ADD_HOUSE_OWNER_FAILURE,
  errors
});
export const addHouseOwnerClear = () => ({
  type: ADD_HOUSE_OWNER_CLEAR
});
