import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactImageGallery from 'react-image-gallery';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-image-gallery/styles/css/image-gallery.css';

const ImageGallery = ({
  imageUrls,
  onDeleteImage,
  deletable,
  customRef,
  ...rest
}) => (
  <div style={{ backgroundColor: '#333333', position: 'relative' }}>
    {deletable && (
      <FontAwesomeIcon
        icon={faTimes}
        size="2x"
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: '9999',
          color: '#007bff',
          textShadow: '5px',
          cursor: 'pointer'
        }}
        onClick={onDeleteImage}
      />
    )}
    <ReactImageGallery
      items={imageUrls.map(x => {
        return {
          original: x,
          thumbnail: x
        };
      })}
      showPlayButton={false}
      slideDuration={200}
      ref={customRef}
      {...rest}
    />
  </div>
);

ImageGallery.defaultProps = {
  imageUrls: [],
  onDeleteImage: () => {},
  deletable: false
};

ImageGallery.propTypes = {
  imageUrls: PropTypes.array,
  onDeleteImage: PropTypes.func,
  deletable: PropTypes.bool,
  customRef: PropTypes.func
};

export default ImageGallery;
