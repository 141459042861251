import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  CREATE_TICKET,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  CREATE_TICKET_CLEAR
} from './createTicket.actions';

export default createReducer(
  {
    [CREATE_TICKET]: defaultRequest,
    [CREATE_TICKET_SUCCESS]: defaultSuccess,
    [CREATE_TICKET_FAILURE]: defaultError,
    [CREATE_TICKET_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
