import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { paymentApi } from 'utils/api';
import {
  GET_PAYMENTS,
  getPaymentsSuccess,
  getPaymentsFailure
} from './getPayments.actions';

function* getPayments() {
  try {
    const { data, errors } = yield call(paymentApi.getPayments);

    if (!errors) {
      yield put(getPaymentsSuccess(data));
    } else {
      yield put(getPaymentsFailure(errors));
    }
  } catch (error) {
    yield put(getPaymentsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_PAYMENTS, getPayments);
}
