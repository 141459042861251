import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const SizeFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formSize">
    <FormLabel>{i18n.t('forms:size')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      type="number"
      min="0"
      step="1"
      name="size"
      {...rest}
    />
  </FormGroup>
);

SizeFormGroup.defaultProps = {
  value: '0',
  min: '0',
  step: '1'
};

SizeFormGroup.propTypes = {
  value: PropTypes.string,
  min: PropTypes.any,
  step: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default SizeFormGroup;
