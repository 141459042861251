import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'react-bootstrap';

const Equipment = ({ equipment, t }) => {
  return (
    <div className="my-4">
      {equipment.furnished && (
        <Badge bg="secondary" className="me-2 mt-2">
          {t('room:furnished')}
        </Badge>
      )}
      {equipment.washer && (
        <Badge bg="secondary" className="me-2 mt-2">
          {t('room:washer')}
        </Badge>
      )}
      {equipment.internet && (
        <Badge bg="secondary" className="me-2 mt-2">
          {t('room:internet')}
        </Badge>
      )}
      {equipment.kitchen && (
        <Badge bg="secondary" className="me-2 mt-2">
          {t('room:kitchen')}
        </Badge>
      )}
      {equipment.allInclusive && (
        <Badge bg="secondary" className="me-2 mt-2">
          {t('room:allInclusive')}
        </Badge>
      )}
      {equipment.tenancyAgreementInclusive && (
        <Badge bg="secondary" className="me-2 mt-2">
          {t('room:tenancyAgreementInclusive')}
        </Badge>
      )}
    </div>
  );
};

Equipment.propTypes = {
  equipment: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default Equipment;
