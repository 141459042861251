import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHouseOwners } from 'store/houseOwner/getHouseOwners/getHouseOwners.actions';

const useHouseOwners = (selector = state => state.houseOwners) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getHouseOwners);
  const houseOwners = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getHouseOwners());
  }, [success]);

  return houseOwners;
};

export default useHouseOwners;
