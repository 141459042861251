import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_TERMINATION,
  GET_TERMINATION_SUCCESS,
  GET_TERMINATION_FAILURE
} from './getTermination.actions';

export default createReducer(
  {
    [GET_TERMINATION]: defaultRequest,
    [GET_TERMINATION_SUCCESS]: defaultSuccess,
    [GET_TERMINATION_FAILURE]: defaultError
  },
  defaultInitialState
);
