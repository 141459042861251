import { FormGroupControl, LoadingButton } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormCheck, FormLabel } from 'react-bootstrap';

const ActivateAccount = ({
  t,
  handleSubmit,
  handleEmailChange,
  loading,
  email,
  password,
  handlePasswordChange,
  repeatedPassword,
  handleRepeatedPasswordChange
}) => (
  <div className="d-flex justify-content-center">
    <div className="col-12 col-md-6 col-lg-4 my-5">
      <h1 className="text-center mb-5 mt-3 mt-md-0">
        {t('drweberimmobilien')}
      </h1>
      <Form
        className="mx-3"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <FormLabel>{t('explanation')}</FormLabel>
        <FormGroupControl
          label={t('email')}
          value={email}
          onChange={handleEmailChange}
          required
          type="email"
        />
        <FormGroupControl
          label={t('password')}
          value={password}
          onChange={handlePasswordChange}
          required
          type="password"
        />
        <FormGroupControl
          label={t('repeatPassword')}
          value={repeatedPassword}
          onChange={handleRepeatedPasswordChange}
          required
          type="password"
        ></FormGroupControl>

        <FormCheck
          className="mt-3"
          required
          label={
            <>
              {t('acceptGTC')}{' '}
              {
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.PUBLIC_URL + '/AGB.pdf'}`}
                >
                  {t('GTCs')}
                </a>
              }
            </>
          }
        ></FormCheck>
        <LoadingButton className="w-100 mt-3" type="submit" isLoading={loading}>
          {t('submit')}
        </LoadingButton>
      </Form>
    </div>
  </div>
);

ActivateAccount.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  repeatPassword: PropTypes.string.isRequired,
  handleRepeatPasswordChange: PropTypes.func.isRequired
};

export default ActivateAccount;
