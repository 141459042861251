import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const LevelsFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formLevels">
    <FormLabel>{i18n.t('forms:levels')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      name="levels"
      type="number"
      min="0"
      step="1"
      max="99"
      {...rest}
    />
  </FormGroup>
);

LevelsFormGroup.defaultProps = {
  value: '',
  min: '0',
  step: '1',
  max: '99'
};

LevelsFormGroup.propTypes = {
  value: PropTypes.string,
  min: PropTypes.any,
  step: PropTypes.any,
  max: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default LevelsFormGroup;
