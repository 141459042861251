import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const DescriptionFormGroup = ({ value, onChange, rows, ...rest }) => (
  <FormGroup className="mb-3" controlId="formDescription">
    <FormLabel>{i18n.t('forms:description')}</FormLabel>
    <FormControl
      as="textarea"
      rows={rows}
      value={value}
      onChange={onChange}
      name="description"
      {...rest}
    />
  </FormGroup>
);

DescriptionFormGroup.defaultProps = {
  value: '',
  rows: '6'
};

DescriptionFormGroup.propTypes = {
  value: PropTypes.string,
  rows: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default DescriptionFormGroup;
