export const ServiceProviderTypes = {
  caretaker: 0,
  cleaning: 1,
  lockSmith: 2,
  sanitary: 3,
  electrician: 4,
  getType: type => {
    return Object.keys(ServiceProviderTypes).find(
      key => ServiceProviderTypes[key] === type
    );
  }
};

Object.freeze(ServiceProviderTypes);
