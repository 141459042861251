import * as UserRole from 'utils/constants/userRole.constants';

export default [
  {
    key: 1,
    title: 'tenantProfile',
    destination: '/dashboard/user/profile',
    roles: [UserRole.USER],
  },
  {
    key: 2,
    title: 'documents',
    destination: '/dashboard/documents',
    roles: [UserRole.USER],
  },
  {
    key: 3,
    title: 'termination',
    destination: '/dashboard/termination',
    roles: [UserRole.USER],
  },
  {
    key: 4,
    title: 'lackOfMoveIn',
    destination: '/dashboard/report/initial',
    roles: [UserRole.USER],
  },
  {
    key: 5,
    title: 'claimReportOverview',
    destination: '/dashboard/claimReports',
    roles: [UserRole.USER, UserRole.LANDLORD],
  },
  {
    key: 6,
    title: 'account',
    destination: '/dashboard/balance',
    roles: [UserRole.USER],
  },
  {
    key: 7,
    title: 'allTickets',
    destination: '/dashboard/tickets',
    roles: [UserRole.LANDLORD, UserRole.SERVICEPROVIDER],
  },
  {
    key: 8,
    title: 'baseData',
    destination: '/dashboard/data',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 9,
    title: 'tenants',
    destination: '/dashboard/tenants',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 10,
    title: 'rooms',
    destination: '/dashboard/rooms',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 11,
    title: 'history',
    destination: '/dashboard/history',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 12,
    title: 'statistic',
    destination: '/dashboard/statistic',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 13,
    title: 'earnMoney',
    destination: '/dashboard/earnmoney',
    roles: [UserRole.USER],
  },
  {
    key: 14,
    title: 'creditNotes',
    destination: '/dashboard/creditNotes',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 15,
    title: 'settings',
    destination: '/dashboard/settings/landlord',
    roles: [UserRole.LANDLORD],
  },
  {
    key: 16,
    title: 'settings',
    destination: '/dashboard/settings/tenant',
    roles: [UserRole.USER],
  },
  {
    key: 17,
    title: 'faq',
    destination: '/dashboard/faq',
    roles: [UserRole.USER],
  },
  {
    key: 18,
    title: 'claimReport',
    destination: '/dashboard/report/serviceProvider',
    roles: [UserRole.SERVICEPROVIDER],
  },
  {
    key: 19,
    title: 'serviceProviderProfile',
    destination: '/dashboard/serviceProvider/profile',
    roles: [UserRole.SERVICEPROVIDER],
  },
];
