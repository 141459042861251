import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceProviderTypeBadges } from 'components';
import Content from 'components/Content';
import { HouseList } from 'containers';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ServiceProviderTypes } from 'utils/constants/serviceProviderTypes';

const ServiceProvider = ({
  serviceProvider,
  handleEditServiceProviderClick,
  handleDeleteServiceProviderClick,
  t
}) => (
  <Content>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('serviceProvider.editServiceProvider')}</Tooltip>}
    >
      <Button
        style={{ float: 'right' }}
        onClick={handleEditServiceProviderClick}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="top"
      delay={{ show: 300, hide: 400 }}
      overlay={<Tooltip>{t('serviceProvider.deleteServiceProvider')}</Tooltip>}
    >
      <Button
        style={{ float: 'right', marginRight: 20 }}
        onClick={handleDeleteServiceProviderClick}
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </OverlayTrigger>
    <h5>{t('serviceProvider.firstName')}</h5>
    <span>{serviceProvider.firstName}</span>
    <h5>{t('serviceProvider.lastName')}</h5>
    <span>{serviceProvider.lastName}</span>
    <h5>{t('serviceProvider.types')}</h5>
    <ServiceProviderTypeBadges
      types={serviceProvider.serviceProviderTypes.map(x =>
        t(`${ServiceProviderTypes.getType(x)}`)
      )}
    />
    <br />
    <br />
    <h5>{t('houses.houses')}</h5>
    <HouseList
      houseSelector={state =>
        state.houses.filter(x => serviceProvider.houseIds.includes(x.id))
      }
    />
  </Content>
);

ServiceProvider.propTypes = {
  serviceProvider: PropTypes.object.isRequired,
  handleEditServiceProviderClick: PropTypes.func.isRequired,
  handleDeleteServiceProviderClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default ServiceProvider;
