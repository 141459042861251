export const GET_ALL_ROOMS = 'GET_ALL_ROOMS';
export const GET_ALL_ROOMS_SUCCESS = 'GET_ALL_ROOMS_SUCCESS';
export const GET_ALL_ROOMS_FAILURE = 'GET_ALL_ROOMS_FAILURE';

export const getAllRooms = () => ({
  type: GET_ALL_ROOMS
});
export const getAllRoomsSuccess = payload => ({
  type: GET_ALL_ROOMS_SUCCESS,
  payload
});
export const getAllRoomsFailure = errors => ({
  type: GET_ALL_ROOMS_FAILURE,
  errors
});
