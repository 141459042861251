import {
  createReducer,
  updateArray,
  pushToArray,
  updateItemInArray,
  removeItemInArray
} from 'utils/reducer.helper';

import { ADD_LANDLORD_SUCCESS } from './addLandlord/addLandlord.actions';
import { DELETE_LANDLORD_SUCCESS } from './deleteLandlord/deleteLandlord.actions';
import { GET_LANDLORD_SUCCESS } from './getLandlord/getLandlord.actions';
import { GET_LANDLORDS_SUCCESS } from './getLandlords/getLandlords.actions';
import { UPDATE_LANDLORD_SUCCESS } from './updateLandlord/updateLandlord.actions';

export default createReducer(
  {
    [ADD_LANDLORD_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [DELETE_LANDLORD_SUCCESS]: (state, action) =>
      updateArray(state, action.payload),
    [GET_LANDLORD_SUCCESS]: (state, action) =>
      pushToArray(state, action.payload),
    [GET_LANDLORDS_SUCCESS]: (state, action) =>
      updateItemInArray(state, action.payload.id, action.payload),
    [UPDATE_LANDLORD_SUCCESS]: (state, action) =>
      removeItemInArray(state, action.payload.id)
  },
  []
);
