export const INVOICE_CLAIM_REPORT = 'INVOICE_CLAIM_REPORT';
export const INVOICE_CLAIM_REPORT_SUCCESS = 'INVOICE_CLAIM_REPORT_SUCCESS';
export const INVOICE_CLAIM_REPORT_FAILURE = 'INVOICE_CLAIM_REPORT_FAILURE';
export const INVOICE_CLAIM_REPORT_CLEAR = 'INVOICE_CLAIM_REPORT_CLEAR';

export const invoiceClaimReport = (
  claim,
  description,
  userId,
  damageAmount,
  images
) => ({
  type: INVOICE_CLAIM_REPORT,
  claim,
  description,
  userId,
  damageAmount,
  images
});

export const invoiceClaimReportSuccess = payload => ({
  type: INVOICE_CLAIM_REPORT_SUCCESS,
  payload
});

export const invoiceClaimReportFailure = errors => ({
  type: INVOICE_CLAIM_REPORT_FAILURE,
  errors
});

export const invoiceClaimReportClear = () => ({
  type: INVOICE_CLAIM_REPORT_CLEAR
});
