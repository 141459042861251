import { put, takeLatest, call } from 'redux-saga/effects';
import { creditNotesApi } from 'utils/api';
import i18n from 'i18next';
import {
  GET_OFFERED_CREDIT_NOTES,
  getOfferedCreditNotesSuccess,
  getOfferedCreditNotesFailure
} from './getOfferedCreditNotes.actions';

function* getOfferedCreditNotes() {
  try {
    const { data, errors } = yield call(creditNotesApi.getOfferedCreditNotes);
    if (!errors && data.errors.length === 0) {
      yield put(getOfferedCreditNotesSuccess(data.resultObject));
    } else {
      yield put(getOfferedCreditNotesFailure(errors || data.errors));
    }
  } catch (error) {
    yield put(getOfferedCreditNotesFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(GET_OFFERED_CREDIT_NOTES, getOfferedCreditNotes);
}
