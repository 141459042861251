import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseApi } from 'utils/api';
import {
  GET_HOUSE,
  getHouseSuccess,
  getHouseFailure
} from './getHouse.actions';

function* getHouse({ id }) {
  try {
    const { data, errors } = yield call(houseApi.getHouse, id);

    if (!errors) {
      yield put(getHouseSuccess(data));
    } else {
      yield put(getHouseFailure(errors));
    }
  } catch (error) {
    yield put(getHouseFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE, getHouse);
}
