import React, { useEffect, useState } from 'react';
import Menu from './Menu';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import menuItems from './menuItems';
import useUser from './../../../../hooks/store/useUser';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

const MenuContainer = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();

  const { data: user, success } = useUser(state => state.user);

  const [highlightedMenuPoint, setHighlightedMenuPoint] = useState();

  const bootstrapLargeSize = 992;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < bootstrapLargeSize
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < bootstrapLargeSize);
    });
  }, []);

  return success ? (
    <Menu
      {...{
        t,
        history,
        menuItems,
        highlightedMenuPoint,
        setHighlightedMenuPoint,
        isMobile,
        user,
        daysLeftForLackOfMoveIn: user.booking
          ? moment(user.booking.startDateContract)
              .add(5, 'days')
              .diff(moment().startOf('day'), 'days')
          : 0,
        daysLeftForStartOfContract: user.booking
          ? moment(user.booking.startDateContract).diff(
              moment().startOf('day'),
              'days'
            )
          : 0
      }}
    />
  ) : (
    <div className="center-at-page">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default MenuContainer;
