import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  GET_NEWSLETTER_SUBSCRIPTION,
  GET_NEWSLETTER_SUBSCRIPTION_SUCCESS,
  GET_NEWSLETTER_SUBSCRIPTION_FAILURE,
  GET_NEWSLETTER_SUBSCRIPTION_RESET
} from './getNewsletterSubscription.actions';

export default createReducer(
  {
    [GET_NEWSLETTER_SUBSCRIPTION]: defaultRequest,
    [GET_NEWSLETTER_SUBSCRIPTION_SUCCESS]: defaultSuccess,
    [GET_NEWSLETTER_SUBSCRIPTION_FAILURE]: defaultError,
    [GET_NEWSLETTER_SUBSCRIPTION_RESET]: defaultReset
  },
  defaultInitialState
);
