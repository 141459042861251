import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const MenuItem = ({ children, roles }) => {
  const role = useSelector(state => state.user.data.role);
  return roles.some(x => x === role) ? children : <div />;
};

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.array
};

export default MenuItem;
