import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseGiverApi } from 'utils/api';
import {
  DELETE_HOUSE_GIVER,
  deleteHouseGiverSuccess,
  deleteHouseGiverFailure
} from './deleteHouseGiver.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteHouseGiverSaga({ id }) {
  try {
    const { data, errors } = yield call(houseGiverApi.deleteHouseGiver, id);

    if (!errors) {
      yield put(deleteHouseGiverSuccess(data));
      successToast('deleteHouseGiver');
    } else {
      yield put(deleteHouseGiverFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteHouseGiverFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_HOUSE_GIVER, deleteHouseGiverSaga);
}
