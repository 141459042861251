import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { landlordApi } from 'utils/api';
import {
  GET_LANDLORDS,
  getLandlordsSuccess,
  getLandlordsFailure
} from './getLandlords.actions';

function* getLandlordsSaga() {
  try {
    const { data, errors } = yield call(landlordApi.getLandlords);

    if (!errors) {
      yield put(getLandlordsSuccess(data));
    } else {
      yield put(getLandlordsFailure(errors));
    }
  } catch (error) {
    yield put(getLandlordsFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_LANDLORDS, getLandlordsSaga);
}
