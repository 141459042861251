export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const updateAddress = (id, address) => ({
  type: UPDATE_ADDRESS,
  id,
  address
});
export const updateAddressSuccess = payload => ({
  type: UPDATE_ADDRESS_SUCCESS,
  payload
});
export const updateAddressFailure = errors => ({
  type: UPDATE_ADDRESS_FAILURE,
  errors
});
