import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDebts } from 'store/debt/getDebts/getDebts.actions';

const useDebts = (selector = state => state.debts) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getDebts);
  const debts = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getDebts());
  }, [success]);

  return debts;
};

export default useDebts;
