export const GET_CURRENT_ADVERTISER_POSITION = 'GET_CURRENT_ADVERTISER_POSITION';
export const GET_CURRENT_ADVERTISER_POSITION_SUCCESS = 'GET_CURRENT_ADVERTISER_POSITION_SUCCESS';
export const GET_CURRENT_ADVERTISER_POSITION_FAILURE = 'GET_CURRENT_ADVERTISER_POSITION_FAILURE';
export const GET_CURRENT_ADVERTISER_POSITION_RESET = 'GET_CURRENT_ADVERTISER_POSITION_RESET';

export const getCurrentAdvertiserPosition = () => ({
  type: GET_CURRENT_ADVERTISER_POSITION
});
export const getCurrentAdvertiserPositionSuccess = payload => ({
  type: GET_CURRENT_ADVERTISER_POSITION_SUCCESS,
  payload
});
export const getCurrentAdvertiserPositionFailure = errors => ({
  type: GET_CURRENT_ADVERTISER_POSITION_FAILURE,
  errors
});

export const getCurrentAdvertiserPositionReset = () => ({
  type: GET_CURRENT_ADVERTISER_POSITION_RESET
});
