import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HouseOwner from './HouseOwner';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { getHouseOwner } from 'store/houseOwner/getHouseOwner/getHouseOwner.actions';
import { deleteHouseOwner } from 'store/houseOwner/deleteHouseOwner/deleteHouseOwner.actions';

const HouseOwnerContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();
  const houseOwnerId = parseInt(match.params.houseOwnerId);

  const houseOwners = useSelector(state => state.houseOwners);
  const houseOwner = houseOwners.find(x => x.id === houseOwnerId);

  useEffect(() => {
    dispatch(getHouseOwner(houseOwnerId));
  }, [houseOwnerId]);

  const handleEdithouseOwnerClick = () =>
    history.push(`/dashboard/data/houseOwner/${houseOwnerId}/edit`);

  const handleDeleteHouseOwnerClick = () => {
    dispatch(deleteHouseOwner(houseOwner.id));
    history.push(`/dashboard/data/houseOwners`);
  };

  return houseOwner !== undefined ? (
    <Loader prop={houseOwner}>
      <HouseOwner
        {...{
          houseOwner,
          handleEdithouseOwnerClick,
          handleDeleteHouseOwnerClick,
          t
        }}
      />
    </Loader>
  ) : (
    <div />
  );
};

HouseOwnerContainer.propTypes = { match: PropTypes.object.isRequired };

export default HouseOwnerContainer;
