export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const register = (email, password, firstName, lastName) => ({
  type: REGISTER,
  email,
  password,
  firstName,
  lastName
});
export const registerSuccess = payload => ({
  type: REGISTER_SUCCESS,
  payload
});
export const registerFailure = errors => ({
  type: REGISTER_FAILURE,
  errors
});
