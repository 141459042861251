import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseApi } from 'utils/api';
import {
  DELETE_HOUSE,
  deleteHouseSuccess,
  deleteHouseFailure
} from './deleteHouse.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* deleteHouse({ id }) {
  try {
    const { data, errors } = yield call(houseApi.deleteHouse, id);

    if (!errors) {
      yield put(deleteHouseSuccess(data));
      successToast('deleteHouse');
    } else {
      yield put(deleteHouseFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(deleteHouseFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(DELETE_HOUSE, deleteHouse);
}
