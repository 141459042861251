import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendClaimReport,
  sendClaimReportClear,
} from 'store/claimReport/sendClaimReport/sendClaimReport.actions';
import errorToast from 'utils/errorToast';
import CreateClaimReportServiceProvider from './CreateClaimReport.serviceProvider';

const CreateClaimReportServiceProviderContainer = () => {
  const { t } = useTranslation('claimReport');
  const dispatch = useDispatch();

  const { isLoading, success } = useSelector(
    (state) => state.request.sendClaimReport
  );

  const [description, setDescription] = useState('');
  const handleDescriptionChanged = (e) => {
    setDescription(e.target.value);
  };

  const [subject, setSubject] = useState('');
  const handleSubjectChanged = (e) => {
    setSubject(e.target.value);
  };

  const [roomId, setRoomId] = useState('');

  const [images, setImages] = useState([]);
  const handleSelectFiles = (e) => {
    setImages([...e.target.files]);
  };

  useEffect(() => {
    setSubject('');
    setRoomId('');
    setDescription('');
    setImages([]);
    dispatch(sendClaimReportClear());
  }, [success]);

  const handleSubmitform = (e) => {
    e.preventDefault();
    if (images.length === 0) {
      errorToast('claimReport:noImageError');
      return;
    }
    dispatch(
      sendClaimReport(subject, description, images, false, null, null, roomId)
    );
  };

  return (
    <CreateClaimReportServiceProvider
      {...{
        description,
        handleDescriptionChanged,
        handleSelectFiles,
        handleSubmitform,
        t,
        subject,
        handleSubjectChanged,
        roomId,
        setRoomId,
        isLoading,
        images,
      }}
    />
  );
};

export default CreateClaimReportServiceProviderContainer;
