import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import House from './House';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import { getHouse } from 'store/houses/getHouse/getHouse.actions';
import { deleteHouse } from 'store/houses/deleteHouse/deleteHouse.actions';

const HouseContainer = ({ match }) => {
  const { t } = useTranslation('data');
  const dispatch = useDispatch();
  const history = useHistory();
  const houseId = parseInt(match.params.houseId);

  const houses = useSelector(state => state.houses);
  const house = houses.find(x => x.id === houseId);

  useEffect(() => {
    dispatch(getHouse(houseId));
  }, [houseId]);

  const handleEditHouseClick = () =>
    history.push(`/dashboard/data/house/${houseId}/edit`);

  const flatSelector = state =>
    state.flats.filter(flat =>
      flat.house ? flat.house.id === house.id : flat.houseId === house.id
    );
  const handleDeleteHouseClick = () => {
    dispatch(deleteHouse(house.id));
    history.push(`/dashboard/data/houses`);
  };
  return house !== undefined ? (
    <Loader prop={house}>
      <House
        {...{
          house,
          handleEditHouseClick,
          handleDeleteHouseClick,
          t,
          flatSelector
        }}
      />
    </Loader>
  ) : (
    <div />
  );
};

HouseContainer.propTypes = { match: PropTypes.object.isRequired };

export default HouseContainer;
