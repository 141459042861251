import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  VALIDATE_PROMOTIONCODE,
  VALIDATE_PROMOTIONCODE_SUCCESS,
  VALIDATE_PROMOTIONCODE_FAILURE,
  VALIDATE_PROMOTIONCODE_RESET
} from './validatePromotionCode.actions';

export default createReducer(
  {
    [VALIDATE_PROMOTIONCODE]: defaultRequest,
    [VALIDATE_PROMOTIONCODE_SUCCESS]: defaultSuccess,
    [VALIDATE_PROMOTIONCODE_FAILURE]: defaultError,
    [VALIDATE_PROMOTIONCODE_RESET]: defaultReset
  },
  defaultInitialState
);
