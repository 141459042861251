import {
  DescriptionFormGroup,
  HouseFormGroup,
  SizeFormGroup,
  SubmitButtonFormGroup,
  SymbolFormGroup
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const FlatForm = ({
  symbol,
  handleSymbolChange,
  level,
  handleLevelChange,
  squareMeters,
  handleSquareMetersChange,
  houseId,
  handleHouseIdChange,
  description,
  handleDescriptionChange,
  handleSubmit,
  t,
  isSubmitEnabled,
  house,
  isLoading
}) => (
  <Form onSubmit={handleSubmit}>
    <SymbolFormGroup value={symbol} onChange={handleSymbolChange} required />

    <SizeFormGroup
      value={squareMeters}
      onChange={handleSquareMetersChange}
      required
    />

    <HouseFormGroup onChange={handleHouseIdChange} value={houseId} required />

    <FormGroup className="mb-3">
      <FormLabel>{t('flatForm.level')}</FormLabel>
      <FormControl
        value={level}
        onChange={handleLevelChange}
        disabled={!houseId}
        type="number"
        min="0"
        max={house ? house.levels : 0}
        step="1"
        required
      />
    </FormGroup>

    <DescriptionFormGroup
      value={description}
      onChange={handleDescriptionChange}
      required
    />

    <SubmitButtonFormGroup isLoading={isLoading} />
  </Form>
);

FlatForm.propTypes = {
  level: PropTypes.number,
  handleLevelChange: PropTypes.func.isRequired,
  squareMeters: PropTypes.number,
  handleSquareMetersChange: PropTypes.func.isRequired,
  houseId: PropTypes.number,
  handleHouseIdChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isSubmitEnabled: PropTypes.bool.isRequired,
  houses: PropTypes.array.isRequired,
  house: PropTypes.object,
  symbol: PropTypes.string,
  handleSymbolChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default FlatForm;
