export const updateObject = (oldObject, newValues) =>
  Object.assign({}, oldObject, newValues);

export const updateArray = (oldArray, newArray) =>
  Object.assign([], oldArray, newArray);

export const pushToArray = (oldArray, newItem) => {
  const newArray = oldArray.push(newItem);
  return Object.assign([], oldArray, newArray);
};

export const removeItemInArray = (oldArray, itemId) => {
  const newArray = oldArray.filter(item => item.id !== itemId);
  return Object.assign([], newArray);
};

export function updateItemInArray(
  oldArray,
  itemId,
  updatedItem,
  itemIdKey = 'id'
) {
  if (oldArray.length === 0) {
    return [updatedItem];
  }

  const updatedItems = oldArray.map(item => {
    if (item[itemIdKey] !== itemId) {
      return item;
    }

    return updatedItem;
  });

  return updatedItems;
}

export const createReducer = (handlers, initialState) =>
  function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };

export const defaultInitialState = {
  data: null,
  loading: false,
  success: false,
  errors: []
};

export const defaultRequest = (state, action) =>
  updateObject(state, { loading: true });

export const defaultSuccess = (state, action) =>
  updateObject(state, {
    loading: false,
    success: true,
    data: action.payload,
    errors: []
  });

export const defaultError = (state, action) => {
  const { errors } = action;
  return updateObject(state, {
    loading: false,
    errors: typeof errors === 'string' ? [errors] : errors
  });
};

export const defaultReset = (state, action) => {
  return updateObject(state, {
    data: null,
    loading: false,
    success: false,
    errors: []
  });
};

export function mergeArrays(arr1, arr2) {
  const result = [];
  arr1.forEach(x => {
    const exists = arr2.some(y => y.id === x.id);
    if (exists) {
      arr2.forEach(z => {
        if (z.id === x.id) result.push({ ...x, ...z });
      });
    } else {
      result.push(x);
    }
  });
  arr2.forEach(x => {
    if (!arr1.some(y => y.id === x.id)) result.push(x);
  });
  return result;
}
