import { DateFormControl } from 'components';
import { Dropdown } from 'containers';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Filter = ({ onSearch, cities, ...rest }) => {
  const { t } = useTranslation('filter');
  const [priceTo, setPriceTo] = useState();
  const [fromSize, setFromSize] = useState();
  const [city, setCity] = useState();
  const [freeAt, setFreeAt] = useState(moment());

  return (
    <div
      {...rest}
      style={{
        padding: 20,
        backgroundColor: 'white',
        borderRadius: '4px',
        display: 'inline-block',
      }}
    >
      <Form className="d-flex gap-2 flex-wrap">
        <InputGroup className="w-auto">
          <InputGroup.Text style={{ width: '3em', justifyContent: 'center' }}>
            €
          </InputGroup.Text>
          <FormControl
            type="number"
            onChange={(e) => setPriceTo(parseInt(e.target.value))}
            value={priceTo}
            className="me-4"
            placeholder={t('priceTo')}
          />
        </InputGroup>
        <InputGroup className="w-auto">
          <InputGroup.Text style={{ width: '3em', justifyContent: 'center' }}>
            m²
          </InputGroup.Text>
          <FormControl
            type="number"
            onChange={(e) => setFromSize(parseInt(e.target.value))}
            value={fromSize}
            className="me-4"
            placeholder={t('fromSize')}
          />
        </InputGroup>
        <Dropdown
          onChange={(e) => {
            if (e.target.value === t('allCities')) {
              setCity('');
              return;
            }
            setCity(e.target.value);
          }}
          value={city}
          className="me-4"
          useFirstAsDefault
          options={[{ value: t('allCities') }].concat(
            cities.map((x) => {
              return { value: x };
            })
          )}
          output={(x) => x.value}
          style={{ width: 255 }}
        />
        <DateFormControl
          onChange={(e) => setFreeAt(e)}
          value={freeAt}
          className="input-group me-4 w-auto"
          placeholder={t('freeAt')}
          style={{ width: 255 }}
        />
        <Button
          className="mt-3 mt-md-0 mx-auto"
          onClick={() => onSearch({ priceTo, fromSize, city, freeAt })}
        >
          {t('search')}
        </Button>
      </Form>
    </div>
  );
};

Filter.propTypes = {
  onSearch: PropTypes.func.isRequired,
  cities: PropTypes.array,
};

export default Filter;
