import { LoadingButton } from 'components';
import React from 'react';
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  ListGroup
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const NextTenantNotBookedYet = ({
  t,
  termination,
  addNewTenant,
  nextTenantEmail,
  handleNextTenantEmailChanged,
  handleCancelTermination
}) => (
  <>
    <ListGroup className="mb-3">
      <ListGroup.Item className="border-0">
        {t('nextTenantHasNotBookedYet')}
      </ListGroup.Item>
      <ListGroup.Item className="border-0">
        {t('invitedNextTenants')}
        <br />
        {termination.nextTenantEmails.map(x => (
          <>
            {x.email}
            <b style={{ color: 'red' }}> ({t('notBookedYet')})</b>
            <br />
          </>
        ))}
      </ListGroup.Item>
      <ListGroup.Item className="border-0">
        <Form
          onSubmit={e => {
            e.preventDefault();
            addNewTenant();
          }}
        >
          <FormLabel>{t('addNextTenant')}</FormLabel>
          <FormGroup
            className="mb-3"
            style={{ width: '100%', display: 'flex' }}
          >
            <FormControl
              type="email"
              value={nextTenantEmail}
              onChange={handleNextTenantEmailChanged}
              required
            />
            <LoadingButton className="mb-2" type="submit">
              {t('invite')}
            </LoadingButton>
          </FormGroup>
        </Form>
      </ListGroup.Item>
    </ListGroup>
    <LoadingButton onClick={handleCancelTermination} className="mb-2">
      {t('cancelTermination')}
    </LoadingButton>
  </>
);

NextTenantNotBookedYet.propTypes = {
  t: PropTypes.func.isRequired,
  termination: PropTypes.object.isRequired,
  addNewTenant: PropTypes.func.isRequired,
  nextTenantEmail: PropTypes.string.isRequired,
  handleNextTenantEmailChanged: PropTypes.func.isRequired,
  handleCancelTermination: PropTypes.func.isRequired
};

export default NextTenantNotBookedYet;
