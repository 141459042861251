import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { claimReportApi } from 'utils/api';
import {
  GET_CLAIM_REPORT,
  getClaimReportSuccess,
  getClaimReportFailure
} from './getClaimReport.actions';

import claimReports from './_temp.json';

export function* getClaimReport({ id }) {
  try {
    // const { data, errors } = yield call(claimReportApi.getClaimReport, id);

    const data = claimReports.find(x => x.id === id);
    const errors = null;

    if (!errors) {
      yield put(getClaimReportSuccess(data));
    } else {
      yield put(getClaimReportFailure(errors));
    }
  } catch (error) {
    yield put(getClaimReportFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(GET_CLAIM_REPORT, getClaimReport);
}
