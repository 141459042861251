import { ServiceProviderTypeBadges } from 'components';
import useServiceProviders from 'hooks/store/useServiceProviders';
import PropTypes from 'prop-types';
import React from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import { ServiceProviderTypes } from 'utils/constants/serviceProviderTypes';
import Select from '../../shared/select';

const ServiceProviderSelectContainer = ({
  onChange,
  value,
  selector,
  ...rest
}) => {
  const serviceProviders = useServiceProviders(selector);

  return (
    <Select
      className="mb-3"
      value={value}
      onChange={onChange}
      options={serviceProviders}
      selectProp="id"
      output={serviceProvider =>
        `${serviceProvider.firstName} ${serviceProvider.lastName}`
      }
      renderMenuItemChildren={(option, { text }, index) => (
        <>
          <Highlighter search={text}>
            {`${option.firstName} ${option.lastName} `}
          </Highlighter>

          <ServiceProviderTypeBadges
            types={option.serviceProviderTypes.map(x =>
              ServiceProviderTypes.getType(x)
            )}
          />
        </>
      )}
      {...rest}
    />
  );
};

ServiceProviderSelectContainer.defaultProps = {
  onChange: () => {},
  selector: state => state.serviceProviders
};

ServiceProviderSelectContainer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  selector: PropTypes.func
};

export default ServiceProviderSelectContainer;
