import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState,
  defaultReset
} from 'utils/reducer.helper';

import {
  ADD_INVITED_NEW_TENANT,
  ADD_INVITED_NEW_TENANT_SUCCESS,
  ADD_INVITED_NEW_TENANT_FAILURE,
  ADD_INVITED_NEW_TENANT_RESET
} from './addInvitedNewTenant.actions';

export default createReducer(
  {
    [ADD_INVITED_NEW_TENANT]: defaultRequest,
    [ADD_INVITED_NEW_TENANT_SUCCESS]: defaultSuccess,
    [ADD_INVITED_NEW_TENANT_FAILURE]: defaultError,
    [ADD_INVITED_NEW_TENANT_RESET]: defaultReset
  },
  defaultInitialState
);
