export const GET_ROOM_HOUSE = 'GET_ROOM_HOUSE';
export const GET_ROOM_HOUSE_SUCCESS = 'GET_ROOM_HOUSE_SUCCESS';
export const GET_ROOM_HOUSE_FAILURE = 'GET_ROOM_HOUSE_FAILURE';

export const getRoomHouse = id => ({
  type: GET_ROOM_HOUSE,
  id
});
export const getRoomHouseSuccess = payload => ({
  type: GET_ROOM_HOUSE_SUCCESS,
  payload
});
export const getRoomHouseFailure = errors => ({
  type: GET_ROOM_HOUSE_FAILURE,
  errors
});
