import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorAlerts from 'components/ErrorAlerts';
import { confirmEmail } from 'store/account/confirmEmail/confirmEmail.actions';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Row, Container, Col } from 'react-bootstrap';

const ConfirmEmailContainer = ({ location }) => {
  const { t } = useTranslation('confirmEmail');
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = location;
  const queryStrings = qs.parse(search);

  const token = queryStrings['?token'];
  const email = queryStrings.email;

  const { success, errors } = useSelector(state => state.request.confirmEmail);

  useEffect(() => {
    dispatch(confirmEmail(token, email));
  }, []);

  useEffect(() => {
    if (success) {
      history.push('./login');
    }
  }, [success]);

  return (
    <Container fluid>
      <Row>
        <Col lg={4} md={6} xs={12}>
          <div className="mt-5 mb-3">{t('confirmingEmail')}</div>
          <ErrorAlerts errors={errors} />
        </Col>
      </Row>
    </Container>
  );
};

ConfirmEmailContainer.propTypes = {
  location: PropTypes.object.isRequired
};

export default ConfirmEmailContainer;
