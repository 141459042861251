import React from 'react';
import PropTypes from 'prop-types';
import { ErrorAlerts, FormGroupControl, LoadingButton } from 'components';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const ResetPassword = ({
  t,
  handleSubmit,
  password,
  setPassword,
  repeatPassword,
  setRepeatPassword,
  errors,
  loading
}) => (
  <div className="d-flex justify-content-center">
    <div className="col-12 col-md-6 col-lg-4 my-5">
      <h1 className="text-center mb-5 mt-3 mt-md-0">
        {t('drweberimmobilien')}
      </h1>
      <ErrorAlerts errors={errors} />
      <Form
      className="mx-3"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <FormGroupControl
        label={t('password')}
        value={password}
        onChange={setPassword}
        required
        type="password"
      />
      <FormGroupControl
        label={t('repeatPassword')}
        value={repeatPassword}
        onChange={setRepeatPassword}
        required
        type="password"
      />
      <LoadingButton className="w-100 mt-3" type="submit" isLoading={loading}>
        {t('submit')}
      </LoadingButton>
      <div
      className="mt-3"
      style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Link to="/login">{t('backToLogin')}</Link>
      </div>
      </Form>
    </div>
  </div>
);

ResetPassword.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ResetPassword;
