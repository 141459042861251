import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_SERVICE_PROVIDER,
  ADD_SERVICE_PROVIDER_SUCCESS,
  ADD_SERVICE_PROVIDER_FAILURE,
  ADD_SERVICE_PROVIDER_CLEAR
} from './addServiceProvider.actions';

export default createReducer(
  {
    [ADD_SERVICE_PROVIDER]: defaultRequest,
    [ADD_SERVICE_PROVIDER_SUCCESS]: defaultSuccess,
    [ADD_SERVICE_PROVIDER_FAILURE]: defaultError,
    [ADD_SERVICE_PROVIDER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
