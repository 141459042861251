import { ImagesFormGroup, LoadingButton } from 'components';
import React, { useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  createClaim,
  createClaimClear,
} from 'store/claim/createClaim/createClaim.actions';

const AddClaimModal = ({ t, show, setShow, ticketId }) => {
  const [amount, setAmount] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [image, setImage] = useState([]);
  const handleSelectImage = (e) => {
    setImage(e.target.files[0]);
  };

  const dispatch = useDispatch();

  const { success, loading } = useSelector(
    (state) => state.request.createClaim
  );

  const handleAddClaim = (e) => {
    e.preventDefault();
    dispatch(createClaim(title, description, amount, ticketId, null, image));
  };

  useEffect(() => {
    if (success) {
      setAmount(null);
      setDescription(null);
      setTitle(null);
      setImage(null);
      dispatch(createClaimClear());
      setShow(false);
    }
  }, [success]);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t('addClaim')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleAddClaim}>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <FormGroup className="mb-3">
            <FormLabel>{t('title')}</FormLabel>
            <FormControl
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>{t('amount')}</FormLabel>
            <FormControl
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              min="0"
              step="1"
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>{t('description')}</FormLabel>
            <FormControl
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={6}
            />
          </FormGroup>
          <ImagesFormGroup
            required
            multiple={false}
            onChange={handleSelectImage}
          />
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton className="mb-3" isLoading={loading} type="submit">
            {t('addClaim')}
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddClaimModal;
