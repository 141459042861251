export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';

export const confirmEmail = (token, email) => ({
  type: CONFIRM_EMAIL,
  token,
  email
});
export const confirmEmailSuccess = payload => ({
  type: CONFIRM_EMAIL_SUCCESS,
  payload
});
export const confirmEmailFailure = errors => ({
  type: CONFIRM_EMAIL_FAILURE,
  errors
});
