import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bookingNextTenant } from 'store/booking/bookingNextTenant/bookingNextTenant.actions';
import {
  canBookRoom,
  canBookRoomSuccess as canBookRoomSuccessAction,
} from 'store/booking/canBookRoom/canBookRoom.actions';
import { createBooking } from 'store/booking/createBooking/createBooking.actions';
import { createLog } from 'store/logging/createLog/createLog.actions';
import Payment from '../../../../../payment/Payment';

const CreateBookingContainer = ({
  location,
  startOfRental,
  rentalDuration,
  deposit,
  promotionCode,
  newTenant,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('payment');
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [documentsGenerating, setDocumentsGenerating] = useState(false);

  const { success: createBookingSuccess } = useSelector(
    (state) => state.request.createBooking
  );

  const { loading: canBookRoomLoading, success: canBookRoomSuccess } =
    useSelector((state) => state.request.canBookRoom);

  const { success: bookingNextTenantSuccess } = useSelector(
    (state) => state.request.bookingNextTenant
  );

  const { roomId } = useParams();

  useEffect(() => {
    if (!newTenant.terminationDate) {
      dispatch(canBookRoom(startOfRental, rentalDuration, roomId));
      return;
    }

    dispatch(canBookRoomSuccessAction());
  }, []);

  useEffect(() => {
    if (createBookingSuccess) history.push('/user/profile');
    if (bookingNextTenantSuccess) history.push('/user/profile');
  }, [createBookingSuccess, bookingNextTenantSuccess]);

  const handlePaymentSuccess = (orderId) => {
    setDocumentsGenerating(true);

    if (newTenant.terminationDate) {
      dispatch(
        bookingNextTenant(
          newTenant.terminationDate,
          newTenant.tenantId,
          roomId,
          orderId,
          newTenant.moveInDate,
          rentalDuration,
          newTenant.secret
        )
      );

      return;
    }

    dispatch(
      createBooking(
        startOfRental,
        rentalDuration,
        roomId,
        orderId,
        promotionCode
      )
    );
  };

  const handlePaymentError = (e) => {
    dispatch(createLog(4, 'Payment failed with error {e}', [String(e)]));
  };

  return (
    <>
      {canBookRoomLoading && (
        <Alert variant="info">{t('bookingValidation')}</Alert>
      )}
      {canBookRoomSuccess && !documentsGenerating && (
        <Container className="my-5">
          <Payment
            handlePaymentSuccess={handlePaymentSuccess}
            handlePaymentError={handlePaymentError}
            amount={deposit}
            xs={12}
            header={t('header')}
            text={t('caution')}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          >
            <p className="mb-5">{t('caution')}</p>
          </Payment>
        </Container>
      )}

      {documentsGenerating && (
        <Container className="my-5">
          <Col className="text-center justify-content-center mb-3">
            <h2 className="mb-3">{t('paymentSuccess')}</h2>
            {t('documentsGenerating')}
          </Col>
          <Row className="text-center justify-content-center">
            <Spinner animation="border" variant="primary" />
          </Row>
        </Container>
      )}
    </>
  );
};

CreateBookingContainer.propTypes = {
  location: PropTypes.object.isRequired,
  startOfRental: PropTypes.string.isRequired,
  rentalDuration: PropTypes.string.isRequired,
  deposit: PropTypes.string.isRequired,
  newTenant: PropTypes.object.isRequired,
};

export default CreateBookingContainer;
