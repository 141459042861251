export const DELETE_HOUSE_GIVER = 'DELETE_HOUSE_GIVER';
export const DELETE_HOUSE_GIVER_SUCCESS = 'DELETE_HOUSE_GIVER_SUCCESS';
export const DELETE_HOUSE_GIVER_FAILURE = 'DELETE_HOUSE_GIVER_FAILURE';
export const DELETE_HOUSE_GIVER_CLEAR = 'DELETE_HOUSE_GIVER_CLEAR';

export const deleteHouseGiver = id => ({
  type: DELETE_HOUSE_GIVER,
  id
});
export const deleteHouseGiverSuccess = payload => ({
  type: DELETE_HOUSE_GIVER_SUCCESS,
  payload
});
export const deleteHouseGiverFailure = errors => ({
  type: DELETE_HOUSE_GIVER_FAILURE,
  errors
});
export const deleteHouseGiverClear = () => ({
  type: DELETE_HOUSE_GIVER_CLEAR
});
