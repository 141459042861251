import { put, takeLatest, call } from 'redux-saga/effects';
import { roomApi, flatApi } from 'utils/api';
import i18n from 'i18next';
import { GET_ROOM, getRoomSuccess, getRoomFailure } from './getRoom.actions';

function* getRoom({ roomId }) {
  try {
    const { data, errors } = yield call(roomApi.getRoom, roomId);

    if (!errors) {
      const { data: rooms } = yield call(flatApi.getFlatRooms, data.flat.id);
      const { data: house } = yield call(roomApi.getRoomHouse, roomId);
      const room = {
        ...data,
        flat: {
          ...data.flat,
          totalTenants: rooms.filter(x => x.resident !== null).length,
          currentTenants: rooms
            .filter(x => x.resident !== null)
            .map(x => x.resident)
        },
        house
      };

      yield put(getRoomSuccess(room));
    } else {
      yield put(getRoomFailure(errors));
    }
  } catch {
    yield put(getRoomFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_ROOM, getRoom);
}
