import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { houseGiverApi } from 'utils/api';
import {
  GET_HOUSE_GIVER,
  getHouseGiverSuccess,
  getHouseGiverFailure
} from './getHouseGiver.actions';

function* getHouseGiverSaga({ id }) {
  try {
    const { data, errors } = yield call(houseGiverApi.getHouseGiver, id);

    if (!errors) {
      yield put(getHouseGiverSuccess(data));
    } else {
      yield put(getHouseGiverFailure(errors));
    }
  } catch (error) {
    yield put(getHouseGiverFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_HOUSE_GIVER, getHouseGiverSaga);
}
