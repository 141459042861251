import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

const LongitudeFormGroup = ({ value, onChange, ...rest }) => (
  <FormGroup className="mb-3" controlId="formLongitude">
    <FormLabel>{i18n.t('forms:longitude')}</FormLabel>
    <FormControl
      value={value}
      onChange={onChange}
      name="longitude"
      maxLength="50"
      {...rest}
    />
  </FormGroup>
);

LongitudeFormGroup.defaultProps = {
  value: ''
};

LongitudeFormGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default LongitudeFormGroup;
