import useHouseGivers from 'hooks/store/useHouseGivers';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../shared/select';

const HouseGiverSelectContainer = ({ onChange, value, selector, ...rest }) => {
  const houseGivers = useHouseGivers(selector);

  return (
    <Select
      className="mb-3"
      value={value}
      onChange={onChange}
      options={houseGivers}
      selectProp="id"
      output={houseGiver => `${houseGiver.firstName} ${houseGiver.lastName}`}
      {...rest}
    />
  );
};

HouseGiverSelectContainer.defaultProps = {
  onChange: () => {},
  selector: state => state.houseGivers
};

HouseGiverSelectContainer.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  selector: PropTypes.func
};

export default HouseGiverSelectContainer;
