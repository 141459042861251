import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PromotionRoomListItem from './PromotionRoomListItem';

const PromotionRoomListItemContainer = ({ room }) => {
  const promotionCode = useSelector((state) => state.user.data.promotionCode);
  const { t } = useTranslation('promotionRoomListItem');
  const moneyForAdvertised = useSelector(
    (state) => state.settings.credit.creditAdvertised + '€'
  );

  const promotionText = 'Hier steht ihr Bewerbungstext';

  return (
    <PromotionRoomListItem room={room} promotionText={promotionText} t={t} />
  );
};

PromotionRoomListItemContainer.propTypes = {
  room: PropTypes.object.isRequired,
};

export default PromotionRoomListItemContainer;
