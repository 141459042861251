export const GET_HOUSE_GIVERS = 'GET_HOUSE_GIVERS';
export const GET_HOUSE_GIVERS_SUCCESS = 'GET_HOUSE_GIVERS_SUCCESS';
export const GET_HOUSE_GIVERS_FAILURE = 'GET_HOUSE_GIVERS_FAILURE';
export const GET_HOUSE_GIVERS_CLEAR = 'GET_HOUSE_GIVERS_CLEAR';

export const getHouseGivers = () => ({
  type: GET_HOUSE_GIVERS
});
export const getHouseGiversSuccess = payload => ({
  type: GET_HOUSE_GIVERS_SUCCESS,
  payload
});
export const getHouseGiversFailure = errors => ({
  type: GET_HOUSE_GIVERS_FAILURE,
  errors
});
export const getHouseGiversClear = () => ({
  type: GET_HOUSE_GIVERS_CLEAR
});
