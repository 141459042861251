import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HouseGiverForm from './HouseGiverForm';
import useInputState from 'hooks/useInputState';

const HouseGiverFormContainer = ({
  onSubmit,
  firstName: _firstName,
  lastName: _lastName,
  address: _address,
  isLoading
}) => {
  const [firstName, setFirstName] = useInputState(_firstName);
  const [lastName, setLastName] = useInputState(_lastName);
  const [address, setAddress] = useState(_address);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      firstName,
      lastName,
      address
    });
  };

  return (
    <HouseGiverForm
      {...{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        handleSubmit,
        address,
        setAddress,
        isLoading
      }}
    />
  );
};

HouseGiverFormContainer.defaultProps = {
  onSubmit: () => {},
  firstName: '',
  lastName: '',
  address: {}
};

HouseGiverFormContainer.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onSubmit: PropTypes.func,
  address: PropTypes.object,
  isLoading: PropTypes.bool
};

export default HouseGiverFormContainer;
