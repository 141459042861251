import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE
} from './getAddress.actions';

export default createReducer(
  {
    [GET_ADDRESS]: defaultRequest,
    [GET_ADDRESS_SUCCESS]: defaultSuccess,
    [GET_ADDRESS_FAILURE]: defaultError
  },
  defaultInitialState
);
