import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { toDisplayDate } from 'utils/date.formatter';

const PromotionRoomListItem = ({ room, promotionText, t }) => (
  <div style={{ position: 'relative' }}>
    <div
      style={{
        display: 'flex',
        height: 120,
        width: '100%',
        border: 'lightgrey 1px solid',
        borderRadius: '5px',
        overflow: 'hidden',
        margin: '15px 0px',
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ height: '80px', width: '80px' }}>
          <img
            src={room.images[0] ? room.images[0].imageUrl : null}
            style={{ width: '80px', height: '80px' }}
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', margin: '0 20px' }}
        >
          <b> {room.description}</b>

          <div>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
            {`${room.address.street} ${room.address.houseNumber}, `}
            {`${room.address.postalCode} ${room.address.location}`}
          </div>
        </div>
        <div style={{ margin: '0 20px' }}>
          <b
            style={{ fontSize: 18, fontWeight: 500 }}
          >{`${room.squareMeters} m²`}</b>{' '}
          <br />
          <small style={{ color: '#757575' }}>{t('squareMeters')}</small>
        </div>

        <div style={{ margin: '0 20px' }}>
          <b style={{ fontSize: 18, fontWeight: 500 }}>{`${room.price} €`}</b>{' '}
          <br />
          <small style={{ color: '#757575' }}> {t('rentalFee')}</small>
        </div>

        <div style={{ margin: '0 20px' }}>
          <b style={{ fontSize: 18, fontWeight: 500 }}>{`${room.deposit} €`}</b>{' '}
          <br />
          <small style={{ color: '#757575' }}> {t('deposit')}</small>
        </div>

        <div style={{ margin: '0 20px' }}>
          <b style={{ fontSize: 18, fontWeight: 500 }}>
            {room.bookable ? (
              <b style={{ fontSize: 18, fontWeight: 500 }}>
                {' '}
                {t('immediately')}{' '}
              </b>
            ) : (
              <span>{toDisplayDate(room.freeAt)}</span>
            )}
          </b>
          <br />
          <small style={{ color: '#757575' }}>{t('freeAt')}</small>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <OverlayTrigger
          overlay={
            <Popover id="popover-basic">
              <Popover.Header as="h3">
                {t('copyAdvertisingText')}
              </Popover.Header>
              <Popover.Body>{promotionText}</Popover.Body>
            </Popover>
          }
          trigger={['hover', 'focus']}
          placement="left"
          delay={{ show: 250, hide: 250 }}
        >
          <Button onClick={() => navigator.clipboard.writeText(promotionText)}>
            {t('copyAdvertisingText')}
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  </div>
);

PromotionRoomListItem.propTypes = {
  room: PropTypes.object.isRequired,
  promotionText: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default PromotionRoomListItem;
