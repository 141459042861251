import React, { useEffect, useState } from 'react';
import CreditNotes from './CreditNotes';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCreditNotes } from 'store/creditNotes/getAllCreditNotes/getAllCreditNotes.actions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CreditNotesContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('creditNotes');

  const [fromDate, setFromDate] = useState('');
  const handleFromDateChange = e => setFromDate(e);

  const [toDate, setToDate] = useState('');
  const handleToDateChange = e => setToDate(e);

  const { success, data: creditNotes } = useSelector(
    state => state.request.getAllCreditNotes
  );

  const [creditNotesToDisplay, setCreditNotesToDisplay] = useState([]);

  useEffect(() => {
    if (!success) dispatch(getAllCreditNotes());
  }, [success]);

  const creditNoteColumns = [
    {
      dataField: 'customerId',
      text: t('customerId'),
      sort: true
    },
    {
      dataField: 'advertisedTenants',
      text: t('advertisedTenants'),
      sort: true
    },
    {
      dataField: 'totalEarnedMoney',
      text: t('totalEarnedMoney'),
      sort: true
    }
  ];

  useEffect(() => {
    if (!creditNotes) return;

    const newList = creditNotes.filter(x => {
      if (!fromDate && !toDate) return true;
      if (!fromDate) return moment(x.redeemedAt).isBefore(toDate);
      if (!toDate) return moment(x.redeemedAt).isAfter(fromDate);

      return moment(x.redeemedAt).isBetween(fromDate, toDate);
    });

    setCreditNotesToDisplay(newList);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (creditNotes) setCreditNotesToDisplay(creditNotes);
  }, [creditNotes]);

  const calculateAdvertisedTenants = () => {
    if (!creditNotes) return 0;
    if (creditNotes.length === 0) return 0;

    return creditNotes.length;
  };

  const calculateTotalAmount = () => {
    if (!creditNotes) return 0;
    if (creditNotes.length === 0) return 0;

    let sum = 0;

    creditNotes.forEach(
      x => (sum += x.paymentAdvertised.amount + x.paymentAdvertiser.amount)
    );

    return parseFloat(sum).toFixed(2);
  };

  const mapDisplayData = () => {
    if (!creditNotesToDisplay) return [];
    if (creditNotesToDisplay.length === 0) return [];

    const tenants = [];
    const displayData = [];

    creditNotesToDisplay.forEach(x => {
      if (tenants.some(y => y === x.advertiser.customerId)) return;
      tenants.push(x.advertiser.customerId);
    });

    tenants.forEach(customerId => {
      const advertisedTenants = creditNotesToDisplay.filter(
        x => x.advertiser.customerId === customerId
      ).length;

      let totalEarnedMoney = 0;
      creditNotesToDisplay.forEach(creditNote => {
        if (creditNote.advertiser.customerId !== customerId) return;
        totalEarnedMoney += creditNote.paymentAdvertiser.amount;
      });

      displayData.push({
        customerId,
        advertisedTenants,
        totalEarnedMoney: totalEarnedMoney.toFixed(2) + '€'
      });
    });

    return displayData;
  };

  return (
    <CreditNotes
      creditNotes={creditNotesToDisplay}
      creditNoteColumns={creditNoteColumns}
      fromDate={fromDate}
      handleFromDateChange={handleFromDateChange}
      toDate={toDate}
      handleToDateChange={handleToDateChange}
      t={t}
      advertisedTenants={calculateAdvertisedTenants()}
      totalAmount={calculateTotalAmount() + '€'}
      displayData={mapDisplayData()}
    />
  );
};

export default CreditNotesContainer;
