import API from './API';

const prefix = 'claimreport';

export const getClaimReports = () => API.get(`${prefix}`);
export const getClaimReport = id => API.get(`${prefix}/${id}`);
export const getAllClaimreportsForLandlord = () =>
  API.get(`${prefix}/landlord`);
export const sendClaimReport = formData => API.post(`${prefix}`, formData);
export const invoiceClaimReport = formData => API.post(`${prefix}`, formData);

export default {
  sendClaimReport,
  getClaimReport,
  getClaimReports,
  invoiceClaimReport,
  getAllClaimreportsForLandlord
};
