import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useInputState from 'hooks/useInputState';
import { useSelector, useDispatch } from 'react-redux';
import { resendConfirmationEmail } from 'store/account/resendConfirmationEmail/resendConfirmationEmail.actions';
import { loginClear } from 'store/account/login/login.actions';
import { useHistory } from 'react-router-dom';
import ResendConfirmationEmail from './ResendConfirmationEmail';

const ResendConfirmationEmailContainer = () => {
  const { t } = useTranslation('requestConfirmationMail');
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useInputState('');
  const { success, errors } = useSelector(
    state => state.request.resendConfirmationEmail
  );

  const handleSubmit = () => {
    dispatch(resendConfirmationEmail(email));
  };

  useEffect(() => {
    if (success) {
      dispatch(loginClear());
      history.push('./login');
    }
  }, [success]);

  return (
    <ResendConfirmationEmail
      {...{ t, email, setEmail, errors, handleSubmit }}
    />
  );
};

export default ResendConfirmationEmailContainer;
