import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { approveDepositSettlement } from 'store/depositSettlement/approveDepositSettlement/approveDepositSettlement.actions';
import { toDisplayDate } from 'utils/date.formatter';

const BookingDetailModal = ({ booking, isOpen, onHide, t }) => {
  const dispatch = useDispatch();

  return (
    <Modal show={isOpen} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('booking')}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{t('tenant')}</b>
        <span>
          {booking.tenant?.firstName + ' ' + booking.tenant?.lastName}
        </span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('room')}</b>
        <span>{booking.room?.symbol}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('startDate')}</b>
        <span>{toDisplayDate(booking.startDate)}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('terminatedTo')}</b>
        <span>{toDisplayDate(booking.termination?.effectiveFrom)}</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('deposit')}</b>
        <span>{booking.deposit}€</span>
        <div style={{ marginBottom: '10px' }} />
        <b>{t('rentalFee')}</b>
        <span>{booking.rentalFee}€</span>
        <div style={{ marginBottom: '10px' }} />
        {booking.depositSettlement && (
          <>
            <b>{t('depositSettlement')}</b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${booking.depositSettlement.link}`}
            >
              <Button variant="secondary w-100">{t('view')}</Button>
            </a>
            <div style={{ marginBottom: '10px' }} />

            <Button
              onClick={() =>
                dispatch(approveDepositSettlement(booking.depositSettlement.id))
              }
              variant="secondary w-100"
            >
              {t('approve')}
            </Button>

            <div style={{ marginBottom: '10px' }} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

BookingDetailModal.defaultState = {
  claimReport: undefined,
  isOpen: false,
};

BookingDetailModal.propTypes = {
  claimReport: PropTypes.object,
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  handleSaveDateClick: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  userRoles: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
};

export default BookingDetailModal;
