export const ADD_HOUSE = 'ADD_HOUSE';
export const ADD_HOUSE_SUCCESS = 'ADD_HOUSE_SUCCESS';
export const ADD_HOUSE_FAILURE = 'ADD_HOUSE_FAILURE';
export const ADD_HOUSE_CLEAR = 'ADD_HOUSE_CLEAR';

export const addHouse = (
  levels,
  minutesToTrainStation,
  giverId,
  ownerId,
  address
) => ({
  type: ADD_HOUSE,
  levels,
  minutesToTrainStation,
  giverId,
  ownerId,
  address,
});
export const addHouseSuccess = (payload) => ({
  type: ADD_HOUSE_SUCCESS,
  payload,
});
export const addHouseFailure = (errors) => ({
  type: ADD_HOUSE_FAILURE,
  errors,
});
export const addHouseClear = () => ({
  type: ADD_HOUSE_CLEAR,
});
