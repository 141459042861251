import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { accountApi } from 'utils/api';
import {
  UPDATE_EMAIL,
  updateEmailSuccess,
  updateEmailFailure
} from './updateEmail.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* updateEmail({ email }) {
  try {
    const { data, errors } = yield call(accountApi.updateEmail, email);

    if (!errors) {
      yield put(updateEmailSuccess(data));
      successToast('updateEmail');
    } else {
      yield put(updateEmailFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(updateEmailFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(UPDATE_EMAIL, updateEmail);
}
