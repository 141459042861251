import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { roomApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import { addRoomFailure, addRoomSuccess, ADD_ROOM } from './addRoom.actions';

function* addRoom({
  symbol,
  flatId,
  squareMeters,
  price,
  deposit,
  description,
  freeAt,
  images,
  equipment,
  locationOfRoom,
}) {
  try {
    const formData = new FormData();
    formData.append('symbol', symbol);
    formData.append('flatId', flatId);
    formData.append('squareMeters', parseInt(squareMeters));
    formData.append('price', price);
    formData.append('deposit', deposit);
    formData.append('description', description);
    formData.append('freeAt', freeAt);
    formData.append('Equipment.Furnished', equipment.furnished);
    formData.append('Equipment.Washer', equipment.washer);
    formData.append('Equipment.Internet', equipment.internet);
    formData.append('Equipment.Kitchen', equipment.kitchen);
    formData.append('Equipment.AllInclusive', equipment.allInclusive);
    formData.append('locationOfRoom', locationOfRoom);

    formData.append(
      'equipment.TenancyAgreementInclusive',
      equipment.tenancyAgreementInclusive
    );
    images.forEach((file) => {
      formData.append('images', file);
    });

    const { data, errors } = yield call(roomApi.addRoom, formData);

    if (!errors) {
      yield put(addRoomSuccess(data));
      successToast('addRoom');
    } else {
      yield put(addRoomFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(addRoomFailure(i18n.t('base:generalError')));
  }
}

export default function* () {
  yield takeLatest(ADD_ROOM, addRoom);
}
