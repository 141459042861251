import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { flatApi } from 'utils/api';
import { GET_FLAT, getFlatSuccess, getFlatFailure } from './getFlat.actions';

function* getFlat({ id }) {
  try {
    const { data, errors } = yield call(flatApi.getFlat, id);

    if (!errors) {
      const { data: rooms } = yield call(flatApi.getFlatRooms, id);
      const flat = {
        ...data,
        rooms
      };
      yield put(getFlatSuccess(flat));
    } else {
      yield put(getFlatFailure(errors));
    }
  } catch (error) {
    yield put(getFlatFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(GET_FLAT, getFlat);
}
