import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ticketApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  assignTicketFailure,
  assignTicketSuccess,
  ASSIGN_TICKET
} from './assignTicket.actions';

function* assignTicket({ id, serviceProviderId }) {
  try {
    const { data, errors } = yield call(
      ticketApi.assignTicket,
      id,
      serviceProviderId
    );

    if (!errors) {
      yield put(assignTicketSuccess(data));
      successToast('assignTicket');
    } else {
      yield put(assignTicketFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(assignTicketFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(ASSIGN_TICKET, assignTicket);
}
