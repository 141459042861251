import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { HouseOwnerForm } from 'containers';

const EditHouseOwner = ({ houseOwner, onSubmit, t, isLoading }) => {
  return (
    <Content>
      <h2>{t('houseOwner.houseOwner')}</h2>
      <HouseOwnerForm
        firstName={houseOwner.firstName}
        lastName={houseOwner.lastName}
        address={houseOwner.address}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Content>
  );
};

EditHouseOwner.propTypes = {
  t: PropTypes.func.isRequired,
  houseOwner: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EditHouseOwner;
