import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getGenderByKey } from 'utils/genderService/gender.service';
import { getJobByKey } from 'utils/jobService/job.service';

import { CheckBoxes, StartOfRental, RentalDuration } from './components';
import { TenantData, RoomOverviewBase } from 'components';

const BookingOverview = ({
  t,
  room,
  user,
  startOfRental,
  handleStartOfRentalChange,
  rentalDuration,
  handleRentalDuration,
  rental,
  handleSubmit,
  isSubmitEnabled,
  firstCheckBox,
  handleFirstCheckBoxChecked,
  secondCheckBox,
  handleSecondCheckBoxChecked,
  firstPossibleCheckinDate,
  nextTenant
}) => (
  <Container className="my-5">
    <h2>{t('bookingOverview')}</h2>
    <RoomOverviewBase t={t} room={room} />
    <TenantData
      {...{
        title: t('overviewRentalData'),
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        nationality: user.nationality,
        dateOfBirth: user.dateOfBirth,
        customerId: user.customerId,
        gender: getGenderByKey(user.gender).value,
        job: getJobByKey(user.job).value
      }}
    />
    <div className="my-4">
      <strong>{t('hint')}</strong>
      <div>{t('hintText')}</div>
    </div>
    <StartOfRental
      {...{
        t,
        room,
        handleStartOfRentalChange,
        firstPossibleCheckinDate
      }}
      disabled={!!nextTenant}
      startOfRental={!nextTenant ? startOfRental : nextTenant.moveInDate}
    />
    <RentalDuration {...{ t, rentalDuration, handleRentalDuration }} />
    <div className="my-5">
      <strong>{t('rental')}</strong>
      <div>{`${rental}€`}</div>
    </div>
    <div style={{ margin: '20px 0' }}>
      <CheckBoxes
        {...{
          t,
          firstCheckBox,
          handleFirstCheckBoxChecked,
          secondCheckBox,
          handleSecondCheckBoxChecked
        }}
      />
    </div>
    {isSubmitEnabled ? (
      <Button onClick={handleSubmit} disabled={!isSubmitEnabled}>
        {t('submit')}
      </Button>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{t('submitTooltip')}</Tooltip>}
      >
        <div style={{ display: 'inline-block', cursor: 'not-allowed' }}>
          <Button
            style={{ pointerEvents: 'none' }}
            onClick={handleSubmit}
            disabled={!isSubmitEnabled}
          >
            {t('submit')}
          </Button>
        </div>
      </OverlayTrigger>
    )}
  </Container>
);

BookingOverview.propTypes = {
  t: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  startOfRental: PropTypes.string.isRequired,
  handleStartOfRentalChange: PropTypes.func.isRequired,
  rentalDuration: PropTypes.string.isRequired,
  handleRentalDuration: PropTypes.func.isRequired,
  rental: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitEnabled: PropTypes.bool.isRequired,
  firstCheckBox: PropTypes.bool.isRequired,
  handleFirstCheckBoxChecked: PropTypes.func.isRequired,
  secondCheckBox: PropTypes.bool.isRequired,
  handleSecondCheckBoxChecked: PropTypes.func.isRequired,
  firstPossibleCheckinDate: PropTypes.string.isRequired,
  nextTenant: PropTypes.object.isRequired
};

export default BookingOverview;
