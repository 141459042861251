import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceProviders } from 'store/serviceProvider/getServiceProviders/getServiceProviders.actions';

const useServiceProviders = (selector = state => state.serviceProviders) => {
  const dispatch = useDispatch();

  const { success } = useSelector(state => state.request.getServiceProviders);
  const serviceProviders = useSelector(selector);

  useEffect(() => {
    if (!success) dispatch(getServiceProviders());
  }, [success]);

  return serviceProviders;
};

export default useServiceProviders;
