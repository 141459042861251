import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';

const DetailModal = ({ title, details, show, onHide, size }) => {
  return (
    <Modal size={size} show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <ModalTitle>{title}</ModalTitle>
      </Modal.Header>
      <ModalBody>
        {details.map((x, i) => {
          return (
            <div key={i} style={{ marginBottom: '10px' }}>
              <b>{x.detailTitle}</b>
              <div>{x.detailText}</div>
            </div>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

DetailModal.defaultProps = {
  size: 'md'
};

DetailModal.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.arrayOf({
    detailTitle: PropTypes.string.isRequired,
    detailText: PropTypes.string.isRequired
  }),
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  size: PropTypes.string
};

export default DetailModal;
