import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { serviceProviderApi } from 'utils/api';
import {
  REGISTER_SERVICE_PROVIDER,
  registerServiceProviderSuccess,
  registerServiceProviderFailure
} from './registerServiceProvider.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

function* registerServiceProvider({ email, password, token }) {
  try {
    const { data, errors } = yield call(
      serviceProviderApi.registerServiceProvider,
      email,
      password,
      token
    );

    if (!errors) {
      yield put(registerServiceProviderSuccess(data));
      successToast('registerServiceProvider');
    } else {
      yield put(registerServiceProviderFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(registerServiceProviderFailure(i18n.t('base:generalError')));
  }
}

export default function*() {
  yield takeLatest(REGISTER_SERVICE_PROVIDER, registerServiceProvider);
}
