import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { bookingApi } from 'utils/api/';
import {
  BOOKING_NEXT_TENANT,
  bookingNextTenantSuccess,
  bookingNextTenantFailure
} from './bookingNextTenant.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* bookingNextTenant({
  terminationDate,
  tenantId,
  roomId,
  orderId,
  startDate,
  durationInMonths,
  secret
}) {
  try {
    const { data, errors } = yield call(
      bookingApi.bookingNextTenant,
      terminationDate,
      parseInt(tenantId),
      parseInt(roomId),
      orderId,
      startDate,
      parseInt(durationInMonths),
      secret
    );

    if (!errors) {
      yield put(bookingNextTenantSuccess(data));
      successToast('bookingNextTenant');
    } else {
      yield put(bookingNextTenantFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(bookingNextTenantFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(BOOKING_NEXT_TENANT, bookingNextTenant);
}
