import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  DELETE_HOUSE_OWNER,
  DELETE_HOUSE_OWNER_SUCCESS,
  DELETE_HOUSE_OWNER_FAILURE,
  DELETE_HOUSE_OWNER_CLEAR
} from './deleteHouseOwner.actions';

export default createReducer(
  {
    [DELETE_HOUSE_OWNER]: defaultRequest,
    [DELETE_HOUSE_OWNER_SUCCESS]: defaultSuccess,
    [DELETE_HOUSE_OWNER_FAILURE]: defaultError,
    [DELETE_HOUSE_OWNER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
