export const SEND_CLAIM_REPORT = 'SEND_CLAIM_REPORT';
export const SEND_CLAIM_REPORT_SUCCESS = 'SEND_CLAIM_REPORT_SUCCESS';
export const SEND_CLAIM_REPORT_FAILURE = 'SEND_CLAIM_REPORT_FAILURE';
export const SEND_CLAIM_REPORT_CLEAR = 'SEND_CLAIM_REPORT_CLEAR';

export const sendClaimReport = (
  claim,
  description,
  images,
  isMoveInClaimReport,
  damageAmount = null,
  userId = null,
  roomId = null
) => ({
  type: SEND_CLAIM_REPORT,
  claim,
  description,
  images,
  isMoveInClaimReport,
  damageAmount,
  userId,
  roomId,
});

export const sendClaimReportSuccess = (payload) => ({
  type: SEND_CLAIM_REPORT_SUCCESS,
  payload,
});

export const sendClaimReportFailure = (errors) => ({
  type: SEND_CLAIM_REPORT_FAILURE,
  errors,
});

export const sendClaimReportClear = (errors) => ({
  type: SEND_CLAIM_REPORT_CLEAR,
  errors,
});
