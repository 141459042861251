import {
  createReducer,
  updateObject,
  updateArray,
  pushToArray
} from 'utils/reducer.helper';

import { ADD_PAYMENTS_SUCCESS } from '../../payment/addPayments/addPayments.actions';
import { GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS } from './getTenantBalanceForLandlord.actions';

export default createReducer(
  {
    [GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS]: (state, action) =>
      updateObject(state, { ...action.payload }),
    [ADD_PAYMENTS_SUCCESS]: (state, action) => {
      state.payments.push(action.payload);
      return { ...state };
    }
  },
  {
    debts: [],
    payments: []
  }
);
