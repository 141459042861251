import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  SAVE_CREDIT_SETTINGS,
  SAVE_CREDIT_SETTINGS_SUCCESS,
  SAVE_CREDIT_SETTINGS_FAILURE
} from './saveCreditSettings.actions';

export default createReducer(
  {
    [SAVE_CREDIT_SETTINGS]: defaultRequest,
    [SAVE_CREDIT_SETTINGS_SUCCESS]: defaultSuccess,
    [SAVE_CREDIT_SETTINGS_FAILURE]: defaultError
  },
  defaultInitialState
);
