export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILURE = 'GET_TICKETS_FAILURE';

export const getTickets = () => ({
  type: GET_TICKETS
});
export const getTicketsSuccess = payload => ({
  type: GET_TICKETS_SUCCESS,
  payload
});
export const getTicketsFailure = errors => ({
  type: GET_TICKETS_FAILURE,
  errors
});
