import API from './API';

const prefix = 'flat';

export const getFlats = () => API.get(`${prefix}`);
export const getFlat = id => API.get(`${prefix}/${id}`);
export const addFlat = (symbol, level, description, squareMeters, houseId) =>
  API.post(`${prefix}/`, {
    symbol,
    level,
    description,
    squareMeters,
    houseId
  });
export const updateFlat = (flatId, symbol, level, description, squareMeters, houseId) =>
  API.put(`${prefix}`, {
    flatId,
    level,
    description,
    squareMeters,
    houseId,
    symbol
  });
export const deleteFlat = id => API.delete(`${prefix}/${id}`);
export const getFlatRooms = id => API.get(`${prefix}/${id}/rooms`);
export const getFlatHouse = id => API.get(`${prefix}/${id}/house`);

export default {
  getFlats,
  getFlat,
  addFlat,
  updateFlat,
  deleteFlat,
  getFlatRooms,
  getFlatHouse
};
