import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CLEAR
} from './login.actions';
import { LOGOUT_SUCCESS } from '../logout/logout.actions';

export default createReducer(
  {
    [LOGIN]: defaultRequest,
    [LOGIN_SUCCESS]: defaultSuccess,
    [LOGIN_FAILURE]: defaultError,
    [LOGIN_CLEAR]: () => defaultInitialState,
    [LOGOUT_SUCCESS]: () => defaultInitialState
  },
  defaultInitialState
);
