import API from './API';

const prefix = 'banking';

export const getBankingStatus = () => API.get(`${prefix}`);
export const synchronizeBanking = () => API.post(`${prefix}`);

export default {
  getBankingStatus,
  synchronizeBanking
};
