import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getHouseGivers } from 'store/houseGiver/getHouseGivers/getHouseGivers.actions';
import HouseGiverList from './HouseGiverList';

const HouseGiverListContainer = ({ houseGiverSelector }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('data');

  const houseGivers = useSelector(houseGiverSelector);
  const { success } = useSelector(state => state.request.getHouseGivers);

  useEffect(() => {
    if (!success) dispatch(getHouseGivers());
  }, [success]);

  const columnConfiguration = [
    {
      dataField: 'firstName',
      text: t('houseGiver.firstName'),
      sort: true
    },
    {
      dataField: 'lastName',
      text: t('houseGiver.lastName'),
      sort: true
    }
  ];

  const handleNewHouseGiverClick = () =>
    history.push('/dashboard/data/HouseGiver/add');
  const handleHouseGiverRowClick = HouseGiver =>
    history.push(`/dashboard/data/HouseGiver/${HouseGiver.id}`);

  return (
    <HouseGiverList
      {...{
        t,
        houseGivers,
        columnConfiguration,
        handleNewHouseGiverClick,
        handleHouseGiverRowClick
      }}
    />
  );
};

HouseGiverListContainer.defaultProps = {
  houseGiverSelector: state => state.houseGivers
};

HouseGiverListContainer.propTypes = {
  houseGiverSelector: PropTypes.func
};

export default HouseGiverListContainer;
