export const DELETE_ROOM = 'DELETE_ROOM';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';

export const deleteRoom = id => ({
  type: DELETE_ROOM,
  id
});
export const deleteRoomSuccess = payload => ({
  type: DELETE_ROOM_SUCCESS,
  payload
});
export const deleteRoomFailure = errors => ({
  type: DELETE_ROOM_FAILURE,
  errors
});
