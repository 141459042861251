import i18n from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { claimApi } from 'utils/api';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';
import {
  createClaimFailure,
  createClaimSuccess,
  CREATE_CLAIM,
} from './createClaim.actions';

export function* createClaimSaga({
  title,
  description,
  damageAmount,
  ticketId,
  claimReportId,
  image,
}) {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    ticketId && formData.append('ticketId', ticketId);
    claimReportId && formData.append('claimReportId', claimReportId);
    damageAmount && formData.append('damageAmount', damageAmount);
    formData.append('images', image);

    const { data, errors } = yield call(claimApi.createClaim, formData);

    if (!errors) {
      yield put(createClaimSuccess(data));
      successToast('createClaim');
    } else {
      yield put(createClaimFailure(errors));
    }
  } catch (error) {
    showGeneralErrorToast();
    yield put(createClaimFailure([i18n.t('base:generalError')]));
  }
}

export default function* () {
  yield takeLatest(CREATE_CLAIM, createClaimSaga);
}
