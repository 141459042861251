import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getAllCountryNames } from '../../utils/countryService/country.service';
import NationalityFormControl from './NationalityFormControl';

const NationalityFormControlContainer = ({ value, onChange, ...rest }) => {
  const { t } = useTranslation('setBaseData');
  const countries = getAllCountryNames();

  return (
    <NationalityFormControl {...{ t, value, countries, onChange, ...rest }} />
  );
};

NationalityFormControlContainer.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default NationalityFormControlContainer;
