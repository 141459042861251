import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  BOOKING_NEXT_TENANT,
  BOOKING_NEXT_TENANT_SUCCESS,
  BOOKING_NEXT_TENANT_FAILURE
} from './bookingNextTenant.actions';

export default createReducer(
  {
    [BOOKING_NEXT_TENANT]: defaultRequest,
    [BOOKING_NEXT_TENANT_SUCCESS]: defaultSuccess,
    [BOOKING_NEXT_TENANT_FAILURE]: defaultError
  },
  defaultInitialState
);
