export const GET_TENANT_BALANCE_FOR_LANDLORD =
  'GET_TENANT_BALANCE_FOR_LANDLORD';
export const GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS =
  'GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS';
export const GET_TENANT_BALANCE_FOR_LANDLORD_FAILURE =
  'GET_TENANT_BALANCE_FOR_LANDLORD_FAILURE';
export const GET_TENANT_BALANCE_FOR_LANDLORD_CLEAR =
  'GET_TENANT_BALANCE_FOR_LANDLORD_CLEAR';

export const getTenantBalanceForLandlord = id => ({
  type: GET_TENANT_BALANCE_FOR_LANDLORD,
  id
});
export const getTenantBalanceForLandlordSuccess = payload => ({
  type: GET_TENANT_BALANCE_FOR_LANDLORD_SUCCESS,
  payload
});
export const getTenantBalanceForLandlordFailure = errors => ({
  type: GET_TENANT_BALANCE_FOR_LANDLORD_FAILURE,
  errors
});
export const getTenantBalanceForLandlordClear = () => ({
  type: GET_TENANT_BALANCE_FOR_LANDLORD_CLEAR
});
