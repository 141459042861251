import React, { useEffect } from 'react';
import AddFlat from './AddFlat';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addFlat, addFlatClear } from 'store/flat/addFlat/addFlat.actions';
import { useHistory } from 'react-router-dom';

const AddFlatContainer = () => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: flat, success, loading } = useSelector(
    state => state.request.addFlat
  );

  useEffect(() => {
    if (success) {
      dispatch(addFlatClear());
      history.push(`/dashboard/data/flat/${flat.id}`);
    }
  }, [success]);

  const onSubmit = ({ symbol, level, squareMeters, houseId, description }) =>
    dispatch(addFlat(symbol, level, description, squareMeters, houseId));

  return <AddFlat isLoading={loading} {...{ onSubmit, t }} />;
};

export default AddFlatContainer;
