import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { removeAccessToken } from 'utils/localStorage.adapter';
import { LOGOUT, logoutSuccess, logoutFailure } from './logout.actions';
import showGeneralErrorToast from 'utils/generalErrorToast';
import successToast from 'utils/successToast';

export function* logout() {
  try {
    yield call(removeAccessToken);
    yield put(logoutSuccess());
    successToast('logout');
  } catch (error) {
    showGeneralErrorToast();
    yield put(logoutFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(LOGOUT, logout);
}
