export const DOES_TERMINATION_EXIST = 'DOES_TERMINATION_EXIST';
export const DOES_TERMINATION_EXIST_SUCCESS = 'DOES_TERMINATION_EXIST_SUCCESS';
export const DOES_TERMINATION_EXIST_FAILURE = 'DOES_TERMINATION_EXIST_FAILURE';

export const doesTerminationExist = id => ({
  type: DOES_TERMINATION_EXIST,
  id
});
export const doesTerminationExistSuccess = payload => ({
  type: DOES_TERMINATION_EXIST_SUCCESS,
  payload
});
export const doesTerminationExistFailure = errors => ({
  type: DOES_TERMINATION_EXIST_FAILURE,
  errors
});
