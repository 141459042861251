import { put, takeLatest, call } from 'redux-saga/effects';
import i18n from 'i18next';
import { serviceProviderApi } from 'utils/api';
import {
  GET_SERVICE_PROVIDERS,
  getServiceProvidersSuccess,
  getServiceProvidersFailure
} from './getServiceProviders.actions';

export function* getServiceProviders() {
  try {
    const { data, errors } = yield call(serviceProviderApi.getServiceProviders);

    if (!errors) {
      yield put(getServiceProvidersSuccess(data));
    } else {
      yield put(getServiceProvidersFailure(errors));
    }
  } catch (error) {
    yield put(getServiceProvidersFailure([i18n.t('base:generalError')]));
  }
}

export default function*() {
  yield takeLatest(GET_SERVICE_PROVIDERS, getServiceProviders);
}
