import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleLoadUser } from 'store/account/getUser/user.actions';
import { getAccessToken } from 'utils/localStorage.adapter';

const useRole = () => {
  const dispatch = useDispatch();

  const { success, data } = useSelector((state) => state.user);

  useEffect(() => {
    const jwt = getAccessToken();
    if (!success) dispatch(handleLoadUser(jwt));
  }, [success]);

  return data?.role;
};

export default useRole;
