import React from 'react';
import PropTypes from 'prop-types';
import { HouseList } from 'containers';
import Content from 'components/Content';

const HouseOverview = ({ t }) => (
  <Content>
    <h2>{t('houses.houses')}</h2>
    <HouseList />
  </Content>
);

HouseOverview.propTypes = { t: PropTypes.func.isRequired };

export default HouseOverview;
