import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, FormControl, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ClaimReportDetailModal from './ClaimReportDetailModal';

const ClaimReports = ({
  claimReports,
  rowEvents,
  columns,
  t,
  userRole,
  handleNewClaimReportClick,
  isModalOpen,
  selectedClaimReport,
  setIsModalOpen,
  userRoles,
  filter,
  handleFilterChange,
  defaultSorted
}) => {
  const { SearchBar } = Search;
  return (
    <Container className="mt-5">
      <ToolkitProvider
        keyField="id"
        data={claimReports}
        columns={columns}
        search={{ searchFormatted: true }}
      >
        {props => (
          <div>
            {selectedClaimReport && (
              <ClaimReportDetailModal
                isOpen={isModalOpen}
                claimReport={selectedClaimReport}
                onHide={() => setIsModalOpen(false)}
                userRoles={userRoles}
                userRole={userRole}
                t={t}
                state={!selectedClaimReport.closed ? t('open') : t('done')}
              />
            )}
            <Row>
              <Col
                className="col-md-7 col-12"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '20px'
                }}
              >
                <b>{t('claimReports')}</b>
              </Col>
              <Col className="col-1">
                <Button onClick={handleNewClaimReportClick}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <FormControl
                  as="select"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option>{t('filter.all')}</option>
                  <option>{t('filter.open')}</option>
                  <option>{t('filter.closed')}</option>
                </FormControl>
              </Col>
              <Col className="col-md-2 col-12 mt-2 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  srText={null}
                  placeholder={i18n.t('base:search')}
                />
              </Col>
            </Row>
            <hr />
            <div className="noselect" style={{ cursor: 'pointer' }}>
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
                defaultSorted={defaultSorted}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
};

ClaimReports.propTypes = {
  claimReports: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowEvents: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  handleNewClaimReportClick: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  selectedClaimReport: PropTypes.object.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  userRoles: PropTypes.array.isRequired,
  filter: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  closed: PropTypes.string.isRequired,
  searchProps: PropTypes.object.isRequired,
  baseProps: PropTypes.object.isRequired,
  defaultSorted: PropTypes.array
};

export default ClaimReports;
