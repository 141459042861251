import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  ADD_HOUSE_OWNER,
  ADD_HOUSE_OWNER_SUCCESS,
  ADD_HOUSE_OWNER_FAILURE,
  ADD_HOUSE_OWNER_CLEAR
} from './addHouseOwner.actions';

export default createReducer(
  {
    [ADD_HOUSE_OWNER]: defaultRequest,
    [ADD_HOUSE_OWNER_SUCCESS]: defaultSuccess,
    [ADD_HOUSE_OWNER_FAILURE]: defaultError,
    [ADD_HOUSE_OWNER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
