import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  UPDATE_HOUSE_GIVER,
  UPDATE_HOUSE_GIVER_SUCCESS,
  UPDATE_HOUSE_GIVER_FAILURE,
  UPDATE_HOUSE_GIVER_CLEAR
} from './updateHouseGiver.actions';

export default createReducer(
  {
    [UPDATE_HOUSE_GIVER]: defaultRequest,
    [UPDATE_HOUSE_GIVER_SUCCESS]: defaultSuccess,
    [UPDATE_HOUSE_GIVER_FAILURE]: defaultError,
    [UPDATE_HOUSE_GIVER_CLEAR]: () => defaultInitialState
  },
  defaultInitialState
);
