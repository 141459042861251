import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from 'utils/reducer.helper';

import {
  SAVE_PAYMENT,
  SAVE_PAYMENT_SUCCESS,
  SAVE_PAYMENT_FAILURE
} from './savePayment.actions';

export default createReducer(
  {
    [SAVE_PAYMENT]: defaultRequest,
    [SAVE_PAYMENT_SUCCESS]: defaultSuccess,
    [SAVE_PAYMENT_FAILURE]: defaultError
  },
  defaultInitialState
);
