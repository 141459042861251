import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { HouseGiverForm } from 'containers';

const AddHouseGiver = ({ houseGiver, onSubmit, t, isLoading }) => {
  return (
    <Content>
      <h2>{t('houseGiver.houseGiver')}</h2>
      <HouseGiverForm
        firstName={houseGiver.firstName}
        lastName={houseGiver.lastName}
        address={houseGiver.address}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Content>
  );
};

AddHouseGiver.propTypes = {
  t: PropTypes.func.isRequired,
  houseGiver: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AddHouseGiver;
