export const GET_NEWSLETTER_SUBSCRIPTION = 'GET_NEWSLETTER_SUBSCRIPTION';
export const GET_NEWSLETTER_SUBSCRIPTION_SUCCESS =
  'GET_NEWSLETTER_SUBSCRIPTION_SUCCESS';
export const GET_NEWSLETTER_SUBSCRIPTION_FAILURE =
  'GET_NEWSLETTER_SUBSCRIPTION_FAILURE';
export const GET_NEWSLETTER_SUBSCRIPTION_RESET =
  'GET_NEWSLETTER_SUBSCRIPTION_RESET';

export const getNewsletterSubscription = () => ({
  type: GET_NEWSLETTER_SUBSCRIPTION
});
export const getNewsletterSubscriptionSuccess = payload => ({
  type: GET_NEWSLETTER_SUBSCRIPTION_SUCCESS,
  payload
});
export const getNewsletterSubscriptionFailure = errors => ({
  type: GET_NEWSLETTER_SUBSCRIPTION_FAILURE,
  errors
});

export const getNewsletterSubscriptionReset = () => ({
  type: GET_NEWSLETTER_SUBSCRIPTION_RESET
});
