import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addRoom, addRoomClear } from 'store/room/addRoom/addRoom.actions';
import AddRoom from './AddRoom';

const AddRoomContainer = () => {
  const { t } = useTranslation('data');
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    data: room,
    success,
    loading,
  } = useSelector((state) => state.request.addRoom);

  useEffect(() => {
    if (success) {
      dispatch(addRoomClear());
      history.push(`/dashboard/data/room/${room.id}`);
    }
  }, [success]);

  const handleCreateRoom = ({
    symbol,
    squareMeters,
    price,
    deposit,
    flatId,
    description,
    newImages,
    freeAt,
    equipment,
    locationOfRoom,
  }) => {
    dispatch(
      addRoom(
        symbol,
        flatId,
        squareMeters,
        price,
        deposit,
        description,
        freeAt,
        newImages,
        equipment,
        locationOfRoom
      )
    );
  };

  return <AddRoom {...{ t, handleCreateRoom, loading }} />;
};

export default AddRoomContainer;
