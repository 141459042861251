import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LandLordSettings from './TenantSettings';
import { setNewsletterSubscription } from 'store/settings/newsletterSubscription/setNewsletterSubscription/setNewsletterSubscription.actions';
import { getNewsletterSubscription } from 'store/settings/newsletterSubscription/getNewsletterSubscription/getNewsletterSubscription.actions';

const TenantSettingsContianer = () => {
  const { t } = useTranslation('settings');
  const dispatch = useDispatch();

  const [subscripteToNewsletter, setSubscripteToNewsletter] = useState(0);

  const newsletterSubscription = useSelector(state => state.request.getNewsletterSubscription);
  const { errors } = useSelector(state => state.request.getNewsletterSubscription);
  const { loading } = useSelector(state => state.request.setNewsletterSubscription);

  useEffect(() => {
    dispatch(getNewsletterSubscription());
  }, []);

  useEffect(() => {
    if (newsletterSubscription.data) setSubscripteToNewsletter(newsletterSubscription.data.value === "True");
  }, [newsletterSubscription]);

  const onNewsletterSubscriptionChange = e =>
    setSubscripteToNewsletter(!subscripteToNewsletter);

  const onSubmit = e => {
    e.preventDefault();
    dispatch(setNewsletterSubscription(subscripteToNewsletter));
  };

  return (
    <LandLordSettings
      {...{
        subscripteToNewsletter,
        onNewsletterSubscriptionChange,
        onSubmit,
        t,
        errors,
        loading
      }}
    />
  );
};

export default TenantSettingsContianer;
