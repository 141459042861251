import register from './register_de.json';
import login from './login_de.json';
import setBaseData from './setBaseData_de.json';
import genders from './genders_de.json';
import jobs from './jobs_de.json';
import room from './room_de.json';
import bookingOverview from './bookingOverview_de.json';
import payment from './payment_de.json';
import tenantProfile from './tenantProfile_de.json';
import tenantData from './tenantData_de.json';
import termination from './termination_de.json';
import confirmEmail from './confirmEmail_de.json';
import requestConfirmationMail from './requestConfirmationMail_de.json';
import landingPage from './landingPage_de.json';
import claimReportTypes from './claimReportTypes_de.json';
import claimReport from './claimReport_de.json';
import dashboard from './dashboard_de.json';
import claimReportOverview from './claimReportOverview_de.json';
import debtOverview from './debtOverview_de.json';
import base from './base_de.json';
import data from './data_de.json';
import ticketOverview from './ticketOverview_de.json';
import tickets from './tickets_de.json';
import ticket from './ticket_de.json';
import header from './header_de.json';
import filter from './filter_de.json';
import documents from './documents_de.json';
import forms from './forms_de.json';
import rooms from './rooms_de.json';
import tenants from './tenants_de.json';
import claimReports from './claimReports_de.json';
import balance from './balance_de.json';
import bookings from './bookings_de.json';
import statistics from './statistics_de.json';
import imprint from './imprint_de.json';
import saga from './saga_de.json';
import faq from './faq_de.json';
import forgotPassword from './forgotPassword_de.json';
import resetPassword from './resetPassword_de.json';
import createNewTenant from './createNewTenant_de.json';
import activateAccount from './activateAccount_de.json';
import settings from './settings_de.json';
import registrationFlow from './registrationFlow_de.json';
import creditNotes from './creditNotes_de.json';
import earnMoney from './earnMoney_de.json';
import promotionRoomListItem from './promotionRoomListItem_de.json';
import serviceProviderProfile from './serviceProviderProfile_de.json';

export default {
  register,
  login,
  setBaseData,
  genders,
  jobs,
  room,
  bookingOverview,
  payment,
  tenantProfile,
  tenantData,
  termination,
  confirmEmail,
  requestConfirmationMail,
  landingPage,
  claimReportTypes,
  claimReport,
  dashboard,
  claimReportOverview,
  debtOverview,
  base,
  data,
  ticketOverview,
  ticket,
  header,
  filter,
  documents,
  forms,
  rooms,
  tenants,
  claimReports,
  balance,
  tickets,
  bookings,
  statistics,
  imprint,
  saga,
  faq,
  forgotPassword,
  resetPassword,
  createNewTenant,
  activateAccount,
  registrationFlow,
  settings,
  creditNotes,
  earnMoney,
  promotionRoomListItem,
  serviceProviderProfile
};
