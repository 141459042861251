import {
  createReducer,
  defaultRequest,
  defaultSuccess,
  defaultError,
  defaultInitialState
} from '../../../utils/reducer.helper';

import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE
} from './forgotPassword.actions';

export default createReducer(
  {
    [FORGOT_PASSWORD]: defaultRequest,
    [FORGOT_PASSWORD_SUCCESS]: defaultSuccess,
    [FORGOT_PASSWORD_FAILURE]: defaultError
  },
  defaultInitialState
);
