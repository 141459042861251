export const ADD_HOUSE_GIVER = 'ADD_HOUSE_GIVER';
export const ADD_HOUSE_GIVER_SUCCESS = 'ADD_HOUSE_GIVER_SUCCESS';
export const ADD_HOUSE_GIVER_FAILURE = 'ADD_HOUSE_GIVER_FAILURE';
export const ADD_HOUSE_GIVER_CLEAR = 'ADD_HOUSE_GIVER_CLEAR';

export const addHouseGiver = (firstName, lastName, address) => ({
  type: ADD_HOUSE_GIVER,
  firstName,
  lastName,
  address
});
export const addHouseGiverSuccess = payload => ({
  type: ADD_HOUSE_GIVER_SUCCESS,
  payload
});
export const addHouseGiverFailure = errors => ({
  type: ADD_HOUSE_GIVER_FAILURE,
  errors
});
export const addHouseGiverClear = () => ({
  type: ADD_HOUSE_GIVER_CLEAR
});
