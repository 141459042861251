import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {
  Search
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const Bookings = ({ bookings, columns, rowEvents, t }) => {
  const { SearchBar } = Search;
  return (
    <Container className="mt-5">
      <ToolkitProvider
        keyField="id"
        data={bookings}
        columns={columns}
        search={{ searchFormatted: true }}
      >
        {props => (
          <div>
            <Row>
              <Col
                className="col-md-10 col-12"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '20px'
                }}
              >
                <b>{t('bookings')}</b>
              </Col>
              <Col className="col-md-2 col-12 mt-3 mt-md-0">
                <SearchBar
                  {...props.searchProps}
                  srText={null}
                  placeholder={i18n.t('base:search')}
                  style={{ float: 'right' }}
                />
              </Col>
            </Row>
            <hr />
            <div className="noselect" style={{ cursor: 'pointer' }}>
              <BootstrapTable
                {...props.baseProps}
                rowEvents={rowEvents}
                hover
                wrapperClasses="table-responsive"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
};

Bookings.propTypes = {
  bookings: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowEvents: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  searchProps: PropTypes.object.isRequired,
  baseProps: PropTypes.object.isRequired
};

export default Bookings;
