import React from 'react';
import PropTypes from 'prop-types';
import Content from 'components/Content';
import { ServiceProviderList } from 'containers';

const ServiceProviders = ({ t }) => (
  <Content>
    <h2>{t('serviceProvider.serviceProvider')}</h2>
    <ServiceProviderList />
  </Content>
);

ServiceProviders.propTypes = {
  t: PropTypes.func.isRequired
};

export default ServiceProviders;
