import {
  createReducer,
  pushToArray,
  updateArray,
  updateItemInArray
} from 'utils/reducer.helper';

import { BOOK_AS_LANDLORD_SUCCESS } from './bookAsLandlord/bookAsLandlord.actions';
import { GET_BOOKINGS_SUCCESS } from './getBookings/getBookings.actions';

export default createReducer(
  {
    [GET_BOOKINGS_SUCCESS]: (state, action) =>
      updateArray(state, { ...action.payload }),
    [BOOK_AS_LANDLORD_SUCCESS]: (state, action) =>
      pushToArray(state, { ...action.payload })
  },
  []
);
