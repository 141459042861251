import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getRoom } from '../../store/room/getRoom/getRoom.actions';

import BookingOverview from './BookingOverview';
import { toInputValue } from 'utils/date.formatter';
import { Loader } from 'components';
import { Alert } from 'react-bootstrap';

const BookingOverviewContainer = ({ match, location }) => {
  const { roomId } = match.params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('bookingOverview');

  const room = useSelector(state => state.room);
  const { success } = useSelector(state => state.request.getRoom);
  const { data: user, success: userLoaded } = useSelector(state => state.user);
  const nextTenant = location.state ? location.state.nextTenant : null;

  const [startOfRental, setStartOfRental] = useState('');
  const [firstPossibleCheckinDate, setFirstPossibleCheckinDate] = useState('');
  const [rentalDuration, setRentalDuration] = useState(12);
  const [firstCheckBox, setFirstCheckBox] = useState(false);
  const [secondCheckBox, setSecondCheckBox] = useState(false);

  useEffect(() => {
    dispatch(getRoom(roomId));
  }, []);

  useEffect(() => {
    if (success) {
      if (moment().isAfter(room.freeAt)) {
        setStartOfRental(
          moment()
            .add(1, 'days')
            .format()
        );
        setFirstPossibleCheckinDate(
          moment()
            .add(1, 'days')
            .format()
        );
      } else {
        setStartOfRental(room.freeAt);
        setFirstPossibleCheckinDate(room.freeAt);
      }
    }
  }, [success]);

  const handleFirstCheckBoxChecked = e => setFirstCheckBox(e.target.checked);
  const handleSecondCheckBoxChecked = e => setSecondCheckBox(e.target.checked);
  const handleStartOfRentalChange = e => setStartOfRental(e);
  const handleRentalDuration = e =>
    setRentalDuration(e.target.value.split(' ')[0]);

  let rental = 0;

  switch (rentalDuration) {
    case '6':
      rental = (room.price * 1.1).toFixed(2);
      break;
    case '9':
      rental = (room.price * 1.05).toFixed(2);
      break;
    case '12':
      rental = room.price.toFixed(2);
      break;
    default:
      if (room.price) {
        rental = room.price.toFixed(2);
      }
      break;
  }

  const isSubmitEnabled = () => {
    if (!firstCheckBox) return false;
    if (!secondCheckBox) return false;
    if (
      !moment(startOfRental).isBetween(
        moment(firstPossibleCheckinDate)
          .add(-1, 'days')
          .format('YYYY-MM-DD'),
        moment(firstPossibleCheckinDate)
          .add(5, 'days')
          .format('YYYY-MM-DD')
      )
    )
      return false;
    return true;
  };

  const handleSubmit = () =>
    history.push({
      pathname: `/payment/room/${roomId}`,
      state: { nextTenant },
      search: `?startofrental=${toInputValue(
        nextTenant ? nextTenant.moveInDate : startOfRental
      )}&rentalduration=${rentalDuration}&deposit=${room.deposit}`
    });
  return (
    <Loader prop={room} spinner>
      <Loader prop={userLoaded} spinner>
        {nextTenant && (
          <Alert className="text-center" variant="info">
            {t('nextTenantBooking')}
          </Alert>
        )}
        <BookingOverview
          {...{
            t,
            room,
            user,
            startOfRental,
            handleStartOfRentalChange,
            rentalDuration: rentalDuration + ` ${t('months')}`,
            handleRentalDuration,
            rental,
            handleSubmit,
            isSubmitEnabled: isSubmitEnabled(),
            firstCheckBox,
            handleFirstCheckBoxChecked,
            secondCheckBox,
            handleSecondCheckBoxChecked,
            firstPossibleCheckinDate,
            nextTenant
          }}
        />
      </Loader>
    </Loader>
  );
};

BookingOverviewContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default BookingOverviewContainer;
